import axios from "axios";
import React, { act, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_PATH, IMAGE_PATH, USER_ID, USER_TOKEN } from "../../Config";
import Spinner from "../../layout/common/Spinner";
import parse from "html-react-parser";
import useLoginAuth from "./../../hooks/useLoginAuth";

export default function StoreFrontProdSingleView() {
  const [spinner, setspinner] = useState(false);
  const param = useParams();
  const prodid = param.id;
  const upath = param.upath;
  const [prodqty, setprodqty] = useState(1);
  const [proddata, setproddata] = useState([]);
  const [bplan, setbplan] = useState("");
  const [status, setstatus] = useState("");
  const [mainImage, setMainImage] = useState(
    require("./../../assets/img/bzlogo1.webp")
  );

  const loginstatus = useLoginAuth();

  useEffect(() => {
    if (prodid > 0) {
      setspinner(true);
      axios
        .post(API_PATH + "ManageInventory.php", {
          productid: prodid,
          upath: upath,
          action: "getproductinfobyid",
        })
        .then((r) => {
          if (r.data.status === "success") {
            setproddata(r.data.retval);
            // check businesses currect plan , if the business is registered as free member
            // dont allow add to cart facility
            setbplan(r.data.bplan);
            setMainImage(
              IMAGE_PATH + "product/" + r.data.retval.prod_image_link
            );
          }
          setspinner(false);
        });
    }
  }, []);
  const changeMainImage = (p) => {
    setMainImage(p.target.src);
  };

  const handleaddtocart = async (e, prodid, qty, acttype) => {
    e.preventDefault();
    if (loginstatus === true) {
      if (prodid && qty && USER_ID && USER_TOKEN) {
        setspinner(true);
        try {
          const r = await axios.post(API_PATH + "ManageCart.php", {
            action: "addtocart",
            userid: USER_ID,
            usertoken: USER_TOKEN,
            prodid: prodid,
            prodqty: qty,
            acttype: acttype,
          });

          if (r.data.status === "success") {
            setstatus("success");
            setTimeout(() => {
              setstatus("");
            }, 5000);
          } else {
            setstatus("failed");
            setTimeout(() => {
              setstatus("");
            }, 5000);
          }
          setspinner(false);
        } catch (error) {}
      } else {
        alert("Something went wrong, please refresh the page and try again.");
      }
    } else {
      alert("Login is required to add products to cart. Please login.");
    }
  };
  return (
    <>
      <div className="mt-5 pt-2 mh-90">
        {spinner === true && <Spinner />}
        <div className="container">
          <div className="row m-0 bg-white pb-3 shadow rounded">
            <div className="col-md-4 ">
              <img
                style={{
                  width: "100%",
                  objectFit: "contain",
                  aspectRatio: "1/1",
                }}
                src={mainImage}
                className="product-image-main rounded"
                alt="product-main"
              />
              <div className="row m-0">
                {proddata.prod_image_link !== "" && (
                  <div className="col-3 p-1">
                    <img
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        aspectRatio: "1/1",
                      }}
                      src={IMAGE_PATH + "product/" + proddata.prod_image_link}
                      className="product-image-secondary rounded"
                      alt="product-secondary"
                      onClick={changeMainImage}
                    />
                  </div>
                )}
                {proddata.prod_image_link_2 !== "" && (
                  <div className="col-3 p-1">
                    <img
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        aspectRatio: "1/1",
                      }}
                      src={IMAGE_PATH + "product/" + proddata.prod_image_link_2}
                      className="product-image-secondary rounded"
                      alt="product-secondary"
                      onClick={changeMainImage}
                    />
                  </div>
                )}
                {proddata.prod_image_link_3 !== "" && (
                  <div className="col-3 p-1">
                    <img
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        aspectRatio: "1/1",
                      }}
                      src={IMAGE_PATH + "product/" + proddata.prod_image_link_3}
                      className="product-image-secondary rounded"
                      alt="product-secondary"
                      onClick={changeMainImage}
                    />
                  </div>
                )}
                {proddata.prod_image_link_4 !== "" && (
                  <div className="col-3 p-1">
                    <img
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        aspectRatio: "1/1",
                      }}
                      src={IMAGE_PATH + "product/" + proddata.prod_image_link_4}
                      className="product-image-secondary rounded"
                      alt="product-secondary"
                      onClick={changeMainImage}
                    />
                  </div>
                )}
                {proddata.prod_image_link_5 !== "" && (
                  <div className="col-3 p-1">
                    <img
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        aspectRatio: "1/1",
                      }}
                      src={IMAGE_PATH + "product/" + proddata.prod_image_link_5}
                      className="product-image-secondary rounded"
                      alt="product-secondary"
                      onClick={changeMainImage}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-8  ps-md-5 pt-5">
              {status === "success" ? (
                <div className="alert alert-success">
                  Your cart has been updated for this product!
                </div>
              ) : (
                status === "failed" && (
                  <div>
                    <div className="alert alert-danger">
                      Something went wrong, please try again later!
                    </div>
                  </div>
                )
              )}

              <div className="product-title">
                <h3>
                  <i className="bi bi-card-heading"> </i>
                  {proddata.prod_full_name}
                </h3>
                <h6 className="">
                  Max Price &nbsp;
                  <i className="bi bi-cash-stack"></i>{" "}
                  <span className="text-danger">
                    <del>&#x20B9; {proddata.prod_mrp}</del>
                  </span>
                </h6>
                <h6>
                  Offer Price &nbsp;
                  <i className="bi bi-cash"></i>{" "}
                  <span className="text-success">
                    &#x20B9; {proddata.final_sale_price}
                  </span>
                </h6>
                <h6>
                  Stock Status &nbsp;
                  {proddata.prod_stock_qty > 0 ? (
                    <i className="bi bi-patch-check-fill text-success"></i>
                  ) : (
                    <i className="bi bi-x-circle-fill text-danger"></i>
                  )}
                </h6>
                <h6>
                  Shpping Cost &nbsp;
                  {proddata.shipping_price > 0 ? (
                    ">&#x20B9; " + proddata.shipping_price
                  ) : (
                    <i className="bi bi-patch-check-fill text-success"></i>
                  )}
                </h6>
                <h6>In Category : {proddata.category_name}</h6>
                <div className="row m-0 mt-4">
                  {bplan !== "free" && (
                    <>
                      <div className="col-md-3 p-1 mt-1 text-center">
                        <input
                          type="number"
                          value={prodqty}
                          onChange={(e) => setprodqty(e.target.value || 1)}
                          min={1}
                          className="form-control shadow rounded-pill"
                        />
                      </div>
                      <div className="col-md-3 p-1 mt-1 text-center">
                        <button
                          className="form-control btn btn-primary border rounded-pill shadow"
                          onClick={(e) =>
                            handleaddtocart(
                              e,
                              proddata.id,
                              prodqty,
                              "addtocart"
                            )
                          }
                        >
                          Add to Cart
                        </button>
                      </div>
                      <div className="col-md-3 p-1 mt-1 text-center">
                        <button
                          className="form-control btn btn-primary border rounded-pill shadow"
                          onClick={(e) =>
                            handleaddtocart(e, proddata.id, prodqty, "wishlist")
                          }
                        >
                          Add to Wishlist
                        </button>
                      </div>
                    </>
                  )}
                  <div className="col-md-3 p-1 mt-1 text-center">
                    <Link to={"/contact/" + upath}>
                      <button className="form-control btn btn-primary rounded-pill shadow">
                        Contact Seller
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="mt-2 prod-short-desc p-1 text-start">
                  {proddata.prod_short_description}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 p-1 rounded bg-white shadow ">
            <span className="bg-white  text-primary text-bold p-1 rounded ps-2 pe-2 shadow">
              Technical Details
            </span>
            <div className="border-top mt-1">
              <div className="row m-0">
                {proddata.batch_no && (
                  <div className="mt-1 col-sm-6 col-md-4 p-1">
                    <div className="rounded border-start border-bottom p-1">
                      Batch No. : {proddata.batch_no}
                    </div>
                  </div>
                )}
                {proddata.brand_modal_name && (
                  <div className="mt-1 col-sm-6 col-md-4 p-1">
                    <div className="rounded border-start border-bottom p-1">
                      Brand / Model Name : {proddata.brand_modal_name}
                    </div>
                  </div>
                )}

                {proddata.exp_date && (
                  <div className="mt-1 col-sm-6 col-md-4 p-1">
                    <div className="rounded border-start border-bottom p-1">
                      Expiry Date : {proddata.exp_date}
                    </div>
                  </div>
                )}
                {proddata.mfg_date && (
                  <div className="mt-1 col-sm-6 col-md-4 p-1">
                    <div className="rounded border-start border-bottom p-1">
                      Mfg Date : {proddata.mfg_date}
                    </div>
                  </div>
                )}
                {proddata.model_part_no && (
                  <div className="mt-1 col-sm-6 col-md-4 p-1">
                    <div className="rounded border-start border-bottom p-1">
                      Model / Part No : {proddata.model_part_no}
                    </div>
                  </div>
                )}
                {proddata.packing_qty_in_pcs && (
                  <div className="mt-1 col-sm-6 col-md-4 p-1">
                    <div className="rounded border-start border-bottom p-1">
                      Qty In Pack : {proddata.packing_qty_in_pcs}
                    </div>
                  </div>
                )}

                {proddata.place_of_origin && (
                  <div className="mt-1 col-sm-6 col-md-4 p-1">
                    <div className="rounded border-start border-bottom p-1">
                      Place Of Origin : {proddata.place_of_origin}
                    </div>
                  </div>
                )}

                {proddata.weight && (
                  <div className="mt-1 col-sm-6 col-md-4 p-1">
                    <div className="rounded border-start border-bottom p-1">
                      Product Weight : {proddata.weight}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-4 p-1 bg-white p-1 rounded shadow mb-5">
            <label className="text-bold text-primary p-1  ps-2 pe-2 shadow rounded">
              Detailed Description
            </label>
            <div className="border-top mt-1 prod-detailed-desc p-1">
              {proddata.prod_full_description &&
                parse(proddata.prod_full_description)}
            </div>
          </div>
        </div>
      </div>
      <div className="btn-cart-parent-div">
        <div className="btn-cart-fixed">
          <Link to="/cart">
            <button className="btn rounded-pill border shadow btn-primary">
              <i className="bi bi-cart-check"></i>
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
