import axios from "axios";
import { useEffect } from "react";
import { API_PATH } from "../Config";

export default function useCheckUpdate() {
  let appversion = require("./../../package.json").version;

  useEffect(() => {
    if (window.location.host !== "localhost:3000") {
      axios
        .post(API_PATH + "Action.php", {
          action: "checkforappupdate",
        })
        .then((r) => {
          if (r.data.status === "success") {
            if (appversion !== r.data.version) {
              if ("caches" in window) {
                caches.keys().then((names) => {
                  // Delete all the cache files
                  names.forEach((name) => {
                    caches.delete(name);
                  });
                });
                window.stop();
                window.location.reload(true);
              }
            }
          }
        });
    }
  }, [appversion]);

  return;
}
