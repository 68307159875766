import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { API_PATH, USER_ID, USER_ROLE, USER_TOKEN } from "../../Config";
import useLoginAuth from "../../hooks/useLoginAuth";
import useValidateRegType from "../../hooks/useValidateRegType";
import PayForExpired from "../../layout/common/PayForExpired";
import Spinner from "../../layout/common/Spinner";

export default function PointOfSale() {
  const [spinner, setSpinner] = useState(false);
  const [product, setProduct] = useState("");
  const [prodQty, setProdQty] = useState(1);
  const [prodList, setProdList] = useState([]);
  const [cartList, setCartList] = useState([]);
  const [counter, setCounter] = useState(1);
  const [taxableValue, setTaxableValue] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const pathname = useLocation().pathname;
  const validateLogin = useLoginAuth();
  const validateSubscription = useValidateRegType();

  const fetchProductList = useCallback(async (searchStr) => {
    setSpinner(true);
    try {
      const response = await axios.post(API_PATH + `ManageInventory.php`, {
        userid: USER_ID,
        userrole: USER_ROLE,
        usertoken: USER_TOKEN,
        searchstr: searchStr,
        action: "getProductForPos",
      });
      if (response.data.status === "success") {
        setProdList(response.data.retval);
      } else {
        setProdList([]);
      }
    } catch (error) {
      console.error("Error fetching product list:", error);
    } finally {
      setSpinner(false);
    }
  }, []);

  const fetchCartList = useCallback(async () => {
    setSpinner(true);
    try {
      const response = await axios.post(API_PATH + `ManageSale.php`, {
        userid: USER_ID,
        userrole: USER_ROLE,
        usertoken: USER_TOKEN,
        action: "getItemizedInvoice",
      });
      if (response.data.status === "success") {
        setCartList(response.data.retval);
        setTaxableValue(response.data.taxablevalue);
        setTaxAmount(response.data.taxamount);
        setTotalAmount(response.data.totalamount);
      }
    } catch (error) {
      console.error("Error fetching cart list:", error);
    } finally {
      setSpinner(false);
    }
  }, [counter]);

  const addToBag = useCallback(
    async (e) => {
      e.preventDefault();
      if (prodQty && product) {
        setSpinner(true);
        try {
          const response = await axios.post(API_PATH + `ManageSale.php`, {
            userid: USER_ID,
            userrole: USER_ROLE,
            usertoken: USER_TOKEN,
            product,
            prodqty: prodQty,
            action: "posAddToBag",
          });
          if (response.data.status === "success") {
            setProduct("");
          }
          setCounter((prev) => prev + 1);
        } catch (error) {
          console.error("Error adding to bag:", error);
        } finally {
          setSpinner(false);
        }
      } else {
        alert("Product and quantity can't be blank.");
      }
    },
    [prodQty, product]
  );

  const deleteFromBag = useCallback(async (e) => {
    e.preventDefault();
    setSpinner(true);
    try {
      const response = await axios.post(API_PATH + `ManageSale.php`, {
        userid: USER_ID,
        userrole: USER_ROLE,
        usertoken: USER_TOKEN,
        action: "deleteFromItemizedInvoice",
        delid: e.target.id,
      });
      if (response.data.status === "success") {
        setCounter((prev) => prev + 1);
      }
    } catch (error) {
      console.error("Error deleting from bag:", error);
    } finally {
      setSpinner(false);
    }
  }, []);

  const updateBagItem = useCallback(async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(API_PATH + `ManageSale.php`, {
        userid: USER_ID,
        userrole: USER_ROLE,
        usertoken: USER_TOKEN,
        action: "updateItemFromItemizedInvoice",
        cartid: e.target.id,
        newvalue: e.target.value,
        cellname: e.target.name,
      });
      if (response.data.status === "success") {
        setCounter((prev) => prev + 1);
      }
    } catch (error) {
      console.error("Error updating bag item:", error);
    }
  }, []);

  const selectProductFromSideList = useCallback(async (prodName) => {
    if (prodName) {
      setSpinner(true);
      try {
        const response = await axios.post(API_PATH + `ManageSale.php`, {
          userid: USER_ID,
          userrole: USER_ROLE,
          usertoken: USER_TOKEN,
          product: prodName,
          prodqty: 1,
          action: "posAddToBag",
        });
        if (response.data.status === "success") {
          setProduct("");
        }
        setCounter((prev) => prev + 1);
      } catch (error) {
        console.error("Error selecting product from side list:", error);
      } finally {
        setSpinner(false);
      }
    } else {
      alert("Product and quantity can't be blank.");
    }
  }, []);

  useEffect(() => {
    fetchProductList(product);
  }, [product, pathname, fetchProductList]);

  useEffect(() => {
    fetchCartList();
  }, [counter, fetchCartList]);

  if (validateLogin !== true) {
    return <Navigate to={"/login"} />;
  } else if (validateSubscription.status === false) {
    return <PayForExpired />;
  } else {
    return (
      <div className="pt-1 pb-5 mh-90">
        {spinner && <Spinner />}
        <div className="container-fluid mt-5">
          <div className="accordion mt-1" id="accordionExample">
            <div className="accordion-item mt-1">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <span style={{ fontWeight: "bold" }}>
                    Sellio Point of Sale: Manage your daily sales and keep track
                    of them. Click to know in detail.
                  </span>
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  This module helps you take advantage of mall or big shopping
                  center-like billing and invoicing experiences from your own
                  store or business. By searching (manually or using a barcode
                  reader), the product you have listed with Sellio will be added
                  to the bill, and in the next screen, you can generate invoices
                  for your customer.
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="d-none d-md-block col-md-3 pb-5"
              style={{ maxHeight: "90vh", overflowY: "scroll" }}
            >
              <div className="bg-primary rounded p-2 text-white mt-1">
                Select products from list to add to the bag
              </div>
              {prodList.map((d) => (
                <div
                  key={d.id}
                  className="btn w-100 btn-white shadow rounded p-2 mt-3"
                  onClick={() => selectProductFromSideList(d.prod_full_name)}
                >
                  <div className="d-flex justify-content-between">
                    <div>{d.prod_full_name}</div>
                    <div>Rs. : {d.sale_price}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-12 col-md-9">
              <div className="row m-0 mt-2">
                <div className="col-6 col-sm-2 p-1">
                  <label>Add Product</label>
                  <Link to={"/add-product"}>
                    <button className="form-control btn btn-danger shadow">
                      <i className="bi bi-file-earmark-plus"></i>
                    </button>
                  </Link>
                </div>
                <div className="col-6 col-sm-1 p-1">
                  <label>Track Sale</label>
                  <Link to={"/order-delivered"}>
                    <button className="form-control btn btn-warning shadow">
                      <i className="bi bi-card-checklist"></i>
                    </button>
                  </Link>
                </div>
                <div className="col-sm-5 p-1">
                  <label>Search/select Product to Sale</label>
                  <input
                    list="pos"
                    type="search"
                    className="form-control shadow"
                    value={product}
                    onChange={(e) => setProduct(e.target.value)}
                    autoComplete="on"
                  />
                  <datalist id="pos">
                    {prodList.map((d) => (
                      <option value={d.prod_full_name} key={d.id} />
                    ))}
                  </datalist>
                </div>
                <div className="col-sm-1 p-1">
                  <label>Qty</label>
                  <input
                    type="number"
                    min="1"
                    value={prodQty}
                    onChange={(e) => setProdQty(e.target.value)}
                    className="form-control shadow"
                  />
                </div>
                <div className="col-6 col-sm-2 p-1">
                  <label>Add to Bag</label>
                  <button
                    className="form-control btn btn-info shadow"
                    onClick={addToBag}
                  >
                    <i className="bi bi-plus-circle-fill text-white"></i>
                  </button>
                </div>
                <div className="col-6 col-sm-1 p-1">
                  <label>Check Out</label>
                  <Link to={"/point-of-sale-checkout"}>
                    <button className="form-control btn btn-success shadow">
                      <i className="bi bi-receipt-cutoff"></i>
                    </button>
                  </Link>
                </div>
              </div>
              <div
                className="mt-4 bg-white shadow rounded"
                style={{ overflowX: "scroll", height: "350px" }}
              >
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Action</th>
                      <th>Product Name</th>
                      <th>Product Qty</th>
                      <th>Rate</th>
                      <th>Taxable Value</th>
                      <th>Tax Rate</th>
                      <th>Tax Amount</th>
                      <th>Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartList.map((d) => (
                      <tr key={d.id}>
                        <td>
                          <i
                            className="text-danger btn bi bi-trash3-fill"
                            id={d.id}
                            onClick={deleteFromBag}
                          ></i>
                        </td>
                        <td>{d.prod_name}</td>
                        <td>
                          <input
                            className="form-control"
                            type="number"
                            name="prod_qty"
                            onChange={updateBagItem}
                            id={d.id}
                            min={1}
                            defaultValue={d.prod_qty}
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="number"
                            name="unit_rate"
                            onChange={updateBagItem}
                            id={d.id}
                            min={0.01}
                            defaultValue={d.unit_rate}
                          />
                        </td>
                        <td>{d.taxable_value}</td>
                        <td>
                          <input
                            className="form-control"
                            type="number"
                            name="igst_rate"
                            onChange={updateBagItem}
                            id={d.id}
                            min={0}
                            defaultValue={d.igst_rate}
                          />
                        </td>
                        <td>{d.igst_amount}</td>
                        <td>{d.total_amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="row m-0 mt-3 shadow rounded bg-white">
                <div className="col-6 col-md-8">
                  <div className="text-bold">Bill Amount</div>
                  <div className="text-bold">Tax Amount</div>
                  <div className="text-bold">Due Amount</div>
                </div>
                <div className="col-6 col-md-4 text-end">
                  <div className="text-bold">{taxableValue}/-</div>
                  <div className="text-bold">{taxAmount}/-</div>
                  <div className="border-top border-primary border-4 text-bold">
                    {totalAmount}/-
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
