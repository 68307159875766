import React, { useEffect } from "react";
import "./layout/common/common.css";
import Intro from "./layout/common/Intro";
import useCheckUpdate from "./hooks/useCheckUpdate";
import Seo from "./layout/common/Seo";
import Features from "./modules/web-front/sections/Features";
import Vision from "./modules/web-front/sections/Vision";
import Branding from "./modules/web-front/sections/Branding";
import OneSignal from "react-onesignal";
import SeoKeywords from "./layout/common/SeoKeywords";

export default function Home() {
  useCheckUpdate();
  useEffect(() => {
    OneSignal.init({
      appId: "91de6c70-5dbe-4154-9a6a-3a79c7ad997e",
    });
    OneSignal.serviceWorkerParam = { scope: "/push-notification" };
    OneSignal.serviceWorkerPath = "/push-notification/OneSignalSDKWorker.js";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mh-90">
      <Seo
        title="Sellio : Comprehensive Solutions for Product Display, Lead Generation, and Sales Growth"
        keywords="
          local search, travel, order food, business, website, mini website, 
          software, management, inventory, sale, pos,
          grocery, medicines, online shopping, book tickets, 
          movies, bus, flights, hotels, events, search plus services, 
          local businesses, online yellow pages, India trade directory, 
          city yellow pages, indian search engine,  customer care, customer support,
          Business management software, Cloud-based management platform,
          SaaS for business management, Enterprise resource planning, 
          Workflow automation, Project management tools,
          Customer relationship management, Sales and marketing automation,
          Financial management software,Inventory management system,
          Human resource management software,Collaboration tools for businesses,
          Productivity tools for businesses, Analytics and reporting software, 
          Business intelligence tools
        "
        description="Sellio offers tools for ERP, sales, finance, inventory, and HR. Streamline operations, productivity, and drive growth. Sign up now!"
        canonical="https://sellio.in"
        ogurl="https://sellio.in"
        ogimage="https://sellio.in/static/media/bzlogo.515dd7e3.png"
        ogtype="website"
      />

      <Intro />
      <Features />
      <Vision />
      <Branding />
      <SeoKeywords />
    </div>
  );
}
