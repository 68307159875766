import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import {
  API_PATH,
  IMAGE_PATH,
  USER_ID,
  USER_ROLE,
  USER_TOKEN,
} from "../../Config";
import useLoginAuth from "../../hooks/useLoginAuth";
import Spinner from "../../layout/common/Spinner";
import useValidateRegType from "../../hooks/useValidateRegType";
import PayForExpired from "../../layout/common/PayForExpired";

export default function ViewProductInventory() {
  const [spinner, setSpinner] = useState(false);
  const [dataLimit, setDataLimit] = useState(100);
  const [orderBy, setOrderBy] = useState("DESC");
  const [skipper, setSkipper] = useState(0);
  const [searchstr, setSearchstr] = useState("");
  const [dataStatus, setDataStatus] = useState("");
  const [dataset, setDataset] = useState([]);
  const [counter, setCounter] = useState(0);
  const [error, setError] = useState(null);

  const validatelogin = useLoginAuth();
  const validateSubscription = useValidateRegType();

  // Fetch product inventory data
  const fetchData = useCallback(async () => {
    setSpinner(true);
    setError(null);
    try {
      const response = await axios.post(API_PATH + "ManageInventory.php", {
        userid: USER_ID,
        userrole: USER_ROLE,
        usertoken: USER_TOKEN,
        searchstr,
        datalimit: dataLimit,
        orderby: orderBy,
        fromdate: null,
        tilldate: null,
        skipval: skipper,
        action: "getProductInventoryListing",
      });
      setDataStatus(response.data.status);
      setDataset(response.data.retval || []);
    } catch (error) {
      console.error("Error fetching inventory data:", error);
      setError("Failed to fetch data. Please try again.");
    } finally {
      setSpinner(false);
    }
  }, [searchstr, dataLimit, orderBy, skipper]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Debounced input handler
  const handleSearchChange = useCallback(
    (e) => {
      setSearchstr(e.target.value);
    },
    [setSearchstr]
  );

  const memoizedDataset = useMemo(() => dataset, [dataset]);

  // Adjust skipper and counter values
  const adjustSkipper = useCallback(
    (increment) => {
      setSkipper((prev) => prev + increment);
      setCounter((prev) => prev + 1);
    },
    [setSkipper, setCounter]
  );

  const reduceSkipper = () => {
    if (skipper > 0) adjustSkipper(-dataLimit);
  };

  const increaseSkipper = () => adjustSkipper(dataLimit);

  // Delete product
  const deleteProduct = useCallback(async (prodid) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      setSpinner(true);
      try {
        const response = await axios.post(API_PATH + "ManageInventory.php", {
          userid: USER_ID,
          userrole: USER_ROLE,
          usertoken: USER_TOKEN,
          action: "deleteProductById",
          productid: prodid,
        });
        if (response.data.status === "success") {
          setCounter((prev) => prev + 1);
          alert("Product Deleted Successfully!");
        }
      } catch (error) {
        console.error("Error deleting product:", error);
        setError("Failed to delete product. Please try again.");
      } finally {
        setSpinner(false);
      }
    }
  }, []);

  // Handle action
  const handleAction = useCallback(
    (e) => {
      e.preventDefault();
      if (e.target.value === "delete") {
        deleteProduct(e.target.id);
      }
    },
    [deleteProduct]
  );

  // Render component
  if (validatelogin !== true) {
    return <Navigate to="/login" />;
  }
  if (!validateSubscription.status) {
    return <PayForExpired />;
  }

  return (
    <div className="bg-light p-2 pt-5 mh-90 pb-5">
      {spinner && <Spinner />}
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="row m-0 mt-5">
        <div className="col-6 col-sm-3 col-md-2 p-1">
          <label>Data Limit</label>
          <select
            className="form-control p-1"
            value={dataLimit}
            onChange={(e) => setDataLimit(Number(e.target.value))}
          >
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
            <option value="5000">5000</option>
          </select>
        </div>
        <div className="col-6 col-sm-3 col-md-2 p-1">
          <label>Order By</label>
          <select
            className="form-control p-1"
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value)}
          >
            <option value="ASC">ASC</option>
            <option value="DESC">DESC</option>
          </select>
        </div>
        <div className="col p-1">
          <label>Navigate</label>
          <div className="p-0">
            <button
              className="btn bg-danger text-white p-1"
              onClick={reduceSkipper}
            >
              <i className="bi bi-arrow-bar-left"></i>
            </button>
            <button
              className="ms-2 btn bg-success text-white p-1"
              onClick={increaseSkipper}
            >
              <i className="bi bi-arrow-bar-right"></i>
            </button>
          </div>
        </div>
        <div className="col col-sm-3 col-md-2 p-1">
          <label>Search Product</label>
          <input
            type="search"
            className="form-control p-1"
            value={searchstr}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div className="p-2" style={{ overflowX: "scroll", fontSize: "12px" }}>
        <table className="rounded bg-white table table-responsive">
          <thead>
            <tr>
              <th>Action</th>
              <th>User Sr.</th>
              <th>Primary Image</th>
              <th>Prod Name</th>
              <th>Alias</th>
              <th>SKU</th>
              <th>Barcode</th>
              <th>Mrp</th>
              <th>Sale Price</th>
              <th>Tax Amount</th>
              <th>Final Sale Price</th>
              <th>Hsn Code</th>
              <th>Batch No</th>
              <th>Category Name</th>
              <th>Category Path</th>
              <th>Stock Qty</th>
              <th>Date</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {dataStatus === "success" &&
              memoizedDataset.map((d) => (
                <tr key={d.id}>
                  <td>
                    <select id={d.id} onChange={handleAction}>
                      <option value="none"></option>
                      <option value="delete">Delete</option>
                    </select>
                  </td>
                  <td>{d.id}</td>
                  <td>
                    <img
                      src={`${IMAGE_PATH}product/${d.prod_image_link}`}
                      alt="Product"
                      className="rounded"
                      style={{
                        width: "80px",
                        aspectRatio: "1/1",
                        objectFit: "cover",
                      }}
                    />
                  </td>
                  <td>{d.prod_full_name}</td>
                  <td>{d.prod_alias}</td>
                  <td>{d.prod_sku}</td>
                  <td>{d.prod_barcode}</td>
                  <td>{d.prod_mrp}</td>
                  <td>{d.sale_price}</td>
                  <td>{d.tax_amount}</td>
                  <td>{d.fina_sale_price}</td>
                  <td>{d.prod_hsn_code}</td>
                  <td>{d.batch_no}</td>
                  <td>{d.category_name}</td>
                  <td>{d.category_path}</td>
                  <td>{d.prod_stock_qty}</td>
                  <td>{d.prod_creation_date}</td>
                  <td>{d.prod_creation_time}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
