import React, { useEffect } from "react";
import "../../layout/common/common.css";
import ProductList from "./ProductList";
import ProfileBanner from "./ProfileBanner";
import StoreFrontContact from "./StoreFrontContact";
import StoreFrontGallery from "./StoreFrontGallery";
import StoreFrontIntro from "./StoreFrontIntro";
import ModalContactRequest from "./ModalContactRequest";

export default function Store() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mh-90">
      <ProfileBanner />
      <StoreFrontIntro />
      <ProductList />
      <StoreFrontGallery />
      <StoreFrontContact />
      <ModalContactRequest />
    </div>
  );
}
