import React, { useEffect, useState } from "react";
import CustomerPageContainer from "./CustomerPageContainer";
import axios from "axios";
import { API_PATH, IMAGE_PATH, USER_ID, USER_TOKEN } from "../../Config";
import { Link, Navigate, useNavigate } from "react-router-dom";
import useLoginAuth from "../../hooks/useLoginAuth";

const CartItems = () => {
  const [cart, setcart] = useState([]);
  const [carttotal, setcarttotal] = useState(0);
  const [counter, setcounter] = useState(0);
  const loginstatus = useLoginAuth();

  useEffect(() => {
    try {
      axios
        .post(API_PATH + "ManageCart.php", {
          action: "fetchcartitems",
          userid: USER_ID,
          usertoken: USER_TOKEN,
        })
        .then((r) => {
          if (r.data.status === "success") {
            setcart(r.data.retval);
            setcarttotal(r.data.carttotal || 0);
          } else {
            setcart([]);
            setcarttotal(0);
          }
        });
    } catch (error) {}
  }, [counter]);

  const delfromcart = (e) => {
    e.preventDefault();
    console.log(e.target);
    try {
      axios
        .post(API_PATH + "ManageCart.php", {
          action: "delcartitems",
          userid: USER_ID,
          usertoken: USER_TOKEN,
          delid: e.target.id,
        })
        .then((r) => {
          if (r.data.status === "success") {
            setcounter(Number(counter) + 1);
          }
        });
    } catch (error) {}
  };

  return (
    <CustomerPageContainer>
      <div className="container-fluid p-0 ps-md-2 m-0">
        <div
          className="bg-white rounded-pill d-flex justify-content-between shadow"
          style={{ padding: 1.5 }}
        >
          <div className="">
            <button className="btn  rounded-pill  p-1">
              Cart Total : &#x20B9; {carttotal || 0} /-
            </button>
          </div>
          <div className="">
            {carttotal > 0 ? (
              <Link to={"/checkout"}>
                <button className="btn btn-primary border rounded-pill p-1 ps-2 pe-2">
                  Checkout
                </button>
              </Link>
            ) : (
              <Link to={"/store"}>
                <button className="btn btn-primary border rounded-pill p-1 ps-2 pe-2">
                  Visit Store
                </button>
              </Link>
            )}
          </div>
        </div>
        <div
          style={{ maxHeight: "77vh", overflowY: "scroll" }}
          className="pt-1"
        >
          {cart.map((d, i) => {
            return (
              <div className="row m-0 mt-1" key={i}>
                <div className="col-md-3 p-0"></div>
                <div className="col-md-6 p-0">
                  <div className="bg-white shadow rounded  p-1">
                    <div className="row m-0">
                      <div className="col-3 p-0">
                        <Link to={"/view-product/" + d.b_link + "/" + d.prodid}>
                          <img
                            src={IMAGE_PATH + "./product/" + d.prod_image_link}
                            alt="img-product-list"
                            width={"100%"}
                            style={{
                              aspectRatio: "1 / 1",
                            }}
                            className="rounded"
                          />
                        </Link>
                      </div>
                      <div className="col-9" style={{ position: "relative" }}>
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <small
                            className="p-0"
                            style={{
                              textTransform: "capitalize",
                              fontWeight: "bold",
                            }}
                          >
                            {d.prod_full_name}
                          </small>
                        </div>
                        <div className="d-flex justify-content-between text-12">
                          <div>Mrp : &#x20B9; {d.prod_mrp}</div>
                          <div>Sale Price : &#x20B9; {d.sale_price}</div>
                        </div>
                        <div className="d-flex justify-content-between text-12 mb-4">
                          <div>Order Value : &#x20B9; {d.order_value}</div>
                          <div>
                            Saving : &#x20B9;{" "}
                            {d.prod_mrp * d.order_qty - d.order_value}
                          </div>
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                          }}
                        >
                          <div className="d-flex justify-content-between ps-2 pe-2">
                            <div>
                              <button className="btn btn-white shadow-sm rounded-pill p-0 ps-1 pe-1  text-14">
                                Qty {d.order_qty}
                              </button>
                            </div>
                            <div>
                              <button
                                className="btn btn-danger  rounded-pill p-0 ps-1 pe-1  text-14"
                                id={d.id}
                                onClick={delfromcart}
                              >
                                <i
                                  className="bi bi-trash3"
                                  id={d.id}
                                  onClick={delfromcart}
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 p-0"></div>
              </div>
            );
          })}

          {cart.length < 1 && (
            <div className="text-center">
              <h3 className="text-dark mt-5">No item found in cart.</h3>
              <small>
                Your cart seems to be empty, please buy something and come back
                later{" "}
              </small>
              <div className="d-flex justify-content-center pb-5">
                <Link to={"/store"}>
                  <button className="mt-5 shadow btn btn-white border rounded-pill p-1 ps-2 pe-2">
                    Visit Store
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </CustomerPageContainer>
  );
};

export default CartItems;
