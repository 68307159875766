import React, { useState } from "react";
import { API_PATH } from "../../../Config";
import axios from "axios";
export default function PartnershipIntro() {
  // schedule a meeting
  const [meetingwithperson, setmeetingwithperson] = useState("");
  const [meetingwithcompany, setmeetingwithcompany] = useState("");
  const [meetingwithcontact, setmeetingwithcontact] = useState("");
  const [meetingwithemail, setmeetingwithemail] = useState("");
  const [meetingwithbusinessdetail, setmeetingwithbusinessdetail] =
    useState("");
  const [statusclass, setstatusclass] = useState("");
  const [statusmsg, setstatusmsg] = useState("");
  const schedulemeeting = (e) => {
    e.preventDefault();
    if (
      !meetingwithperson ||
      !meetingwithcompany ||
      !meetingwithcontact ||
      !meetingwithemail ||
      !meetingwithbusinessdetail
    ) {
      setstatusclass("alert alert-danger");
      setstatusmsg("All fields are important, please fill up carefully.");
    } else {
      if (meetingwithcontact.length < 10) {
        setstatusclass("alert alert-danger");
        setstatusmsg("Contact number can not be less than 10 digit.");
      } else if (meetingwithcompany.length < 5) {
        setstatusclass("alert alert-danger");
        setstatusmsg("Company name can not be less than 5 letters.");
      } else {
        setstatusclass("");
        setstatusmsg("");
        axios
          .post(API_PATH + "Action.php", {
            meetingwithperson: meetingwithperson,
            meetingwithcompany: meetingwithcompany,
            meetingwithcontact: meetingwithcontact,
            meetingwithemail: meetingwithemail,
            meetingwithbusinessdetail: meetingwithbusinessdetail,
            requestfrom: "partner-prospect",
            action: "schedulemeeting",
          })
          .then((r) => {
            if (r.data.status === "success") {
              setmeetingwithperson("");
              setmeetingwithcompany("");
              setmeetingwithcontact("");
              setmeetingwithemail("");
              setmeetingwithbusinessdetail("");
              setstatusclass("alert alert-success");
              setstatusmsg(
                "Contact request sent successfully. Our team will reach you soon."
              );
            } else {
              setstatusclass("alert alert-danger");
              if (r.data.error === "existing") {
                setstatusmsg("This contact has alredy requested to connect. ");
              }
            }
            setTimeout(() => {
              setstatusclass("");
              setstatusmsg("");
            }, 5000);
          });
      }
    }
  };
  return (
    <>
      <div className="container">
        <div className="row m-0">
          <div className="col-md-6 pt-3">
            <h1 className="text-dark text-bold" style={{ fontSize: "35px" }}>
              Partner with us
            </h1>
            <p className="mt-2 text-justify">
              With Sellio, we bring you an opportunity of mutual growth. Along
              with running your existing business, you can earn a good amount
              just by telling your friends to get benefited with Sellio and
              getting them onboard.
            </p>
            <p className="mt-2 text-justify">
              By this partnership you will be eligible to add businesses on
              behalf of us and will let you earn from our remuneration schemes.
              You can start with absolutely zero investment and earn a lot with
              this program.
            </p>
            <p className="mt-2 text-justify">
              Our partnership process is as simple as filling up a simple form.
              After successfull submission, our team will be contacting you and
              will process your on boarding.
            </p>
            <button
              className="mt-1 btn btn-primary text-bold"
              data-bs-toggle="modal"
              data-bs-target="#modal_partnership"
            >
              Click to fill Partnership form
            </button>
          </div>
          <div className="col-md-6 pt-3">
            <img
              src={require("../../../assets/img/Partner.webp")}
              width="100%"
              height="auto"
              alt="img-partner-us"
              style={{ aspectRatio: "1/0.75" }}
              className=" rounded shadow  bg-white cover-full"
            />
            <div className="text-center d-block d-lg-none">
              <img
                width={"80%"}
                height={"80%"}
                src={require("./../../../assets/img/sellio_logo.png")}
                alt="about"
              />
            </div>
          </div>
        </div>
      </div>
      {/* partnership form modal  */}
      <div
        className="modal fade"
        id="modal_partnership"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Fill up details to set the meeting
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className={"w-100 " + statusclass + " mt-1"}>{statusmsg}</div>

            <div className="modal-body">
              <div className="input-group mb-3">
                <span
                  className="input-group-text bg-primary text-white"
                  id="basic-addon1"
                >
                  <i className="bi bi-person-circle"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Name"
                  aria-label="username"
                  aria-describedby="basic-addon1"
                  value={meetingwithperson}
                  onChange={(e) => setmeetingwithperson(e.target.value)}
                />
              </div>
              <div className="input-group mb-3">
                <span
                  className="input-group-text bg-primary text-white"
                  id="basic-addon2"
                >
                  <i className="bi bi-building"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Company Name"
                  aria-label="companyname"
                  aria-describedby="basic-addon2"
                  value={meetingwithcompany}
                  onChange={(e) => setmeetingwithcompany(e.target.value)}
                />
              </div>
              <div className="input-group mb-3">
                <span
                  className="input-group-text bg-primary text-white"
                  id="basic-addon3"
                >
                  <i className="bi bi-phone-fill"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Contact Number"
                  aria-label="contactnumber"
                  aria-describedby="basic-addon3"
                  value={meetingwithcontact}
                  onChange={(e) => setmeetingwithcontact(e.target.value)}
                />
              </div>
              <div className="input-group mb-3">
                <span
                  className="input-group-text bg-primary text-white"
                  id="basic-addon4"
                >
                  <i className="bi bi-envelope-fill"></i>
                </span>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  aria-label="emailaddress"
                  aria-describedby="basic-addon4"
                  value={meetingwithemail}
                  onChange={(e) => setmeetingwithemail(e.target.value)}
                />
              </div>
              <div className="input-group">
                <span className="input-group-text bg-primary text-white">
                  <i className="bi bi-briefcase-fill"></i>
                </span>
                <textarea
                  className="form-control"
                  aria-label="businessdetails"
                  placeholder="Business Details"
                  value={meetingwithbusinessdetail}
                  onChange={(e) => setmeetingwithbusinessdetail(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={schedulemeeting}
              >
                Set a Meeting
              </button>
              <button
                type="button"
                className="btn btn-light border"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
