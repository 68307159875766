import React, { useEffect, useState, useCallback } from "react";
import { API_PATH, IMAGE_PATH } from "../../Config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "../../layout/common/Spinner";

export default function BusinessSearchBar() {
  const [spinner, setSpinner] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [justLoaded, setJustLoaded] = useState(true);
  const [searchRes, setSearchRes] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();

  const fetchReviews = useCallback(async (searchKey, page) => {
    setSpinner(true);
    try {
      const response = await axios.post(API_PATH + "ManageStoreFront.php", {
        action: "searchBusiness",
        searchkey: searchKey || "default",
        page: page,
        limit: 33,
      });

      if (response.data.status === "success") {
        return response.data.retval;
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    } finally {
      setSpinner(false);
    }
    return [];
  }, []);

  const searchBusiness = async (e) => {
    const newSearchKey = e.target.value;
    setSearchKey(newSearchKey);
    setJustLoaded(false);
    setPage(1);

    if (newSearchKey.length > 0) {
      const data = await fetchReviews(newSearchKey, 1);
      setSearchRes(data);
      setHasMore(data.length > 0);
    } else {
      setSearchRes([]);
      setHasMore(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchReviews("default", 1);
      setSearchRes(data);
      setHasMore(data.length > 0);
    };
    fetchData();
  }, [fetchReviews]);

  const fetchMoreReviews = async () => {
    if (!hasMore || spinner) return;

    const moreReviews = await fetchReviews(searchKey, page + 1);
    if (moreReviews.length > 0) {
      setSearchRes((prev) => [...prev, ...moreReviews]);
      setPage((prevPage) => prevPage + 1);
    } else {
      setHasMore(false);
    }
  };

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 500
    ) {
      fetchMoreReviews();
    }
  }, [fetchMoreReviews]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <>
      <div className="container mt-4">
        {spinner && <Spinner />}
        <div className="row m-0">
          <div className="col-md-3"></div>
          <div className="col-md-6 p-1">
            <input
              type="search"
              placeholder="Search by Business or Product"
              className="form-control border shadow rounded-pill"
              onChange={searchBusiness}
              value={searchKey}
            />
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row m-0">
          {justLoaded && (
            <div className="text-center text-dark mb-4 mt-2">
              <h1>Search any business here</h1>
              <p>
                Searching businesses on sellio.in is very easy. You can search
                any business registered here with multiple parameters like
                business name, contact number, city, district, pincode, business
                category, GST number, or company CIN number.
              </p>
            </div>
          )}
          {searchRes.map((d) => (
            <div className="col-md-6 col-lg-4 p-1" key={d.id}>
              <div className="text-center">
                <span className="rounded ps-1 pe-1 bg-white text-bold text-secondary">
                  {d.b_name}
                </span>
              </div>
              <div
                className="shadow bg-white row ms-0 me-0 p-0 pt-1 border-bottom border"
                style={{
                  borderRadius: "15px",
                  marginTop: -11,
                }}
              >
                <div className="col-4 p-1">
                  <div
                    className="mb-1 border"
                    style={{
                      borderRadius: "10px",
                    }}
                  >
                    <img
                      src={
                        d.b_profile_image && d.b_profile_image.length > 5
                          ? IMAGE_PATH +
                            "./business-profile/" +
                            d.b_profile_image
                          : require("../../assets/img/bzlogo-transparent.webp")
                      }
                      alt="img-business-search-bar"
                      style={{
                        borderRadius: "10px",
                        width: "100%",
                        aspectRatio: "1/1",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
                <div className="col-8 p-1" style={{ fontSize: 14 }}>
                  <div
                    style={{ height: "90px", overflowY: "scroll" }}
                    className="rounded"
                  >
                    Address:{" "}
                    {`${d.office_address} ${d.office_city} ${
                      d.office_district
                    } ${d.office_state} ${
                      d.office_pincode > 0 ? d.office_pincode : ""
                    }`}
                  </div>
                  <div className="text-center mt-1 d-flex justify-content-between">
                    <div>
                      <i className="bi bi-star-fill text-primary"></i>:{" "}
                      {d.rating}
                    </div>
                    <button
                      className="btn btn-light shadow border rounded-pill p-1 ps-2 pe-2"
                      style={{ fontSize: 14 }}
                      onClick={() => navigate(`/in/${d.unique_user_name}`)}
                    >
                      <i className="bi bi-eyeglasses"></i> View
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {spinner && <p>Loading more business...</p>}
        </div>
      </div>
    </>
  );
}
