import React from "react";
import { useEffect } from "react";
import "../../layout/common/common.css";
import { Link } from "react-router-dom";

export default function Pricing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mh-90 mb-5">
      <div className="container pt-5">
        <div className="mt-4">
          <h4 className="text-center">Pricing structure for sellio</h4>
        </div>
        <div className="row m-0 mt-lg-4">
          <div className="mt-md-4 mt-lg-0 col-md-6 col-lg-3">
            <div className="shadow bg-white rounded p-2 text-center">
              <h2>Free</h2>
              <h6>
                <del>6999</del> Rs. 0/Year{" "}
                <span className="bg-danger rounded text-white ps-1 pe-1">
                  In Offer
                </span>
              </h6>
            </div>
            <div className="rounded mt-3 bg-white">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td>Sellio Business Profile</td>
                  </tr>
                  <tr>
                    <td>Sellio Product Catalogue</td>
                  </tr>
                  <tr>
                    <td>Unique Business QR Code</td>
                  </tr>
                  <tr>
                    <td>
                      <del>Sellio Point of Sale</del>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <del>Sellio Sales Tracking</del>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <del>Sellio Lead Management</del>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="text-center pb-2">
                <Link to="/user-registration">
                  <button
                    type="submit"
                    className="btn btn-primary rounded-pill border "
                  >
                    Register Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-md-4 mt-lg-0 col-md-6 col-lg-3">
            <div className="shadow bg-white rounded p-2 text-center">
              <h2>Basic</h2>
              <h6>
                <del>23999</del> Rs. 6999/Year{" "}
                <span className="bg-danger rounded text-white ps-1 pe-1">
                  In Offer
                </span>
              </h6>
            </div>
            <div className="rounded mt-3 bg-white">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td>Sellio Business Profile</td>
                  </tr>
                  <tr>
                    <td>Sellio Product Catalogue</td>
                  </tr>
                  <tr>
                    <td>Sellio Point of Sale Billing Module</td>
                  </tr>
                  <tr>
                    <td>Sellio Sales Tracking</td>
                  </tr>
                  <tr>
                    <td>Sellio Lead Management </td>
                  </tr>
                  <tr>
                    <td>Unique Business QR Code</td>
                  </tr>
                </tbody>
              </table>
              <div className="text-center pb-2">
                <Link to="/user-registration">
                  <button
                    type="submit"
                    className="btn btn-primary rounded-pill border "
                  >
                    Register Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-md-4 mt-lg-0 col-md-6 col-lg-3">
            <div className="shadow bg-white rounded p-2 text-center">
              <h2>Advance</h2>
              <h6>
                <del>37999</del> Rs. 18999/Year{" "}
                <span className="bg-danger rounded text-white ps-1 pe-1">
                  In Offer
                </span>
              </h6>
            </div>
            <div className="rounded mt-3 bg-white">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td>Everything In Basic+</td>
                  </tr>
                  <tr>
                    <td>Job Posts</td>
                  </tr>
                  <tr>
                    <td>Dedicated Website</td>
                  </tr>
                  <tr>
                    <td>SEO Support</td>
                  </tr>
                  <tr>
                    <td>Verified Business Badge</td>
                  </tr>
                  <tr>
                    <td>Priority Customer Support</td>
                  </tr>
                </tbody>
              </table>
              <div className="text-center pb-2">
                <Link to="/user-registration">
                  <button
                    type="submit"
                    className="btn btn-primary rounded-pill border "
                  >
                    Register Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-md-4 mt-lg-0 col-md-6 col-lg-3">
            <div className="shadow bg-white rounded p-2 text-center">
              <h2>Pro</h2>
              <h6>
                <del>99999</del> Rs. 24999/Year{" "}
                <span className="bg-danger rounded text-white ps-1 pe-1">
                  In Offer
                </span>
              </h6>
            </div>
            <div className="rounded mt-3 bg-white">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td>Everything In Basic and Advance+</td>
                  </tr>
                  <tr>
                    <td>Sellio Online Orders</td>
                  </tr>
                  <tr>
                    <td>Sellio Order Management</td>
                  </tr>
                  <tr>
                    <td>Dedicated Account Manager</td>
                  </tr>
                  <tr>
                    <td>Tele Calling Crm</td>
                  </tr>
                  <tr>
                    <td>Marketing/Promotional Support</td>
                  </tr>
                </tbody>
              </table>
              <div className="text-center pb-2">
                <Link to="/user-registration">
                  <button
                    type="submit"
                    className="btn btn-primary rounded-pill border "
                  >
                    Register Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 mt-5">
          <div className="col-md-8">
            <div className="p-3 shadow rounded bg-white">
              <p className="text-justify pt-2 pb-2" style={{ fontSize: 16 }}>
                Our SaaS platform, sellio.in, provides a comprehensive and
                affordable business management solution. In today's competitive
                environment, efficient operations are key to success. Sellio.in
                offers a wide range of features and functionalities at an
                affordable price, catering to businesses of all sizes. We
                believe every business deserves access to the latest technology
                to streamline operations and boost productivity. Our cloud-based
                solution ensures modern tools are within reach without breaking
                the bank. With flexible subscription plans, you only pay for
                what you use, with options to fit any budget. Our transparent
                pricing model has no hidden fees, building trust and long-term
                relationships with our customers. Choose sellio.in for the most
                cost-effective solution on the market. Try our platform today
                and experience the benefits of a modern, cloud-based business
                management solution at an affordable price.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex flex-column justify-content-center h-100">
              <div>
                <img
                  alt="PricingImage"
                  width={"100%"}
                  height={"auto"}
                  src={require("./../../assets/img/value-for-money.webp")}
                  className="w-100"
                />
              </div>
              <div className="text-center d-lg-none">
                <img
                  width={"80%"}
                  height={"80%"}
                  src={require("./../../assets/img/sellio_logo.png")}
                  alt="about"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
