import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_PATH, IMAGE_PATH } from "../../Config";
import axios from "axios";
import parse from "html-react-parser";
import GoToHome from "./GoToHome";
import Spinner from "../../layout/common/Spinner";

export default function StoreFrontBlog() {
  const [spinner, setspinner] = useState(false);
  const params = useParams();
  const upath = params.from;
  const blogid = params.id > 0 ? params.id : 0;
  const [status, setstatus] = useState("");

  const [bdata, setbdata] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true);
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "getBusinessBlogData",
        upath: upath,
        blogid: blogid,
      })
      .then((r) => {
        if (r.data.status === "success") {
          setbdata(r.data.retval);
        } else {
          setstatus("failed");
        }
        setspinner(false);
      });
  }, [upath, blogid]);

  return (
    <>
      {blogid && bdata ? (
        <div className="container mt-5 pt-3 mh-90 mb-5">
          {spinner === true && <Spinner />}
          <div className="row pt-1">
            <GoToHome link={"/in/" + upath} />
            <div
              className="col-12 text-left mt-4 mb-2 bg-white rounded border shadow pt-4 pb-4 p-lg-4"
              style={{ textTransform: "uppercase" }}
            >
              <h6 className="text-secondary">
                <span className="text-dark p-1">
                  <i className="bi bi-pencil-square"></i>
                </span>
                {bdata.post_title}
              </h6>
              <div className="row">
                <div className="col">
                  <h6 className="text-secondary mt-3">
                    <span className="text-dark p-1">
                      <i className="bi bi-calendar3"></i>
                    </span>
                    {bdata.date}
                  </h6>
                </div>
                <div className="col">
                  <h6 className="text-secondary mt-3">
                    <span className="text-dark p-1">
                      <i className="bi bi-alarm"></i>
                    </span>
                    {bdata.time}
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-0 pe-lg-2">
              <div className="bg-white rounded border shadow p-1">
                <img
                  src={IMAGE_PATH + "blog-post/" + bdata.post_image}
                  alt=""
                  style={{ width: "100%" }}
                  className="rounded"
                />
              </div>
            </div>
            <div className="col-md-8 pt-4 bg-white shadow rounded border p-lg-4">
              {bdata.post_description && parse(bdata.post_description)}
            </div>
          </div>
        </div>
      ) : (
        <div className="mh-90 container mt-5 pt-4">
          {spinner === true && <Spinner />}
          <GoToHome link={"/in/" + upath} />
          <div className="w-100 mt-3">
            {status !== "failed" && (
              <h4 className="text-secondary text-center mt-2 mb-4">
                RECENT POSTS
              </h4>
            )}
            {status === "failed" && (
              <>
                <h4 className="text-center">
                  Sorry, No post for this business is updated yet.
                </h4>
                <p className="text-center">
                  Some times businesses are too busy serving things to you and
                  dont get time to update infromations here. If you are looking
                  for some specific information. You can contact business by
                  given button link.
                </p>
                <div className="text-center">
                  <Link to={"/contact/" + upath}>
                    <button className="btn btn-primary">
                      Contact Business
                    </button>
                  </Link>
                </div>
              </>
            )}
            {bdata &&
              bdata.length > 0 &&
              bdata.map((d) => {
                return (
                  <div className="row m-0 mt-2 mb-5" key={d.id}>
                    <div className="col-md-2 p-1">
                      <img
                        src={IMAGE_PATH + "blog-post/" + d.post_image}
                        alt=""
                        style={{
                          width: "100%",
                          aspectRatio: "1/1",
                          objectFit: "cover",
                        }}
                        className="rounded"
                      />
                    </div>
                    <div className="col-md-10 p-1">
                      <Link to={"/blog/" + upath + "/" + d.id}>
                        <div className="btn btn-white rounded shadow p-1 p-lg-3 w-100 border ">
                          {d.post_title}
                        </div>
                      </Link>
                      <div
                        className="bg-white shadow mt-2 border p-1 rounded ellipsis"
                        style={{ height: "145px", overflow: "hidden" }}
                      >
                        {parse(d.post_description)}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}
