import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { API_PATH, USER_ID, USER_TOKEN } from "../../Config";
import CustomerPageContainer from "./CustomerPageContainer";
import useLoginAuth from "../../hooks/useLoginAuth";

const Checkout = () => {
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("");
  const [addresses, setaddresses] = useState([]);
  const [status, setstatus] = useState("");
  const [cartitemstatus, setcartitemstatus] = useState("");
  const loginstatus = useLoginAuth();
  const navigate = useNavigate();

  const fetchAddresses = async () => {
    try {
      const r = await axios.post(API_PATH + "ManageCart.php", {
        action: "getcustomeraddress",
        userid: USER_ID,
        usertoken: USER_TOKEN,
      });
      if (r.data.status === "success") {
        setaddresses(r.data.retval);
        setcartitemstatus(r.data.cartitemstatus);
        setTimeout(() => {
          r.data.cartitemstatus !== "success" && navigate("/cart");
        }, 5000);
      }
      console.log(r.data.retval);
    } catch (error) {
      console.error("Error fetching addresses", error);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  // const paymentModes = ["cash_on_delivery", "pay_online"];
  const paymentModes = ["cash_on_delivery"];

  const handleAddressChange = (event) => {
    setSelectedAddress(event.target.value);
  };

  const handlePaymentModeChange = (event) => {
    setSelectedPaymentMode(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedAddress > 0 && selectedPaymentMode) {
      if (selectedPaymentMode === "cash_on_delivery") {
        try {
          await axios
            .post(API_PATH + "ManageCart.php", {
              userid: USER_ID,
              usertoken: USER_TOKEN,
              action: "handlecodcheckout",
              address: selectedAddress,
            })
            .then((r) => {
              window.scrollTo(0, 0);
              if (r.data.status === "success") {
                setstatus("success");
                setTimeout(() => {
                  setstatus("");
                  navigate("/order-history");
                }, 5000);
              } else {
              }
            });
        } catch (error) {}
      }
      if (selectedPaymentMode === "pay_online") {
      }
    } else {
      alert("Please select an address and payment mode to checkout!");
    }
  };

  if (loginstatus !== true) {
    return <Navigate to={"/logout"} />;
  } else {
    return (
      <CustomerPageContainer>
        <div className="p-0 m-0 ps-md-2 pb-4">
          <h2 className="text-center pb-3">Checkout</h2>
          {status === "success" && (
            <div className="alert alert-success">
              Order Placed successfully! Navigating to order history in 5
              seconds!
            </div>
          )}
          {cartitemstatus !== "success" && (
            <div className="alert alert-danger">
              Your cart is empty ! Navigating to cart in 5 seconds!
            </div>
          )}

          <div className="bg-white rounded shadow p-1 pt-3 pb-3">
            <form onSubmit={handleSubmit}>
              <div className="mb-4 p-md-2">
                <h4 className="border-bottom pb-1">Select Delivery Address</h4>
                {addresses.length < 1 && (
                  <Link to="/delivery-address">
                    <button
                      type="submit"
                      className="mt-4 btn btn-white border shadow btn-block rounded-pill ms-4"
                    >
                      Click to Add an address first
                    </button>
                  </Link>
                )}
                {addresses.map((address, index) => (
                  <div
                    className="form-check shadow-sm ps-5 pt-2 rounded mt-2"
                    key={index}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      id={`address${index}`}
                      name="address"
                      value={address.id} // Use index as value
                      checked={selectedAddress === address.id}
                      onChange={handleAddressChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`address${index}`}
                    >
                      <div className="w-100">
                        {address.address}, {address.city}, {address.state} -{" "}
                        {address.pincode}
                      </div>
                      <div className="w-100">
                        Contact person: {address.contact_person}
                      </div>
                      <div className="w-100">
                        Contact Number: {address.contact_number}
                      </div>
                    </label>
                  </div>
                ))}
              </div>

              <div className="mb-4 p-md-2 ">
                <h4 className="border-bottom pb-1">Select Payment Mode</h4>
                {paymentModes.map((mode, index) => (
                  <div className="form-check ps-5" key={index}>
                    <input
                      className="form-check-input"
                      type="radio"
                      id={`paymentMode${index}`}
                      name="paymentMode"
                      value={mode}
                      checked={selectedPaymentMode === mode}
                      onChange={handlePaymentModeChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`paymentMode${index}`}
                    >
                      {mode}
                    </label>
                  </div>
                ))}
              </div>
              {addresses.length > 0 && (
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="btn btn-primary btn-block rounded-pill ms-4"
                  disabled={cartitemstatus === "success" ? false : true}
                >
                  Place Order
                </button>
              )}
            </form>
          </div>
        </div>
      </CustomerPageContainer>
    );
  }
};

export default Checkout;
