import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_PATH } from "../../Config";
import SearchBar from "./SearchBar";

export default function Intro() {
  // schedule a meeting
  const [meetingwithperson, setmeetingwithperson] = useState("");
  const [loaded, setloaded] = useState(false);
  const [meetingwithcompany, setmeetingwithcompany] = useState("");
  const [meetingwithcontact, setmeetingwithcontact] = useState("");
  const [meetingwithemail, setmeetingwithemail] = useState("");
  const [meetingwithbusinessdetail, setmeetingwithbusinessdetail] =
    useState("");
  const [statusclass, setstatusclass] = useState("");
  const [statusmsg, setstatusmsg] = useState("");
  const schedulemeeting = (e) => {
    e.preventDefault();
    if (
      !meetingwithperson ||
      !meetingwithcompany ||
      !meetingwithcontact ||
      !meetingwithemail ||
      !meetingwithbusinessdetail
    ) {
      setstatusclass("alert alert-danger");
      setstatusmsg("All fields are important, please fill up carefully.");
    } else {
      if (meetingwithcontact.length < 10) {
        setstatusclass("alert alert-danger");
        setstatusmsg("Contact number can not be less than 10 digit.");
      } else if (meetingwithcompany.length < 3) {
        setstatusclass("alert alert-danger");
        setstatusmsg("Company name can not be less than 3 letters.");
      } else {
        setstatusclass("");
        setstatusmsg("");
        axios
          .post(API_PATH + "Action.php", {
            meetingwithperson: meetingwithperson,
            meetingwithcompany: meetingwithcompany,
            meetingwithcontact: meetingwithcontact,
            meetingwithemail: meetingwithemail,
            meetingwithbusinessdetail: meetingwithbusinessdetail,
            requestfrom: "user-prospect",
            action: "schedulemeeting",
          })
          .then((r) => {
            if (r.data.status === "success") {
              setmeetingwithperson("");
              setmeetingwithcompany("");
              setmeetingwithcontact("");
              setmeetingwithemail("");
              setmeetingwithbusinessdetail("");
              setstatusclass("alert alert-success");
              setstatusmsg(
                "Contact request sent successfully. Our team will reach you soon."
              );
            } else {
              setstatusclass("alert alert-danger");
              if (r.data.error === "existing") {
                setstatusmsg("This contact has alredy requested to connect. ");
              }
            }
            setTimeout(() => {
              setstatusclass("");
              setstatusmsg("");
            }, 5000);
          });
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setloaded(true);
    }, 1000);
  }, []);

  return (
    <>
      <div className="mt-4 mt-md-5 pb-3">
        <div className="row bg-white rounded m-0 shadow">
          <div className="col-md-7 col-lg-7 px-4 px-lg-5 py-2">
            {/* <SearchBar /> */}
            <h1
              className="text-dark mt-3 mt-md-2 mt-lg-4 text-center text-md-start"
              style={{ fontSize: "35px", fontWeight: "bold" }}
            >
              Maximize Your Sales Potential
            </h1>
            {/* <h4>
              Comprehensive Solutions for Product Display, Lead Generation, and
              Sales Growth
            </h4> */}
            <h5 className="text-secondary  text-center text-md-start">
              Made easy with sellio.in !
            </h5>
            {/* nav on mobile */}
            <div className="row m-0 mt-4 mt-md-5 d-sm-none">
              <div className="col-12 col-md-4 p-1">
                <Link to={"/user-registration"}>
                  <button
                    className="btn form-control btn-primary rounded-pill shadow fs-6"
                    style={{ fontWeight: "bold" }}
                  >
                    Register Now
                  </button>
                </Link>
              </div>
              <div className="col-12 col-md-4 p-1">
                <button
                  className="btn form-control bg-white border rounded-pill shadow text-dark fs-6"
                  style={{ fontWeight: "bold" }}
                  data-bs-toggle="modal"
                  data-bs-target="#modal_meeting"
                >
                  Set a meeting
                </button>
              </div>

              <div className="col-12 col-md-4 p-1">
                <Link to={"/search-business"}>
                  <button
                    className="btn form-control bg-white border rounded-pill shadow text-dark fs-6"
                    style={{ fontWeight: "bold" }}
                  >
                    Search Business
                  </button>
                </Link>
              </div>
              <div className="col-12 col-md-4 p-1">
                <Link to={"/pricing"}>
                  <button
                    className="btn form-control bg-white border shadow rounded-pill text-dark fs-6"
                    style={{ fontWeight: "bold" }}
                  >
                    View Business Plan
                  </button>
                </Link>
              </div>
            </div>
            {/* {loaded === true && ( */}
            <p className="mt-4 text-justify text-secondary d-md-none d-lg-block">
              Create your Sellio Business Profile and expand your business
              online. Use is as your full fledged website, Inventory Manager,
              Product Catalogue, Pos Solution, Business Identity and much more.
              Generate your Business QR Code and make your business available
              with a single scan. Utilize various modules according to your need
              and monitor your business online.
            </p>
            {/* )} */}
            <h4 className="text-primary">Powered By AI</h4>
            {/* desktop navigation */}
            <div className="d-none d-sm-block">
              <div className="row m-0 mt-2 mt-lg-5">
                <div className="col-12 col-md-6 col-lg-3 p-1">
                  <Link to={"/user-registration"}>
                    <button
                      className="btn btn-primary form-control text-white border rounded-pill shadow text-dark fs-6"
                      style={{ fontWeight: "bold" }}
                    >
                      Register Now
                    </button>
                  </Link>
                </div>
                <div className="col-12 col-md-6 col-lg-3 p-1">
                  <button
                    className="btn form-control bg-white border rounded-pill shadow text-dark fs-6"
                    style={{ fontWeight: "bold" }}
                    data-bs-toggle="modal"
                    data-bs-target="#modal_meeting"
                  >
                    Set a meeting
                  </button>
                </div>

                <div className="col-12 col-md-6 col-lg-3 p-1">
                  <Link to={"/search-business"}>
                    <button
                      className="btn form-control bg-white border shadow rounded-pill text-dark fs-6"
                      style={{ fontWeight: "bold" }}
                    >
                      Search Business
                    </button>
                  </Link>
                </div>
                <div className="col-12 col-md-6 col-lg-3 p-1">
                  <Link to={"/pricing"}>
                    <button
                      className="btn form-control bg-white border shadow rounded-pill text-dark fs-6"
                      style={{ fontWeight: "bold" }}
                    >
                      View Plans
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-lg-5 p-0">
            {/* {loaded === true && ( */}
            <img
              alt="introimage"
              width={"100%"}
              height={"100%"}
              src={require("./../../assets/img/intronew.webp")}
              className="w-100 border shadow"
              style={{ borderRadius: "48% 0% 0% 48%" }}
            />
            {/* )} */}
          </div>
        </div>
      </div>

      {/* Schedule a meeting modal  */}
      <div
        className="modal fade"
        id="modal_meeting"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-5" id="staticBackdropLabel">
                Fill up details to set the meeting
              </h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className={"w-100 " + statusclass + " mt-1"}>{statusmsg}</div>

            <div className="modal-body">
              <div className="input-group mb-3">
                <span
                  className="input-group-text bg-primary text-white"
                  id="basic-addon1"
                >
                  <i className="bi bi-person-circle"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Name"
                  aria-label="username"
                  aria-describedby="basic-addon1"
                  value={meetingwithperson}
                  onChange={(e) => setmeetingwithperson(e.target.value)}
                />
              </div>
              <div className="input-group mb-3">
                <span
                  className="input-group-text  bg-primary text-white"
                  id="basic-addon2"
                >
                  <i className="bi bi-building"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Company Name"
                  aria-label="companyname"
                  aria-describedby="basic-addon2"
                  value={meetingwithcompany}
                  onChange={(e) => setmeetingwithcompany(e.target.value)}
                />
              </div>
              <div className="input-group mb-3">
                <span
                  className="input-group-text  bg-primary text-white"
                  id="basic-addon3"
                >
                  <i className="bi bi-phone-fill"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Contact Number"
                  aria-label="contactnumber"
                  aria-describedby="basic-addon3"
                  value={meetingwithcontact}
                  onChange={(e) => setmeetingwithcontact(e.target.value)}
                />
              </div>
              <div className="input-group mb-3">
                <span
                  className="input-group-text  bg-primary text-white"
                  id="basic-addon4"
                >
                  <i className="bi bi-envelope-fill"></i>
                </span>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  aria-label="emailaddress"
                  aria-describedby="basic-addon4"
                  value={meetingwithemail}
                  onChange={(e) => setmeetingwithemail(e.target.value)}
                />
              </div>
              <div className="input-group">
                <span className="input-group-text  bg-primary text-white">
                  <i className="bi bi-briefcase-fill"></i>
                </span>
                <textarea
                  className="form-control"
                  aria-label="businessdetails"
                  placeholder="Business Details"
                  value={meetingwithbusinessdetail}
                  onChange={(e) => setmeetingwithbusinessdetail(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary text-white"
                onClick={schedulemeeting}
              >
                Set a Meeting
              </button>
              <button
                type="button"
                className="btn btn-light border"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
