import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import Switcher from "./navswitch/Switcher";
import axios from "axios";
import { API_PATH } from "../../Config";

export default function Navbar() {
  const [navToggle, setNavToggle] = useState("d-none");
  const [searchinput, setsearchinput] = useState("");
  const [searchresult, setsearchresult] = useState([]);
  const location = useLocation();

  // check if current page is store
  const getstoreurl = location.pathname;
  const isstorepage = getstoreurl.slice(1, 3) === "in" ? true : false;

  // toggle sidebar onclick
  const toggleSidebar = () => {
    if (navToggle === "d-none") {
      setNavToggle("d-block");
    } else {
      setNavToggle("d-none");
    }
  };

  // Detect url locaion change and hide mobile menu
  useEffect(() => {
    setNavToggle("d-none");
  }, [location.pathname]);

  const search = useCallback(
    (e) => {
      setsearchresult([]);
      setsearchinput(e.target.value);
      if (e.target.value.length > 0 || e.target.value !== "") {
        axios
          .post(API_PATH + "ManageStoreFront.php", {
            action: "searchBusinessInNavBar",
            searchinput: e.target.value,
          })
          .then((r) => {
            if (r.data.status) {
              setsearchresult(r.data.retval);
            } else {
              setsearchresult([]);
            }
          });
      } else {
        setsearchresult([]);
      }
    },
    [searchinput]
  );

  return (
    <>
      {isstorepage ? (
        <>
          {/* store page navigation */}
          <div className="row m-0 ">
            <div className="col-md-6 col-10 text-center p-1 ps-md-4">
              <div className="nav-branding d-flex">
                <div className="pt-0 pt-sm-2 pt-md-0">
                  <Link to="/">
                    <div className="d-flex">
                      <img
                        src={require("./../../assets/img/bzlogo3.webp")}
                        alt="nav-branding"
                        style={{
                          width: "45px",
                          height: "45px",
                          aspectRatio: "1/1",
                          padding: "1px",
                        }}
                        className="shadow rounded "
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block col-md-4 ">
              {/* added just to make layout work */}
            </div>

            <div className="col-md-2 col-2 p-1 text-end pe-md-4">
              <i
                className="btn btn-white shadow bi bi-layout-text-sidebar fs-5 border"
                onClick={toggleSidebar}
              ></i>
            </div>
          </div>
        </>
      ) : (
        // navigation other than store page
        <nav className="navbar-custom fixed-top  shadow-sm text-right bg-base p-0">
          <div className="container-fluid">
            <div className="row m-0 ">
              <div className="col-lg-2  col-md-3  col-10 p-0">
                <div className="nav-branding">
                  <div className="pt-0 pt-sm-2 pt-md-0">
                    <Link to="/">
                      <div className="d-flex justify-content-start pt-1">
                        <span className="fs-2 text-bold text-primary">
                          Sellio
                        </span>
                        {/* <img
                          src={require("../../assets/img/bzlogo1.webp")}
                          alt="nav-branding-2"
                          width="170px"
                          height={"100%"}
                          className=""
                          style={{ marginLeft: "-45px" }}
                        /> */}
                        <span style={{ marginLeft: "-2px" }}>
                          <strong className="text-dark">.in</strong>
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-block  col-md-6 col-lg-4  p-0 position-relative">
                {/* added just to make layout work */}
                <input
                  type="search"
                  className="form-control p-2 m-0 mt-1 alert alert-primary rounded-pill text-dark"
                  placeholder=" Search for business here..."
                  value={searchinput}
                  onChange={search}
                />
                {searchresult && searchresult.length > 0 && (
                  <div className="position-absolute w-100 bg-light p-1 nav-search-result pt-0">
                    {searchresult.map((d, i) => {
                      return (
                        <Link to={"/in/" + d.unique_user_name} key={i}>
                          <div className="bg-white mt-1 nav-search-result border px-3">
                            {`${d.b_name} : ${d.registered_city} ${d.registered_state}`}
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>

              <div className="col-lg-6 col-md-3 col-2 p-1 ">
                <div className="d-none d-lg-block">
                  <Switcher />
                </div>
                <i
                  className="d-block d-lg-none bi bi-layout-text-sidebar text-dark fs-3 togglebutton text-end"
                  onClick={toggleSidebar}
                ></i>
              </div>
            </div>
          </div>
        </nav>
      )}
      {/* mobile navigation */}
      <div
        className={`sidebar bg-light ${navToggle} border-end border-4 border-white shadow`}
      >
        <Switcher />
      </div>
    </>
  );
}
