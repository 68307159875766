import React, { useEffect, useState } from "react";
import CustomerPageContainer from "./CustomerPageContainer";
import axios from "axios";
import { API_PATH, IMAGE_PATH, USER_ID, USER_TOKEN } from "../../Config";
import { Link } from "react-router-dom";

const OrderHistory = () => {
  const [cart, setcart] = useState([]);
  const [counter, setcounter] = useState(0);

  useEffect(() => {
    try {
      axios
        .post(API_PATH + "ManageCart.php", {
          action: "fetchorderhistory",
          userid: USER_ID,
          usertoken: USER_TOKEN,
        })
        .then((r) => {
          if (r.data.status === "success") {
            setcart(r.data.retval);
          } else {
            setcart([]);
          }
        });
    } catch (error) {}
  }, [counter]);

  const delfromcart = (e) => {
    e.preventDefault();
    console.log(e.target);
    try {
      axios
        .post(API_PATH + "ManageCart.php", {
          action: "delcartitems",
          userid: USER_ID,
          usertoken: USER_TOKEN,
          delid: e.target.id,
        })
        .then((r) => {
          if (r.data.status === "success") {
            setcounter(Number(counter) + 1);
          }
        });
    } catch (error) {}
  };

  return (
    <CustomerPageContainer>
      <div className="container-fluid p-0 ps-md-2 m-0">
        {cart.length > 0 ? (
          <div
            style={{ maxHeight: "77vh", overflowY: "scroll" }}
            className="pt-1"
          >
            {cart.map((d, i) => {
              return (
                <div className="row m-0 mt-1" key={i}>
                  <div className="col-md-3 p-0"></div>
                  <div className="col-md-6 p-0">
                    <div className="bg-white shadow rounded  p-1">
                      <div className="row m-0">
                        <div className="col-3 p-0">
                          <Link
                            to={"/view-product/" + d.b_link + "/" + d.prodid}
                          >
                            <img
                              src={
                                IMAGE_PATH + "./product/" + d.prod_image_link
                              }
                              alt="img-product-list"
                              width={"100%"}
                              style={{
                                aspectRatio: "1 / 1",
                              }}
                              className="rounded"
                            />
                          </Link>
                        </div>
                        <div className="col-9" style={{ position: "relative" }}>
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <small
                              className="p-0"
                              style={{
                                textTransform: "capitalize",
                                fontWeight: "bold",
                              }}
                            >
                              {d.prod_full_name}
                            </small>
                          </div>
                          <div className="d-flex justify-content-between text-12">
                            <div>Mrp : &#x20B9; {d.prod_mrp}</div>
                            <div>Sale Price : &#x20B9; {d.sale_price}</div>
                          </div>
                          <div className="d-flex justify-content-between text-12">
                            <div>Order Value : &#x20B9; {d.order_value}</div>
                            <div>
                              Saving : &#x20B9;{" "}
                              {d.prod_mrp * d.order_qty - d.order_value}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between text-12 mb-4">
                            <div>Order Status : {d.order_status}</div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              width: "100%",
                            }}
                          >
                            <div className="d-flex justify-content-between ps-2 pe-2">
                              <div>
                                <button className="btn btn-white shadow-sm rounded-pill p-0 ps-1 pe-1  text-14">
                                  Qty {d.order_qty}
                                </button>
                              </div>
                              <div>
                                <button
                                  className="btn btn-danger  rounded-pill p-0 ps-1 pe-1  text-14"
                                  id={d.id}
                                  onClick={delfromcart}
                                >
                                  <i
                                    className="bi bi-trash3"
                                    id={d.id}
                                    onClick={delfromcart}
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 p-0"></div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-center">
            <h3>No data found for this section</h3>
            <small>
              Currently we don't have any data for you, please shop something
              and come back again.
            </small>
          </div>
        )}
      </div>
    </CustomerPageContainer>
  );
};

export default OrderHistory;
