import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_PATH, IMAGE_PATH } from "../../Config";
import Spinner from "../../layout/common/Spinner";

const ProductList = () => {
  const [spinner, setSpinner] = useState(false);
  const [pdata, setPdata] = useState([]);
  const { id: upath } = useParams();

  useEffect(() => {
    const fetchProducts = async () => {
      setSpinner(true);
      try {
        const response = await axios.post(API_PATH + "ManageStoreFront.php", {
          action: "getStoreFrontProductData",
          upath,
        });

        if (response.data.status === "success") {
          setPdata(response.data.retval);
        } else {
          console.error("Failed to fetch products");
        }
      } catch (error) {
        console.error("Error fetching products", error);
      } finally {
        setSpinner(false);
      }
    };

    fetchProducts();
  }, [upath]);

  const ProductCard = ({ product }) => (
    <div className="col-6 col-md-4 col-lg-2 p-1">
      <div
        className="bg-white shadow p-1"
        style={{ border: "1px dashed #ffcc00", borderRadius: "15px" }}
      >
        <img
          src={`${IMAGE_PATH}/product/${product.prod_image_link}`}
          alt="img-product-list"
          width="100%"
          style={{ borderRadius: "15px", aspectRatio: "1 / 1" }}
        />
        <div className="text-truncate">
          <small className="p-1" style={{ textTransform: "capitalize" }}>
            {product.prod_full_name}
          </small>
        </div>
        <div className="row">
          <div
            className="col-6 text-center text-danger"
            style={{ fontSize: 14, fontWeight: "bold" }}
          >
            <del>{parseFloat(product.prod_mrp).toFixed(2)}</del>
          </div>
          <div
            className="col-6 text-center text-success"
            style={{ fontSize: 14, fontWeight: "bold" }}
          >
            {"\u20B9" + parseFloat(product.final_sale_price).toFixed(2)}
          </div>
        </div>
        <Link to={`/view-product/${upath}/${product.id}`}>
          <button
            className="btn btn-white border form-control p-1"
            style={{ borderRadius: "15px" }}
          >
            View More
          </button>
        </Link>
      </div>
    </div>
  );

  return (
    <div className="container mt-5">
      {spinner && <Spinner />}
      <div className="row m-0">
        {pdata.length > 0 ? (
          pdata.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))
        ) : (
          <div className="text-center w-100">
            <h4 className="text-secondary">
              Product or service not disclosed by owner.
            </h4>
            <Link to={`/contact/${upath}`}>
              <button className="btn btn-white rounded-pill border shadow mt-3">
                Find contact details
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductList;
