import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_PATH, IMAGE_PATH } from "../../Config";
import Spinner from "../../layout/common/Spinner";
import GoToHome from "./GoToHome";

export default function StoreFrontTeam() {
  const [spinner, setspinner] = useState(false);

  const params = useParams();
  const upath = params.id;

  const [teamdata, setteamdata] = useState([]);
  const [businessname, setbusinessname] = useState();
  const [status, setstatus] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (upath.length > 0) {
      setspinner(true);
      axios
        .post(API_PATH + "ManageStoreFront.php", {
          action: "getBusinessTeamData",
          upath: upath,
        })
        .then((r) => {
          if (r.data.status === "success") {
            setteamdata(r.data.retval);
            setbusinessname(r.data.business_name);
          } else {
            setstatus("failed");
          }
          setspinner(false);
        });
    }
  }, [upath]);
  return (
    <div className="mt-5 pt-4 mh-90">
      {spinner === true && <Spinner />}
      <div className="container">
        <GoToHome link={"/in/" + upath} />
        {status === "failed" ? (
          <>
            <h4 className="text-center mt-3">
              Sorry, No Team is displayed for this business yet.
            </h4>
            <p className="text-center">
              Some times businesses are too busy serving things to you and dont
              get time to update infromations here. If you are looking for some
              specific information. You can contact business by given button
              link.
            </p>
            <div className="text-center">
              <Link to={"/contact/" + upath}>
                <button className="btn btn-primary">Contact Business</button>
              </Link>
            </div>
          </>
        ) : (
          <>
            <div
              className="text-center mb-4 mt-3"
              style={{ textTransform: "uppercase" }}
            >
              <h4 className="text-secondary">Team of {businessname}</h4>
            </div>
            <div className="row">
              {teamdata.map((d) => {
                return (
                  <div className="col-md-3 mt-2" key={d.id}>
                    <div className="rounded bg-white shadow border p-0 pb-2">
                      <img
                        src={IMAGE_PATH + "business-team/" + d.image_link}
                        className="rounded"
                        alt=""
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          aspectRatio: "1/1",
                        }}
                      />
                      <div className="text-center">
                        <strong>{d.full_name}</strong>
                      </div>
                      <div className="text-center">
                        <strong>{d.designation}</strong>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
