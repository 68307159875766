import React from "react";

export default function Branding() {
  return (
    <div className="container pt-5 pb-5 ">
      <h2 className="text-center">Powered By</h2>
      <p className="text-center">
        A spacial thanks to everyone behind the project.
      </p>
      <div className="row m-0 mt-3">
        <div className="col-md-1"></div>
        <div className="col-md-1"></div>
        <div className="col-md-1"></div>
        <div className="col-md-1"></div>
        {/* <div className="col-md-1 col-3 p-1">
          <img
            src={require("./../../../assets/img/innovilla-logo.webp")}
            style={{ aspectRatio: "1/1", borderRadius: "50%" }}
            width={"200px"}
            alt=""
            className="shadow w-100"
          />
        </div> */}
        <div className="col-md-1 col-3 p-1">
          <img
            src={require("./../../../assets/img/rec-banda-logo.webp")}
            style={{ aspectRatio: "1/1", borderRadius: "50%" }}
            width={"200px"}
            alt=""
            className="shadow w-100 p-2 bg-white"
          />
        </div>
        <div className="col-md-1 col-3 p-1">
          <img
            src={require("./../../../assets/img/imbhu.webp")}
            style={{ aspectRatio: "1/1", borderRadius: "50%" }}
            width={"200px"}
            alt=""
            className="shadow w-100"
          />
        </div>
        <div className="col-md-1 col-3 p-1">
          <img
            src={require("./../../../assets/img/startupindia.webp")}
            style={{ aspectRatio: "1/1", borderRadius: "50%" }}
            width={"200px"}
            alt=""
            className="shadow w-100"
          />
        </div>
        <div className="col-md-1 col-3 p-1">
          <img
            src={require("./../../../assets/img/startinup.webp")}
            style={{ aspectRatio: "1/1", borderRadius: "50%" }}
            width={"200px"}
            alt=""
            className="shadow w-100"
          />
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-1"></div>
        <div className="col-md-1"></div>
        <div className="col-md-1"></div>
      </div>
    </div>
  );
}
