import React from "react";

function FaqAccordian() {
  return (
    <>
      <div className="container mt-5 pt-5">
        <h1 className="text-center">Frequently asked questions</h1>
        <div className="accordion" id="faqAccordion">
          {/* <!-- FAQ 1 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading1">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse1"
                aria-expanded="true"
                aria-controls="collapse1"
              >
                What is Sellio Business?
              </button>
            </h2>
            <div
              id="collapse1"
              className="accordion-collapse collapse alert alert-primary show"
              aria-labelledby="heading1"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Sellio Business is a SaaS-based business management platform
                offering features like business profile creation, product
                listing, POS billing, stock tracking, and more.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 2 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading2">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse2"
                aria-expanded="false"
                aria-controls="collapse2"
              >
                How much does Sellio Business cost?
              </button>
            </h2>
            <div
              id="collapse2"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading2"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Sellio Business costs Rs. 6999 per year, which is approximately
                Rs. 19 per day.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 3 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading3">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse3"
                aria-expanded="false"
                aria-controls="collapse3"
              >
                What is included in the business profile creation?
              </button>
            </h2>
            <div
              id="collapse3"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading3"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                It includes your business details, contact information, customer
                reviews, product listings, and more.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 4 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading4">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse4"
                aria-expanded="false"
                aria-controls="collapse4"
              >
                Can I list products on Sellio Business?
              </button>
            </h2>
            <div
              id="collapse4"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading4"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can list products with detailed descriptions and
                images.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 5 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading5">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse5"
                aria-expanded="false"
                aria-controls="collapse5"
              >
                What is the unique business QR code?
              </button>
            </h2>
            <div
              id="collapse5"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading5"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                It is a QR code that directs customers to your business profile
                and product listings.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 6 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading6">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse6"
                aria-expanded="false"
                aria-controls="collapse6"
              >
                How does the POS billing module work?
              </button>
            </h2>
            <div
              id="collapse6"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading6"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                The POS billing module generates invoices, processes payments,
                and manages inventory.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 7 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading7">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse7"
                aria-expanded="false"
                aria-controls="collapse7"
              >
                What kind of analytics does Sellio Business provide?
              </button>
            </h2>
            <div
              id="collapse7"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading7"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                It provides sales reports, customer behavior analytics, and
                performance metrics.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 8 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading8">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse8"
                aria-expanded="false"
                aria-controls="collapse8"
              >
                Can I track my stock using Sellio Business?
              </button>
            </h2>
            <div
              id="collapse8"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading8"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can track your inventory levels and manage stock
                efficiently.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 9 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading9">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse9"
                aria-expanded="false"
                aria-controls="collapse9"
              >
                How do I sign up for Sellio Business?
              </button>
            </h2>
            <div
              id="collapse9"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading9"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Visit our website at sellio.in and click on the sign-up button
                to create an account.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 10 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading10">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse10"
                aria-expanded="false"
                aria-controls="collapse10"
              >
                Is there a free trial available?
              </button>
            </h2>
            <div
              id="collapse10"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading10"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Currently, we do not offer a free trial, but our pricing is very
                affordable.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 11 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading11">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse11"
                aria-expanded="false"
                aria-controls="collapse11"
              >
                Can I manage multiple businesses with one account?
              </button>
            </h2>
            <div
              id="collapse11"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading11"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can manage multiple business profiles from a single
                account.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 12 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading12">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse12"
                aria-expanded="false"
                aria-controls="collapse12"
              >
                How can I update my business profile?
              </button>
            </h2>
            <div
              id="collapse12"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading12"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can update your profile through the dashboard on our
                platform.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 13 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading13">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse13"
                aria-expanded="false"
                aria-controls="collapse13"
              >
                Does Sellio Business support multiple languages?
              </button>
            </h2>
            <div
              id="collapse13"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading13"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Currently, the platform supports English and Hindi.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 14 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading14">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse14"
                aria-expanded="false"
                aria-controls="collapse14"
              >
                What payment methods are accepted for the subscription?
              </button>
            </h2>
            <div
              id="collapse14"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading14"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                We accept credit/debit cards, net banking, and UPI.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 15 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading15">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse15"
                aria-expanded="false"
                aria-controls="collapse15"
              >
                Is my data secure on Sellio Business?
              </button>
            </h2>
            <div
              id="collapse15"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading15"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, we use advanced security measures to ensure your data is
                safe.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 16 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading16">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse16"
                aria-expanded="false"
                aria-controls="collapse16"
              >
                Can I cancel my subscription anytime?
              </button>
            </h2>
            <div
              id="collapse16"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading16"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can cancel your subscription at any time through your
                account settings.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 17 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading17">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse17"
                aria-expanded="false"
                aria-controls="collapse17"
              >
                Will I get a refund if I cancel my subscription?
              </button>
            </h2>
            <div
              id="collapse17"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading17"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                We do not offer refunds, but you can continue using the service
                until the end of your subscription period.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 18 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading18">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse18"
                aria-expanded="false"
                aria-controls="collapse18"
              >
                How do I contact customer support?
              </button>
            </h2>
            <div
              id="collapse18"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading18"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can contact us via email at support@sellio.in or through our
                website's contact form.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 19 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading19">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse19"
                aria-expanded="false"
                aria-controls="collapse19"
              >
                Can I import my existing product list into Sellio Business?
              </button>
            </h2>
            <div
              id="collapse19"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading19"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can import your product list through a CSV file. It is
                not supported on basic plan.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 20 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading20">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse20"
                aria-expanded="false"
                aria-controls="collapse20"
              >
                How often is the platform updated?
              </button>
            </h2>
            <div
              id="collapse20"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading20"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                We regularly update the platform to add new features and improve
                existing ones.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 21 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading21">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse21"
                aria-expanded="false"
                aria-controls="collapse21"
              >
                Is there a mobile app for Sellio Business?
              </button>
            </h2>
            <div
              id="collapse21"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading21"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, our mobile app is available for both Android and iOS
                devices.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 22 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading22">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse22"
                aria-expanded="false"
                aria-controls="collapse22"
              >
                Can I use Sellio Business for an e-commerce store?
              </button>
            </h2>
            <div
              id="collapse22"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading22"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, Sellio Business is ideal for e-commerce stores to manage
                products and sales. This feature required upgraded plans.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 23 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading23">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse23"
                aria-expanded="false"
                aria-controls="collapse23"
              >
                What are the benefits of blogging on Sellio Business?
              </button>
            </h2>
            <div
              id="collapse23"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading23"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Blogging helps engage your audience, drive traffic, and improve
                SEO.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 24 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading24">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse24"
                aria-expanded="false"
                aria-controls="collapse24"
              >
                How do I add new products to my catalogue?
              </button>
            </h2>
            <div
              id="collapse24"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading24"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can add new products through the dashboard by entering
                product details and uploading images.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 25 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading25">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse25"
                aria-expanded="false"
                aria-controls="collapse25"
              >
                Can I generate sales reports on Sellio Business?
              </button>
            </h2>
            <div
              id="collapse25"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading25"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, the platform provides detailed sales reports.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 26 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading26">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse26"
                aria-expanded="false"
                aria-controls="collapse26"
              >
                Does Sellio Business integrate with other platforms?
              </button>
            </h2>
            <div
              id="collapse26"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading26"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Currently, we offer integration with select third-party tools.
                More integrations are coming soon.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 27 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading27">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse27"
                aria-expanded="false"
                aria-controls="collapse27"
              >
                How do I reset my password?
              </button>
            </h2>
            <div
              id="collapse27"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading27"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can reset your password using the 'Forgot Password' option
                on the login page.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 28 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading28">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse28"
                aria-expanded="false"
                aria-controls="collapse28"
              >
                Can I offer discounts to my customers?
              </button>
            </h2>
            <div
              id="collapse28"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading28"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can create and manage discount offers through the
                platform.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 29 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading29">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse29"
                aria-expanded="false"
                aria-controls="collapse29"
              >
                How do I manage categories for my products?
              </button>
            </h2>
            <div
              id="collapse29"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading29"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can create and manage product categories through the
                dashboard.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 30 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading30">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse30"
                aria-expanded="false"
                aria-controls="collapse30"
              >
                Can I access Sellio Business from any device?
              </button>
            </h2>
            <div
              id="collapse30"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading30"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can access the platform from any device with internet
                access.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 31 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading31">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse31"
                aria-expanded="false"
                aria-controls="collapse31"
              >
                How do I handle tax calculations?
              </button>
            </h2>
            <div
              id="collapse31"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading31"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                The POS billing module handles tax calculations automatically.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 32 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading32">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse32"
                aria-expanded="false"
                aria-controls="collapse32"
              >
                Can I customize the appearance of my business profile?
              </button>
            </h2>
            <div
              id="collapse32"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading32"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can customize the look and feel of your business
                profile.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 33 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading33">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse33"
                aria-expanded="false"
                aria-controls="collapse33"
              >
                Are there any tutorials available?
              </button>
            </h2>
            <div
              id="collapse33"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading33"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, we provide tutorials and guides in our help center.
              </div>
            </div>
          </div>
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading34">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse34"
                aria-expanded="false"
                aria-controls="collapse34"
              >
                Can I track employee performance?
              </button>
            </h2>
            <div
              id="collapse34"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading34"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can monitor employee sales and performance metrics.
                Feature will be available in upcomin plans.
              </div>
            </div>
          </div>
          {/* <!-- FAQ 35 --> */}
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading35">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse35"
                aria-expanded="false"
                aria-controls="collapse35"
              >
                How do I manage customer feedback and reviews?
              </button>
            </h2>
            <div
              id="collapse35"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading35"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can manage and respond to customer reviews through the
                platform.
              </div>
            </div>
          </div>
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading36">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse36"
                aria-expanded="false"
                aria-controls="collapse36"
              >
                What is the setup time for a new account?
              </button>
            </h2>
            <div
              id="collapse36"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading36"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Setting up a new account typically takes less than 15 minutes.
              </div>
            </div>
          </div>
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading37">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse37"
                aria-expanded="false"
                aria-controls="collapse37"
              >
                Can I offer multiple payment options to my customers?
              </button>
            </h2>
            <div
              id="collapse37"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading37"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can offer various payment options like cards, UPI, and
                net banking.
              </div>
            </div>
          </div>
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading38">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse38"
                aria-expanded="false"
                aria-controls="collapse38"
              >
                How do I update product prices?
              </button>
            </h2>
            <div
              id="collapse38"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading38"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can update product prices in the product listing section.
              </div>
            </div>
          </div>
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading39">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse39"
                aria-expanded="false"
                aria-controls="collapse39"
              >
                Does Sellio Business support barcode scanning?
              </button>
            </h2>
            <div
              id="collapse39"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading39"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, our POS system supports barcode scanning for quick billing.
              </div>
            </div>
          </div>
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading40">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse40"
                aria-expanded="false"
                aria-controls="collapse40"
              >
                Can I generate purchase orders?
              </button>
            </h2>
            <div
              id="collapse40"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading40"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can create and manage purchase orders through the
                platform. This feature will be available in upcoming plans.
              </div>
            </div>
          </div>
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading41">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse41"
                aria-expanded="false"
                aria-controls="collapse41"
              >
                What kind of reports can I generate?
              </button>
            </h2>
            <div
              id="collapse41"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading41"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can generate sales, inventory, and customer reports. This
                feature will be available in upcomin plans.
              </div>
            </div>
          </div>
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading42">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse42"
                aria-expanded="false"
                aria-controls="collapse42"
              >
                How do I handle returns and refunds?
              </button>
            </h2>
            <div
              id="collapse42"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading42"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can manage returns and process refunds through the platform.
                This is an upcoming feature and requires plan elevation.
              </div>
            </div>
          </div>
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading43">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse43"
                aria-expanded="false"
                aria-controls="collapse43"
              >
                Can I track inventory levels?
              </button>
            </h2>
            <div
              id="collapse43"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading43"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can monitor inventory levels and receive low-stock
                alerts.
              </div>
            </div>
          </div>
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading44">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse44"
                aria-expanded="false"
                aria-controls="collapse44"
              >
                How do I add new users to my account?
              </button>
            </h2>
            <div
              id="collapse44"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading44"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can add new users through the account settings. This
                requires elevated plans.
              </div>
            </div>
          </div>
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading45">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse45"
                aria-expanded="false"
                aria-controls="collapse45"
              >
                Can I set different access levels for users?
              </button>
            </h2>
            <div
              id="collapse45"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading45"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can assign different roles and permissions to users.
                This requires elevated plan and will be available in upcoming
                plans.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading46">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse46"
                aria-expanded="false"
                aria-controls="collapse46"
              >
                Can I export my data from Sellio Business?
              </button>
            </h2>
            <div
              id="collapse46"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading46"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can export your data in CSV format.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading47">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse47"
                aria-expanded="false"
                aria-controls="collapse47"
              >
                Is there a limit to the number of products I can list?
              </button>
            </h2>
            <div
              id="collapse47"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading47"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                No, there is no limit to the number of products you can list on
                Sellio Business.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading48">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse48"
                aria-expanded="false"
                aria-controls="collapse48"
              >
                How do I update my business profile information?
              </button>
            </h2>
            <div
              id="collapse48"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading48"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can update your business profile information from the
                dashboard.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading49">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse49"
                aria-expanded="false"
                aria-controls="collapse49"
              >
                Does Sellio Business support multi-currency transactions?
              </button>
            </h2>
            <div
              id="collapse49"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading49"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Currently, we support transactions in INR. Multi-currency
                support will be added soon.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading50">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse50"
                aria-expanded="false"
                aria-controls="collapse50"
              >
                Can I manage multiple stores with Sellio Business?
              </button>
            </h2>
            <div
              id="collapse50"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading50"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can manage multiple stores from a single Sellio
                Business account.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading51">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse51"
                aria-expanded="false"
                aria-controls="collapse51"
              >
                How do I handle customer data?
              </button>
            </h2>
            <div
              id="collapse51"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading51"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can manage customer data securely through the CRM feature on
                Sellio Business.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading52">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse52"
                aria-expanded="false"
                aria-controls="collapse52"
              >
                Does Sellio Business offer SEO tools?
              </button>
            </h2>
            <div
              id="collapse52"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading52"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, we provide basic SEO tools to improve your online
                visibility.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading53">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse53"
                aria-expanded="false"
                aria-controls="collapse53"
              >
                Can I customize my POS receipts?
              </button>
            </h2>
            <div
              id="collapse53"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading53"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                No, you can not customize the receipts generated by the POS
                system.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading54">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse54"
                aria-expanded="false"
                aria-controls="collapse54"
              >
                What kind of analytics does Sellio Business provide?
              </button>
            </h2>
            <div
              id="collapse54"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading54"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Sellio Business provides detailed analytics on sales, inventory,
                and customer behavior.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading55">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse55"
                aria-expanded="false"
                aria-controls="collapse55"
              >
                How can I promote my business on Sellio Business?
              </button>
            </h2>
            <div
              id="collapse55"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading55"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can promote your business through blogging, social media
                integration, and SEO tools available on Sellio Business.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading56">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse56"
                aria-expanded="false"
                aria-controls="collapse56"
              >
                Can I schedule posts for my blog?
              </button>
            </h2>
            <div
              id="collapse56"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading56"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                No, but in upcoming version you can schedule blog posts to be
                published at a later date.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading57">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse57"
                aria-expanded="false"
                aria-controls="collapse57"
              >
                How do I manage shipping and delivery options?
              </button>
            </h2>
            <div
              id="collapse57"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading57"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can manage shipping and delivery options through the
                platform's shipping module.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading58">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse58"
                aria-expanded="false"
                aria-controls="collapse58"
              >
                Does Sellio Business support multi-language?
              </button>
            </h2>
            <div
              id="collapse58"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading58"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, Sellio Business supports multiple languages.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading59">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse59"
                aria-expanded="false"
                aria-controls="collapse59"
              >
                Can I track my orders in real-time?
              </button>
            </h2>
            <div
              id="collapse59"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading59"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can track your orders in real-time through the
                dashboard.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading60">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse60"
                aria-expanded="false"
                aria-controls="collapse60"
              >
                How do I handle international shipping?
              </button>
            </h2>
            <div
              id="collapse60"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading60"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Currently, You can not set up international shipping options in
                the shipping module.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading61">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse61"
                aria-expanded="false"
                aria-controls="collapse61"
              >
                Can I import my existing product catalog?
              </button>
            </h2>
            <div
              id="collapse61"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading61"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can import your existing product catalog via CSV
                upload. This requires plan elevation.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading62">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse62"
                aria-expanded="false"
                aria-controls="collapse62"
              >
                How secure is my data on Sellio Business?
              </button>
            </h2>
            <div
              id="collapse62"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading62"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                We use advanced security measures, including encryption and
                regular backups, to protect your data.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading63">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse63"
                aria-expanded="false"
                aria-controls="collapse63"
              >
                Can I set up recurring payments?
              </button>
            </h2>
            <div
              id="collapse63"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading63"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can set up and manage recurring payments for your
                customers. This requires plan elevation.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading64">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse64"
                aria-expanded="false"
                aria-controls="collapse64"
              >
                Does Sellio Business offer customer support?
              </button>
            </h2>
            <div
              id="collapse64"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading64"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, we offer 24/7 customer support via chat, email, and phone.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading65">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse65"
                aria-expanded="false"
                aria-controls="collapse65"
              >
                How do I get started with Sellio Business?
              </button>
            </h2>
            <div
              id="collapse65"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading65"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can get started by signing up on our website and following
                the setup instructions.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading66">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse66"
                aria-expanded="false"
                aria-controls="collapse66"
              >
                Can I use Sellio Business for my retail store?
              </button>
            </h2>
            <div
              id="collapse66"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading66"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, Sellio Business is ideal for retail stores of all sizes.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading67">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse67"
                aria-expanded="false"
                aria-controls="collapse67"
              >
                Can I manage my suppliers through Sellio Business?
              </button>
            </h2>
            <div
              id="collapse67"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading67"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can manage your suppliers and purchase orders through
                the platform.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading68">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse68"
                aria-expanded="false"
                aria-controls="collapse68"
              >
                How do I set up notifications?
              </button>
            </h2>
            <div
              id="collapse68"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading68"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can configure notifications in the settings section. This is
                an upcomin feature.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading69">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse69"
                aria-expanded="false"
                aria-controls="collapse69"
              >
                Does Sellio Business integrate with other software?
              </button>
            </h2>
            <div
              id="collapse69"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading69"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, Sellio Business integrates with various third-party
                software and services.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading70">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse70"
                aria-expanded="false"
                aria-controls="collapse70"
              >
                How do I manage tax settings?
              </button>
            </h2>
            <div
              id="collapse70"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading70"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can configure tax settings in the billing module.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading71">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse71"
                aria-expanded="false"
                aria-controls="collapse71"
              >
                Can I use Sellio Business for service-based businesses?
              </button>
            </h2>
            <div
              id="collapse71"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading71"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, Sellio Business is suitable for both product and
                service-based businesses.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading72">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse72"
                aria-expanded="false"
                aria-controls="collapse72"
              >
                How do I handle customer loyalty programs?
              </button>
            </h2>
            <div
              id="collapse72"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading72"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can create and manage customer loyalty programs through the
                CRM module in upcoming versions, Currently we don't support this
                feature.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading73">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse73"
                aria-expanded="false"
                aria-controls="collapse73"
              >
                Can I generate invoices through Sellio Business?
              </button>
            </h2>
            <div
              id="collapse73"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading73"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can generate invoices for transactions made through
                Sellio Business.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading74">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse74"
                aria-expanded="false"
                aria-controls="collapse74"
              >
                How does Sellio Business ensure data privacy?
              </button>
            </h2>
            <div
              id="collapse74"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading74"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Sellio Business adheres to strict data privacy regulations and
                employs encryption and secure storage methods.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading75">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse75"
                aria-expanded="false"
                aria-controls="collapse75"
              >
                Can I customize the appearance of my online store?
              </button>
            </h2>
            <div
              id="collapse75"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading75"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can customize the design and layout of your online
                store using Sellio Business's theme editor.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading76">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse76"
                aria-expanded="false"
                aria-controls="collapse76"
              >
                How do I accept payments on Sellio Business?
              </button>
            </h2>
            <div
              id="collapse76"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading76"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Sellio Business integrates with various payment gateways,
                allowing you to accept online payments securely.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading77">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse77"
                aria-expanded="false"
                aria-controls="collapse77"
              >
                Can I offer discounts and promotions on Sellio Business?
              </button>
            </h2>
            <div
              id="collapse77"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading77"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can create and manage discounts and promotions for your
                products or services.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading78">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse78"
                aria-expanded="false"
                aria-controls="collapse78"
              >
                Can I send automated email notifications to customers?
              </button>
            </h2>
            <div
              id="collapse78"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading78"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                No, you can not set up automated email notifications for various
                events such as order confirmation, shipping updates, and more in
                current plan structure.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading79">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse79"
                aria-expanded="false"
                aria-controls="collapse79"
              >
                Can I access Sellio Business on mobile devices?
              </button>
            </h2>
            <div
              id="collapse79"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading79"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, Sellio Business is accessible on mobile devices through a
                responsive web interface.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading80">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse80"
                aria-expanded="false"
                aria-controls="collapse80"
              >
                Is Sellio Business suitable for e-commerce businesses?
              </button>
            </h2>
            <div
              id="collapse80"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading80"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, Sellio Business is designed to meet the needs of e-commerce
                businesses, including product listings, online payments, and
                order management.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading81">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse81"
                aria-expanded="false"
                aria-controls="collapse81"
              >
                Can I integrate Sellio Business with my existing website?
              </button>
            </h2>
            <div
              id="collapse81"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading81"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, Sellio Business offers integration options for existing
                websites. You need to contact support team for this.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading82">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse82"
                aria-expanded="false"
                aria-controls="collapse82"
              >
                Can I create and manage coupons?
              </button>
            </h2>
            <div
              id="collapse82"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading82"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can create and manage coupons to offer discounts to
                your customers. This requires plan elevation.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading84">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse84"
                aria-expanded="false"
                aria-controls="collapse84"
              >
                Can I schedule automated reports?
              </button>
            </h2>
            <div
              id="collapse84"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading84"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can schedule automated reports to be generated and sent
                at specified intervals.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading85">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse85"
                aria-expanded="false"
                aria-controls="collapse85"
              >
                Can I import data from other platforms?
              </button>
            </h2>
            <div
              id="collapse85"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading85"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can import data from other platforms using compatible
                file formats. This is an upcoming feature.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading86">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse86"
                aria-expanded="false"
                aria-controls="collapse86"
              >
                How does Sellio Business handle taxes?
              </button>
            </h2>
            <div
              id="collapse86"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading86"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Sellio Business provides tools for managing taxes, including tax
                calculation and reporting.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading87">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse87"
                aria-expanded="false"
                aria-controls="collapse87"
              >
                Can I export reports from Sellio Business?
              </button>
            </h2>
            <div
              id="collapse87"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading87"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can export reports generated by Sellio Business in
                various formats. This is an upcoming feature and requires plan
                elevation.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading88">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse88"
                aria-expanded="false"
                aria-controls="collapse88"
              >
                How do I manage customer subscriptions?
              </button>
            </h2>
            <div
              id="collapse88"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading88"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can manage customer subscriptions through the billing
                module.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading89">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse89"
                aria-expanded="false"
                aria-controls="collapse89"
              >
                Can I offer gift cards on Sellio Business?
              </button>
            </h2>
            <div
              id="collapse89"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading89"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can create and sell gift cards through Sellio Business.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading90">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse90"
                aria-expanded="false"
                aria-controls="collapse90"
              >
                Can I manage employee accounts?
              </button>
            </h2>
            <div
              id="collapse90"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading90"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yes, you can create and manage employee accounts with different
                access levels. This is an upcoming feature and requires plan
                elevation.
              </div>
            </div>
          </div>
          <div className="accordion-item mt-2 shadow">
            <h2 className="accordion-header" id="heading92">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse92"
                aria-expanded="false"
                aria-controls="collapse92"
              >
                How do I manage product variants?
              </button>
            </h2>
            <div
              id="collapse92"
              className="accordion-collapse collapse alert alert-primary"
              aria-labelledby="heading92"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                You can create and manage product variants such as size, color,
                and style within Sellio Business.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FaqAccordian;
