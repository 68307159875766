import React, { useState } from "react";
import { gemini } from "../../Config";

const AiGeminiIntroWriter = (props) => {
  const [loading, setloading] = useState(false);
  const generateintro = () => {
    setloading(true);
    props.introtext("");
    let prompt = `create a business introduction in 1000 words 
                    where `;
    props.data.b_name && (prompt += ` business name = ${props.data.b_name} `);

    props.data.key_person_fname &&
      (prompt += ` and Contact person = ${props.data.key_person_fname} ${props.data.key_person_mname} ${props.data.key_person_lname}`);

    props.data.key_person_profile &&
      (prompt += ` and Contact persons profile  = ${props.data.key_person_profile}`);

    props.data.b_type &&
      (prompt += ` and business type = ${props.data.registered_address}`);

    props.data.registered_address &&
      (prompt += ` and registered address = ${props.data.registered_address}`);

    props.data.registered_city &&
      (prompt += ` and city = ${props.data.registered_city}`);

    props.data.registered_state &&
      (prompt += ` and state = ${props.data.registered_state}`);

    props.data.b_category &&
      (prompt += ` and business category = ${props.data.b_category}`);

    props.data.b_sub_cat_1 &&
      (prompt += ` and business sub category 1 = ${props.data.b_sub_cat_1}`);

    props.data.b_sub_cat_2 &&
      (prompt += ` and business sub category 2 = ${props.data.b_sub_cat_2}`);

    props.data.b_sub_cat_3 &&
      (prompt += ` and business sub category 3 = ${props.data.b_sub_cat_3}`);

    props.data.b_sub_cat_4 &&
      (prompt += ` and business sub category 4 = ${props.data.b_sub_cat_4}. `);

    prompt += ` follow these instructions as well. dont add placeholders. provide final text that doesnt need any change. dont add contact us section. format text like html editor output`;

    console.log(prompt);

    gemini(prompt).then((text) => {
      console.log(text);
      props.introtext(text);
      setloading(false);
    });
  };
  return (
    <div className="d-flex justify-content-end">
      {loading === true ? (
        "AI is on work, Be patient..."
      ) : (
        <button
          type="submit"
          className="btn btn-primary rounded-pill"
          onClick={generateintro}
        >
          Generate with AI
        </button>
      )}
    </div>
  );
};

export default AiGeminiIntroWriter;
