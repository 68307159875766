import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_PATH } from "../../Config";
import Spinner from "../../layout/common/Spinner";

//  modal gets current business path and validates that if customer has already submitted
//  contact details for current business or not , if contact details are already there
//  for current date, modal will not fire for that particular business profile.
//  localStorage.getItem(`sellio-contact-${upath}`) is setup to store value for each business
//  page where contact request has been submitted

const ModalContactRequest = () => {
  const [show, setShow] = useState(false);
  const [contactnumber, setcontactnumber] = useState("");
  const [fullname, setfullname] = useState("");
  const [statustype, setstatustype] = useState("");
  const [spinner, setspinner] = useState(false);

  //   get profile id
  const params = useParams();
  const upath = params.id;

  // -------------------------------------------------------------------------------------------------------->
  // Date is initialized and being converted to match format with mysql database
  // Get the current date
  const currentDate = new Date();

  // Extract the year, month, and day components
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, "0");

  // Format the date as YYYY-MM-DD
  const currentdate = `${year}-${month}-${day}`;

  // -------------------------------------------------------------------------------------------------------->

  useEffect(() => {
    // Show the modal on page load
    if (
      !localStorage.getItem(`sellio-contact-${upath}`) &&
      localStorage.getItem("sellio-contact-request-date") !== currentDate
    ) {
      setShow(true);
    }
  }, []);

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (show) {
      const myModal = new window.bootstrap.Modal(
        document.getElementById("exampleModal")
      );
      myModal.show();
    }
  }, [show]);

  const submitcontact = (e) => {
    if (contactnumber) {
      localStorage.setItem(`sellio-contact-${upath}`, contactnumber);
      localStorage.setItem("sellio-contact-request-date", currentdate);

      setspinner(true);
      //   process data
      axios
        .post(API_PATH + "ManageStoreFront.php", {
          action: "sendcontactrequestfromprofilevisit",
          upath: upath,
          fullname: fullname,
          contactnumber: contactnumber,
        })
        .then((r) => {
          if (r.data.status === "success") {
            setfullname("");
            setcontactnumber("");
            setstatustype("success");
          } else {
            setstatustype("failed");
          }
          setspinner(false);
        });
    } else {
      setstatustype("failed");
    }
  };

  return (
    <div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Submit your contact number and get a call from this business.
              </h5>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              {spinner === true ? (
                <div className="text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="">
                    <input
                      type="text"
                      className="form-control rounded-pill bg-light"
                      name="customer_name"
                      placeholder="Enter Your Name"
                      value={fullname}
                      onChange={(e) => setfullname(e.target.value)}
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="number"
                      min={"1000000000"}
                      max={"99999999999"}
                      className="form-control  rounded-pill bg-light"
                      name="customer_contact"
                      placeholder="Enter Contact number"
                      value={contactnumber}
                      onChange={(e) => setcontactnumber(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="alert alert-primary p-1 text-center">
              <small>
                You need to submit contact details only once a day on any
                business profile.
              </small>
            </div>
            <div>
              {statustype === "success" && (
                <div className="alert alert-success text-center">
                  Your contact request has been submitted, this business will
                  contact you very soon.
                </div>
              )}

              {statustype === "failed" && (
                <div className="alert alert-danger  text-center">
                  Something went wrong, please try again later! Check if you
                  have entered correct number.
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary rounded-pill"
                onClick={submitcontact}
              >
                Save changes
              </button>
              <button
                type="button"
                className="btn btn-secondary rounded-pill"
                data-bs-dismiss="modal"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalContactRequest;
