import React from "react";
import { Link } from "react-router-dom";

export default function NavlinksGeneralUser() {
  return (
    <div className="navlinks">
      <ul>
        <li>
          <i className="bi bi-person-bounding-box"></i> Account
          <ul className="child-nav">
            {/* <li>
              <Link to="/dashboard">
                <i className="bi bi-speedometer"></i> Dashboard
              </Link>
            </li> */}
            {/* <li>
              <Link to="/dashboard">
                <i className="bi bi-person-check"></i> Profile
              </Link>
            </li> */}
            <li>
              <Link to="/delivery-address">
                <i className="bi bi-house-heart"></i> Addresses
              </Link>
            </li>
            <li>
              <Link to="/reset-password">
                <i className="bi bi-shield-lock"></i> Password Reset
              </Link>
            </li>
            <li>
              <Link to="/logout">
                <i className="bi bi-door-open"></i> Logout
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to={"/search-business"}>
            <i className="bi bi-search"></i> Search business
          </Link>
        </li>
        <li>
          <Link to={"/store"}>
            <i className="bi bi-shop"></i> Store
          </Link>
        </li>
        <li>
          <i className="bi bi-bag-heart"></i> Shopping
          <ul className="child-nav">
            <li>
              <Link to="/cart">
                <i className="bi bi-cart-check"></i> Cart
              </Link>
            </li>
            <li>
              <Link to="/order-history">
                <i className="bi bi-hourglass-bottom"></i> Order History
              </Link>
            </li>
            <li>
              <Link to="/wishlist">
                <i className="bi bi-balloon-heart"></i> Wishlist
              </Link>
            </li>
            <li>
              <Link to="/delivery-address">
                <i className="bi bi-signpost"></i> Addresses
              </Link>
            </li>
            {/* <li>
              <Link to="/product-recommendations">
                <i className="bi bi-list-stars"></i> Recommendations
              </Link>
            </li> */}
            {/* <li>
              <Link to="/wallet">
                <i className="bi bi-wallet2"></i> Wallet
              </Link>
            </li> */}
          </ul>
        </li>
      </ul>
    </div>
  );
}
