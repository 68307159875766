import React from "react";
import CustomerPageContainer from "./CustomerPageContainer";
import { useState, useEffect } from "react";
import axios from "axios";
import { API_PATH, STATE_LIST, USER_ID, USER_TOKEN } from "../../Config";
import { Navigate, useNavigate } from "react-router-dom";
import useLoginAuth from "../../hooks/useLoginAuth";

const CustomerAddress = () => {
  const [addresses, setAddresses] = useState([]);
  const [error, seterror] = useState("");
  const [successmsg, setsuccessmsg] = useState("");
  const [newAddress, setNewAddress] = useState({
    address: "",
    city: "",
    state: "",
    pincode: "",
    landmark: "",
    latitude: "",
    longitude: "",
    contact_person: "",
    contact_number: "",
    email: "",
    alt_contact: "",
    whatsapp_contact: "",
  });
  const loginstatus = useLoginAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fetchAddresses();
    captureGPSLocation();
  }, []);

  const fetchAddresses = async () => {
    try {
      const r = await axios.post(API_PATH + "ManageCart.php", {
        action: "getcustomeraddress",
        userid: USER_ID,
        usertoken: USER_TOKEN,
      });
      r.data.status === "success" && setAddresses(r.data.retval);
    } catch (error) {
      console.error("Error fetching addresses", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAddress({ ...newAddress, [name]: value });
  };

  const handleAddAddress = async (e) => {
    e.preventDefault();
    if (addresses.length >= 5) {
      alert("Maximum of 5 addresses allowed");
      return;
    }
    if (
      newAddress.address &&
      newAddress.city &&
      newAddress.state &&
      newAddress.pincode &&
      newAddress.contact_person &&
      newAddress.contact_number
    ) {
      try {
        const r = await axios.post(API_PATH + "ManageCart.php", {
          action: "addcustomeraddress",
          newAddress,
          userid: USER_ID,
          usertoken: USER_TOKEN,
        });

        if (r.data.status === "success") {
          setsuccessmsg("Address data updated!");
          setTimeout(() => {
            setsuccessmsg("");
          }, 5000);
          fetchAddresses();
          setNewAddress({
            userid: "",
            address: "",
            city: "",
            state: "",
            pincode: "",
            landmark: "",
            latitude: "",
            longitude: "",
            contact_person: "",
            contact_number: "",
            email: "",
            alt_contact: "",
            whatsapp_contact: "",
          });
        }
      } catch (error) {
        console.error("Error adding address", error);
      }
    } else {
      seterror(
        "Address form should contain all of address, city, state, pincode, contact person and contact number. Please fill up the form correctly."
      );
      setTimeout(() => {
        seterror("");
      }, 5000);
    }
    window.scrollTo(0, 0);
  };

  const handleDeleteAddress = async (id) => {
    try {
      const r = await axios.post(API_PATH + "ManageCart.php", {
        action: "deletecustomeraddress",
        delid: id,
        userid: USER_ID,
        usertoken: USER_TOKEN,
      });

      if (r.data.status === "success") {
        setsuccessmsg("Address data updated!");
        setTimeout(() => {
          setsuccessmsg("");
        }, 5000);
      }
      fetchAddresses();
      window.scrollTo(0, 0);
    } catch (error) {
      console.error("Error deleting address", error);
    }
  };

  const captureGPSLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setNewAddress((prevState) => ({
            ...prevState,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }));
        },
        (error) => {
          console.error("Error capturing GPS location", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <CustomerPageContainer>
      <div className="container mb-5">
        {error && <div className="alert alert-danger">{error}</div>}
        {successmsg && <div className="alert alert-success">{successmsg}</div>}
        <h4 className="text-start border-bottom pb-2">Add an Address</h4>
        <small>
          Turning on gps helps you capture accurate delivery location, if
          address belongs to your residence, please turn on the gps and let the
          system capture it.{" "}
        </small>
        <form onSubmit={handleAddAddress} className="mb-4 mt-4">
          <div className="row m-0">
            <div className="col-md-6 mb-1">
              <label className="form-label">Complete Address</label>
              <input
                type="text"
                className="form-control rounded-pill shadow-sm"
                name={"address"}
                value={newAddress["address"]}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-3 mb-1">
              <label className="form-label">City</label>
              <input
                type="text"
                className="form-control rounded-pill shadow-sm"
                name={"city"}
                value={newAddress["city"]}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-3 mb-1">
              <label className="form-label">State</label>
              <select
                className="form-control rounded-pill shadow-sm"
                name="state"
                value={newAddress["state"]}
                onChange={handleInputChange}
              >
                <option value="">Select State</option>
                {STATE_LIST.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3 mb-1">
              <label className="form-label">Pincode</label>
              <input
                type="number"
                min={100000}
                className="form-control rounded-pill shadow-sm"
                name={"pincode"}
                value={newAddress["pincode"]}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-3 mb-1">
              <label className="form-label">Landmark</label>
              <input
                type="text"
                className="form-control rounded-pill shadow-sm"
                name={"landmark"}
                value={newAddress["landmark"]}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-3 mb-1">
              <label className="form-label">Latitude</label>
              <input
                type="text"
                className="form-control rounded-pill shadow-sm"
                name={"latitude"}
                value={newAddress["latitude"]}
                onChange={handleInputChange}
                disabled={true}
              />
            </div>
            <div className="col-md-3 mb-1">
              <label className="form-label">Longitude</label>
              <input
                type="text"
                className="form-control rounded-pill shadow-sm"
                name={"longitude"}
                value={newAddress["longitude"]}
                onChange={handleInputChange}
                disabled={true}
              />
            </div>
            <div className="col-md-3 mb-1">
              <label className="form-label">Contact Person</label>
              <input
                type="text"
                className="form-control rounded-pill shadow-sm"
                name={"contact_person"}
                value={newAddress["contact_person"]}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-3 mb-1">
              <label className="form-label">Contact Number</label>
              <input
                type="number"
                min={1000000000}
                className="form-control rounded-pill shadow-sm"
                name={"contact_number"}
                value={newAddress["contact_number"]}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-3 mb-1">
              <label className="form-label">EMail Adress</label>
              <input
                type="email"
                className="form-control rounded-pill shadow-sm"
                name={"email"}
                value={newAddress["email"]}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-3 mb-1">
              <label className="form-label">Whatsapp Contact</label>
              <input
                type="number"
                min={1000000000}
                className="form-control rounded-pill shadow-sm"
                name={"whatsapp_contact"}
                value={newAddress["whatsapp_contact"]}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <button
                type="submit"
                className="mt-3 btn btn-primary rounded-pill shadow"
              >
                Add Address
              </button>
            </div>
          </div>
        </form>

        <h4 className="text-start border-bottom pb-2">Saved Addresses</h4>
        <ul className="list-group">
          {addresses.map((d, i) => (
            <li
              className="list-group-item d-flex justify-content-between align-items-center"
              key={i}
            >
              {d.contact_person} <br />
              {d.address}, {d.city}, {d.landmark}, {d.state} - {d.pincode}{" "}
              <br />
              Ph: {d.contact_number}
              <button
                className="btn btn-danger btn-sm rounded-pill shadow"
                onClick={() => handleDeleteAddress(d.id)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>
    </CustomerPageContainer>
  );
};

export default CustomerAddress;
