import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { API_PATH } from "../../Config";
import useLoginAuth from "../../hooks/useLoginAuth";
import Spinner from "../../layout/common/Spinner";
import ProfileCompletionDashboard from "./ProfileCompletionDashboard";

export default function UpdateSocialMediaLinks() {
  const [spinner, setspinner] = useState(false);
  const validatelogin = useLoginAuth();
  const navigate = useNavigate();
  const [profiledata, setprofiledata] = useState([]);
  const [profilescore, setprofilescore] = useState("");
  const [facebooklink, setfacebooklink] = useState("");
  const [twitterlink, settwitterlink] = useState("");
  const [instagramlink, setinstagramlink] = useState("");
  const [gbusinesslink, setgbusinesslink] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true);
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "getBusinessProfile",
        userid: localStorage.getItem("ez-userid"),
        usertoken: localStorage.getItem("ez-usertoken"),
      })
      .then((r) => {
        if (r.data.status === "success") {
          setprofiledata(r.data.retval);
          setprofilescore(r.data.profilescore + "%");
        } else {
          setprofilescore("0%");
        }
        setspinner(false);
      });
  }, []);

  const updateSocialMediaLinks = () => {
    let score = 0;

    if (facebooklink !== "") {
      score += 1;
    }

    if (twitterlink !== "") {
      score += 1;
    }

    if (instagramlink !== "") {
      score += 1;
    }

    if (gbusinesslink !== "") {
      score += 1;
    }

    if (score > 0) {
      setspinner(true);
      axios
        .post(API_PATH + "ManageStoreFront.php", {
          action: "updateSocialMediaLinks",
          userid: localStorage.getItem("ez-userid"),
          usertoken: localStorage.getItem("ez-usertoken"),
          facebook: facebooklink,
          twitter: twitterlink,
          instagram: instagramlink,
          gbusiness: gbusinesslink,
        })
        .then((r) => {
          if (r.data.status === "success") {
            alert("Contact data uploaded successfully.");
          } else {
            alert("Something went wrong, please try again.");
          }
          setspinner(false);
        });
    } else {
      alert("Can not update empty fields.");
    }
  };
  if (validatelogin !== true) {
    return <Navigate to={"/login"} />;
  } else {
    return (
      <div className="pt-5 mh-90 pb-5 bg-white">
        {spinner === true && <Spinner />}
        <div className="container mt-4">
          <h2 className="text-secondary">Update Social Media Links</h2>
          <small>
            Fill up details reuired in form fields and create your own Sellio
            Store where you can display all your business information at your
            customized business page. Click{" "}
            {
              <Link
                to={"/in/" + profiledata.unique_user_name}
                className="border rounded bg-warning text-dark p-1"
              >
                Here
              </Link>
            }{" "}
            to view your page progress.
          </small>
        </div>
        <ProfileCompletionDashboard score={profilescore} />
        <div className="container mt-4">
          <div className="row pb-4 p-0">
            <div className="pt-2 col-md-3"></div>
            <div className="pt-2 col-md-6">
              <div className="row m-0">
                <div className="col-1 p-0">
                  <img
                    src={require("../../assets/img/facebook.webp")}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-11">
                  <label>Facebook Page Link</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={profiledata.facebook}
                    onChange={(e) => setfacebooklink(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="pt-2 col-md-3"></div>
            {/* facebook */}
            <div className="pt-2 col-md-3"></div>
            <div className="pt-2 col-md-6">
              <div className="row m-0">
                <div className="col-1 p-0">
                  <img
                    src={require("../../assets/img/twitter.webp")}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-11">
                  <label>Twitter Page Link</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={profiledata.twitter}
                    onChange={(e) => settwitterlink(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="pt-2 col-md-3"></div>
            {/* twitter */}

            {/* instagram */}
            <div className="pt-2 col-md-3"></div>
            <div className="pt-2 col-md-6">
              <div className="row m-0">
                <div className="col-1 p-0">
                  <img
                    src={require("../../assets/img/instagram.webp")}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-11">
                  <label>Instagram Page Link</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={profiledata.instagram}
                    onChange={(e) => setinstagramlink(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="pt-2 col-md-3"></div>
            {/* instagram */}
            <div className="pt-2 col-md-3"></div>
            <div className="pt-2 col-md-6">
              <div className="row m-0">
                <div className="col-1 p-0">
                  <img
                    src={require("../../assets/img/linkedin.webp")}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-11">
                  <label>Linked IN Link</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={profiledata.google_business}
                    onChange={(e) => setgbusinesslink(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="pt-2 col-md-3"></div>
            {/* instagram */}
          </div>
          <div className="row mt-3">
            <div className="d-none d-lg-block col-lg-3"></div>
            <div className="col-lg-3 text-center p-2">
              <button
                className="btn btn-primary"
                onClick={updateSocialMediaLinks}
              >
                Update Links
              </button>
            </div>
            <div className="col-lg-3 text-center p-2">
              <button
                className="btn btn-primary"
                onClick={() => navigate("/update-payment-link")}
              >
                Go to Payment Links
              </button>
            </div>
            <div className="d-none d-lg-block col-lg-3 p-2"></div>
          </div>
        </div>
      </div>
    );
  }
}
