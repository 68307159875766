import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_PATH, IMAGE_PATH } from "../../Config";
import axios from "axios";
import Spinner from "../../layout/common/Spinner";
import Seo from "../../layout/common/Seo";

const ProfileBanner = () => {
  const [spinner, setSpinner] = useState(false);
  const params = useParams();
  const upath = params.id;
  const [bdata, setBdata] = useState({});
  const [bannerImage, setBannerImage] = useState(
    require("./../../assets/img/my-profile-bg.webp")
  );

  useEffect(() => {
    const fetchStoreFrontData = async () => {
      setSpinner(true);
      try {
        const response = await axios.post(API_PATH + "ManageStoreFront.php", {
          action: "getStoreFrontData",
          upath: upath,
        });

        if (response.data.status === "success") {
          const data = response.data.retval;
          setBdata(data);
          if (data.b_banner_image) {
            setBannerImage(
              `${IMAGE_PATH}/business-profile/${data.b_banner_image}`
            );
          }
        } else {
          alert("Something went wrong. We didn't find the data.");
        }
      } catch (error) {
        console.error("Error fetching store front data", error);
      } finally {
        setSpinner(false);
      }
    };

    fetchStoreFrontData();
  }, [upath]);

  const getSeoProps = () => ({
    title: `Sellio : ${bdata.b_name}`,
    keywords: `
      local search, travel, order food, business, website, mini website, 
      software, management, inventory, sale, pos,
      grocery, medicines, online shopping, book tickets, 
      movies, bus, flights, hotels, events, search plus services, 
      local businesses, online yellow pages, India trade directory, 
      city yellow pages, indian search engine,  customer care, customer support,
      Business management software, Cloud-based management platform,
      SaaS for business management, Enterprise resource planning, 
      Workflow automation, Project management tools,
      Customer relationship management, Sales and marketing automation,
      Financial management software,Inventory management system,
      Human resource management software,Collaboration tools for businesses,
      Productivity tools for businesses, Analytics and reporting software, 
      Business intelligence tools`,
    description: `${bdata.b_name} is doing business online with sellio.in. Is your Business online?`,
    ogurl: window.location.href,
    ogimage: bdata.b_profile_image
      ? `${IMAGE_PATH}/business-profile/${bdata.b_profile_image}`
      : require("./../../assets/img/bzlogo-transparent.webp"),
    ogtype: "website",
  });

  const ProfileImage = () => (
    <div className="p-1 text-center text-md-start ps-md-5">
      <img
        src={
          bdata.b_profile_image
            ? `${IMAGE_PATH}/business-profile/${bdata.b_profile_image}`
            : require("./../../assets/img/bzlogo-transparent.webp")
        }
        alt="img-business-profile-2"
        className="shadow bg-white p-1 rounded"
        style={{
          height: 210,
          width: 210,
          marginTop: -90,
          aspectRatio: "1 / 1",
        }}
      />
    </div>
  );

  const NavLink = ({ to, imgSrc, alt, text }) => (
    <div className="col-3 col-md-2 col-lg-1 p-1 text-center">
      <Link to={to}>
        <img
          src={require(`../../assets/img/${imgSrc}`)}
          alt={alt}
          className="p-2 btn bg-white shadow border"
          style={{
            objectFit: "contain",
            borderRadius: "10px",
            aspectRatio: "1 / 1",
          }}
          width="80%"
        />
        <div className="mt-1">{text}</div>
      </Link>
    </div>
  );

  return (
    <>
      <Seo {...getSeoProps()} />
      {spinner && <Spinner />}
      <div className="container-fluid">
        {/* Business Name */}
        <div
          className="fs-md-5 text-center mt-1 text-bold text-dark mb-1 rounded pt-1 pb-1 bg-white shadow border"
          style={{ textTransform: "uppercase" }}
        >
          <div className="row">
            <div className="d-none d-md-block col-md-2 p-0"></div>
            <div className="col-12 col-md-8 fs-5 p-0">{bdata.b_name}</div>
            <div className="col-12 col-md-2 p-0 pe-md-3 pe-lg-1">
              <Link to={`/reviews/${upath}`}>
                Rating : {bdata.rating}{" "}
                <i className="bi bi-star-fill text-primary"></i>
              </Link>
            </div>
          </div>
        </div>

        {/* Banner Image */}
        <div className="bg-white shadow p-1 rounded border">
          <div style={{ height: 220 }} className="rounded bg-white">
            <img
              src={bannerImage}
              alt="img-business-profile"
              className="rounded"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                zIndex: -22,
              }}
            />
          </div>
        </div>

        {/* Profile Image */}
        <ProfileImage />

        {/* Navigation Links */}
        <div className="container-fluid rounded" style={{ marginTop: -120 }}>
          <div className="row m-0 mt-md-3">
            <div
              className="col-md-5 col-lg-4 col-xl-3 col-12"
              style={{ height: 140 }}
            ></div>
            <div className="col-md-7 col-lg-8 col-xl-9 col-12 p-0 text-center">
              <div className="row m-0 text-12">
                <NavLink
                  to={`/pay/${upath}`}
                  imgSrc="pay.webp"
                  alt="img-business-profile-3"
                  text="Pay Now"
                />
                <NavLink
                  to={`/intro/${upath}`}
                  imgSrc="about.webp"
                  alt="img-bp-4"
                  text="About Us"
                />
                <NavLink
                  to={`/catalogue/${upath}`}
                  imgSrc="store.webp"
                  alt="img-bp-12"
                  text="Store"
                />
                <NavLink
                  to={`/contact/${upath}`}
                  imgSrc="mail.webp"
                  alt="img-bp-5"
                  text="Contact"
                />
                <NavLink
                  to={`/blog/${upath}`}
                  imgSrc="blogging.webp"
                  alt="img-bp-6"
                  text="Blog"
                />
                <NavLink
                  to={`/team/${upath}`}
                  imgSrc="united.webp"
                  alt="img-bp-7"
                  text="Team"
                />
                <NavLink
                  to={`/gallery/${upath}`}
                  imgSrc="gallery.webp"
                  alt="img-bp-8"
                  text="Gallery"
                />
                <div
                  className="col-3 col-md-2 col-lg-1 p-1 text-center"
                  data-bs-toggle="modal"
                  data-bs-target="#modal_social_media_links"
                >
                  <img
                    src={require("../../assets/img/social-media.webp")}
                    alt="img-bp-8"
                    className="p-2 btn bg-white shadow border"
                    width="80%"
                    style={{
                      objectFit: "contain",
                      borderRadius: "10px",
                      aspectRatio: "1 / 1",
                    }}
                  />
                  <div className="mt-1">Social</div>
                </div>
                <div
                  className="col-3 col-md-2 col-lg-1 p-1 text-center"
                  data-bs-toggle="modal"
                  data-bs-target="#modal_share_page"
                >
                  <img
                    src={require("../../assets/img/sharing.webp")}
                    alt="img-bp-10"
                    className="p-2 btn bg-white shadow border"
                    width="80%"
                    style={{
                      objectFit: "contain",
                      borderRadius: "10px",
                      aspectRatio: "1 / 1",
                    }}
                  />
                  <div className="mt-1">Share</div>
                </div>
                <NavLink
                  to={`/qr-code/${upath}`}
                  imgSrc="qr-code.webp"
                  alt="img-bp-11"
                  text="Get Qr"
                />
                <NavLink
                  to={`/reviews/${upath}`}
                  imgSrc="reviews.webp"
                  alt="img-bp-12"
                  text="Reviews"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Share Social Link Modal */}
        <div
          className="modal fade"
          id="modal_social_media_links"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  Share it everywhere
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <a
                  href={`whatsapp://send?text=Visit ${bdata.b_name} at ${window.location.href}`}
                  className="btn btn-success btn-lg btn-block w-100 mt-2"
                >
                  <i className="bi bi-whatsapp me-2"></i> Share on WhatsApp
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
                  className="btn btn-info btn-lg btn-block w-100 mt-2"
                >
                  <i className="bi bi-twitter me-2"></i> Share on Twitter
                </a>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                  className="btn btn-primary btn-lg btn-block w-100 mt-2"
                >
                  <i className="bi bi-facebook me-2"></i> Share on Facebook
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Share Page Modal */}
        <div
          className="modal fade"
          id="modal_share_page"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  Share Business Link
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <label htmlFor="page_link" className="form-label">
                  Business Page Link
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="page_link"
                  style={{ height: 80 }}
                  value={window.location.href}
                  readOnly
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() =>
                    navigator.clipboard.writeText(window.location.href)
                  }
                >
                  <i className="bi bi-clipboard me-2"></i> Copy Link
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileBanner;
