import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_PATH, PROFILE } from "../../Config";
import Spinner from "../../layout/common/Spinner";

export default function DashboardAdmin() {
  const [spinner, setspinner] = useState(false);

  const [totaluser, settotaluser] = useState("");
  const [totalbranch, settotalbranch] = useState("");
  const [totalabm, settotalabm] = useState("");
  const [totalsm, settotalsm] = useState("");
  const [totaladvisor, settotaladvisor] = useState("");
  const [paiduser, setpaiduser] = useState("");
  const [unpaiduser, setunpaiduser] = useState("");
  const [totalrevenue, settotalrevenue] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true);
    axios
      .post(API_PATH + "ManageDashboard.php", {
        userid: localStorage.getItem("ez-userid"),
        userrole: localStorage.getItem("ez-userrole"),
        usertoken: localStorage.getItem("ez-usertoken"),
        action: "getdashboardstats",
      })
      .then((r) => {
        if (r.data.status === "success") {
          settotaluser(r.data.totaluser);
          settotalbranch(r.data.totalbranch);
          settotalabm(r.data.totalabm);
          settotalsm(r.data.totalsm);
          settotaladvisor(r.data.totaladvisor);
          setpaiduser(r.data.paiduser);
          setunpaiduser(r.data.unpaiduser);
          settotalrevenue(r.data.totalrevenue);
        }
        setspinner(false);
      });
  }, []);
  return (
    <div className="mt-5 pt-4 pb-5 mh-90">
      {spinner === true && <Spinner />}
      <div className="mt-2 container-fluid">
        <div className="text-center mt-2 mb-5 ">
          <h4 style={{ textTransform: "capitalize" }}>
            Welcome {PROFILE[localStorage.getItem("ez-userrole")].display}
          </h4>
        </div>
        <div className="row mt-4">
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"/manage-user"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Registered Users
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  {totaluser > 0 ? totaluser : 0}
                </p>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"/manage-user/level1"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Total Branch
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  {totalbranch > 0 ? totalbranch : 0}
                </p>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"/manage-user/level2"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Total ABM
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  {totalabm > 0 ? totalabm : 0}
                </p>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"/manage-user/level3"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">Total SM</p>
                <p className="m-0 text-center text-bold text-secondary">
                  {totalsm > 0 ? totalsm : 0}
                </p>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"/manage-user/level4"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Total Advisor
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  {totaladvisor > 0 ? totaladvisor : 0}
                </p>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"#"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Paid Users
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  {paiduser > 0 ? paiduser : 0}
                </p>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"#"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Un-Paid Users
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  {unpaiduser > 0 ? unpaiduser : 0}
                </p>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"#"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Total Revenue
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  {totalrevenue > 0 ? totalrevenue : 0}
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
