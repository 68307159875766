import React, { useEffect } from "react";
import "../../layout/common/common.css";
import AboutUsIntro from "./sections/AboutUsIntro";

export default function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mh-90 mb-5">
      <AboutUsIntro />
    </div>
  );
}
