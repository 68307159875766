import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_PATH, IMAGE_PATH } from "../../Config";
import axios from "axios";
import GoToHome from "./GoToHome";
import Spinner from "../../layout/common/Spinner";

export default function StoreFrontPayNow() {
  const [spinner, setspinner] = useState(false);

  const params = useParams();
  const upath = params.id;
  const [bdata, setbdata] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true);
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "getPayLinkData",
        upath: upath,
      })
      .then((r) => {
        if (r.data.status === "success") {
          setbdata(r.data.retval);
        } else {
          alert("Something went wrong. We didn't find the data.");
        }
        setspinner(false);
      });
  }, [upath]);

  return (
    <>
      <div className="container mt-5 pt-4 mh-90">
        {spinner === true && <Spinner />}
        <GoToHome link={"/in/" + upath} />

        <div className="row pt-3">
          <div
            className="col-12 text-center mb-2"
            style={{ textTransform: "uppercase" }}
          >
            <h4 className="text-secondary">Pay for {bdata.b_name}</h4>
          </div>
          <div className="col-md-6 pt-3">
            <h3 className="text-center mb-4">Scan and Pay</h3>
            <img
              src={IMAGE_PATH + "qrcodes/" + bdata.b_qr_image}
              alt=""
              style={{ width: "100%", borderRadius: "12px" }}
              className="shadow"
            />
          </div>
          <div className="col-md-6 pt-4">
            <h3 className="text-center mb-4">Account Details</h3>
            <div className="row m-0">
              {bdata.upi_pay_link !== "" && (
                <div className="col p-1">
                  <a
                    href={
                      "upi://pay?pa=" + bdata.upi_pay_link + "&amp;&amp;cu=INR"
                    }
                    className="upi-pay1"
                  >
                    <button className="btn btn-white border shadow form-control">
                      Pay with Upi
                    </button>
                  </a>
                </div>
              )}
              {bdata.gateway_pay_link !== "" && (
                <div className="col p-1">
                  <a
                    href={bdata.gateway_pay_link}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <button className="btn btn-white border shadow form-control">
                      Pay Online
                    </button>
                  </a>
                </div>
              )}
            </div>
            <div className="text-left mt-3 p-1 rounded shadow">
              <table className="table table-striped bg-white  ">
                <tbody className="rounded">
                  <tr>
                    <td>Bank Name</td>
                    <td>{bdata.bank_name}</td>
                  </tr>
                  <tr>
                    <td>Branch Name</td>
                    <td>{bdata.branch_name}</td>
                  </tr>
                  <tr>
                    <td>Account Number</td>
                    <td>{bdata.account_number}</td>
                  </tr>
                  <tr>
                    <td>Ifsc Code</td>
                    <td>{bdata.ifsc_code}</td>
                  </tr>
                  <tr>
                    <td>Upi Address</td>
                    <td>{bdata.upi_pay_link}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
