import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { API_PATH, IMAGE_PATH } from "../../Config";
import useLoginAuth from "../../hooks/useLoginAuth";
import Spinner from "../../layout/common/Spinner";
import "./store.css";
import useValidateRegType from "../../hooks/useValidateRegType";
import PayForExpired from "../../layout/common/PayForExpired";

export default function ManageTeamMembers() {
  const [spinner, setspinner] = useState(false);
  const validatelogin = useLoginAuth();
  const [data, setdata] = useState([]);
  const [counter, setcounter] = useState(0);
  const navigate = useNavigate();
  const validateSubscription = useValidateRegType();
  const upath = localStorage.getItem("ez-upath");

  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true);
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "getTeamMemberList",
        userid: localStorage.getItem("ez-userid"),
        usertoken: localStorage.getItem("ez-usertoken"),
      })
      .then((r) => {
        if (r.data.status === "success") {
          setdata(r.data.retval);
        } else {
          setdata([]);
          alert("Sorry! No data Found.");
        }
        setspinner(false);
      });
  }, [counter]);

  // action after selection change
  const action = (e) => {
    switch (e.target.value) {
      case "view-blog-post":
        navigate("/team/" + upath);
        break;

      case "delete":
        setspinner(true);
        axios
          .post(API_PATH + "ManageStoreFront.php", {
            action: "deleteTeamMemberById",
            userid: localStorage.getItem("ez-userid"),
            usertoken: localStorage.getItem("ez-usertoken"),
            delid: e.target.id,
          })
          .then((r) => {
            if (r.data.status === "success") {
              setcounter(Number(counter) + 1);
              alert("Post deleted successfully!");
            } else {
              alert("Sorry! Something went wrong.");
            }
            setspinner(false);
          });
        break;

      default:
        break;
    }
  };
  if (validatelogin !== true) {
    return <Navigate to={"/login"} />;
  } else {
    if (validateSubscription.status === false) {
      return <PayForExpired />;
    } else {
      return (
        <div className="pt-5 mh-90 pb-5">
          {spinner === true && <Spinner />}
          <div className="container mt-5">
            <h2 className="text-secondary">Manage Team Members</h2>
            <small>
              This page enables you to update existing Team Members to your
              business profile on sellio.in. Creating a Team is very easy. You
              just have to fill up the simple form and submit it.
            </small>
          </div>
          <div className="container mt-4">
            <table
              className="table table-striped bg-white"
              style={{ overflowX: "scroll" }}
            >
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Image</th>
                  <th>Full Name</th>
                  <th>Designation</th>
                  <th>Creation Date</th>
                  <th>Creation Time</th>
                </tr>
              </thead>
              <tbody>
                {data.map((d) => {
                  return (
                    <tr key={d.id}>
                      <td>
                        <select className="rounded" onChange={action} id={d.id}>
                          <option value=""></option>
                          <option value="view-blog-post">View</option>
                          {/* <option value="">Edit</option> */}
                          <option value="delete">Delete</option>
                        </select>
                      </td>
                      <td>
                        <img
                          className="rounded"
                          src={IMAGE_PATH + "business-team/" + d.image_link}
                          alt="img-manage-team-member"
                          style={{
                            width: "100px",
                            objectFit: "contain",
                            aspectRatio: "1/1",
                          }}
                        />
                      </td>
                      <td>{d.full_name}</td>
                      <td>{d.designation}</td>
                      <td>{d.date}</td>
                      <td>{d.time}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
}
