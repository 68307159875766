import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { API_PATH, APP_TOKEN } from "../../Config";
import useLoginAuth from "../../hooks/useLoginAuth";
import Spinner from "../../layout/common/Spinner";

export default function AddUser() {
  const validatelogin = useLoginAuth();
  const [email, setemail] = useState("");
  const [actype, setactype] = useState("");
  const [toggleBusinessUrlInput, setToggleBusinessUrlInput] = useState("hide");
  const [pageurl, setpageurl] = useState("");
  const [contact, setcontact] = useState("");
  const [password, setpassword] = useState("");
  const [verifypassword, setverifypassword] = useState("");
  const [sponsorUser, setSponsorUser] = useState("");
  const [statusClass, setStatusClass] = useState("d-none");
  const [statusMessage, setStatusMessage] = useState("");
  const [urlStatusMsg, setUrlStatusMsg] = useState("");

  const [spinner, setspinner] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // create friendly url business name
  const ToSeoUrl = (e) => {
    // make the url lowercase
    var encodedUrl = e.target.value.toString().toLowerCase();

    // replace & with and
    encodedUrl = encodedUrl.split(/\&+/).join("-and-"); //eslint-disable-line

    // remove invalid characters
    encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");

    // remove duplicates
    encodedUrl = encodedUrl.split(/-+/).join("-");

    // trim leading & trailing characters
    encodedUrl = encodedUrl.trim("-");
    // const checkendchar = encodedUrl.charAt(encodedUrl.length-1);
    // if(checkendchar==='-'){
    //     encodedUrl = encodedUrl.slice(0, -1)
    // }
    setpageurl(encodedUrl);

    if (encodedUrl.toString().length > 0) {
      axios
        .post(API_PATH + "Auth.php", {
          apptoken: APP_TOKEN,
          action: "verifyBusinessUrl",
          url: encodedUrl,
        })
        .then((r) => {
          if (r.data.status === "failed") {
            setUrlStatusMsg(
              "Sorry, This url has been taken by some one. Please try with other."
            );
          } else {
            setUrlStatusMsg("");
          }
        });
    } else {
      setUrlStatusMsg("");
    }

    return;
  };

  //toggle business url input on user type change
  const toggleactype = (e) => {
    setactype(e.target.value);
    if (e.target.value === "business") {
      setToggleBusinessUrlInput("show");
    } else {
      setToggleBusinessUrlInput("hide");
    }
  };

  // register user to database
  const RegisterUser = () => {
    if (password !== verifypassword) {
      setStatusClass("d-block alert-danger");
      setStatusMessage(
        "Enetered password did not match at verification, Please try again."
      );
    } else if (
      email === "" ||
      sponsorUser === "" ||
      contact === "" ||
      password === "" ||
      verifypassword === "" ||
      actype === ""
    ) {
      setStatusClass("d-block alert-danger");
      setStatusMessage("All fields are mandatory.");
    } else {
      setspinner(true);
      axios
        .post(API_PATH + "ManageUser.php", {
          userid: localStorage.getItem("ez-userid"),
          userrole: localStorage.getItem("ez-userrole"),
          usertoken: localStorage.getItem("ez-usertoken"),
          actype: actype,
          pageurl: pageurl,
          email: email,
          contact: contact,
          password: password,
          sponsor: sponsorUser,
          usertype: actype === "business" ? "paid" : "free",
          action: "userRegistration",
        })
        .then(function(r) {
          if (r.data.status === "success") {
            setStatusClass("alert-success");
            setStatusMessage("Registration Successfull.");
            setpageurl("");
            setactype("");
            setemail("");
            setcontact("");
            setpassword("");
            setverifypassword("");
            setSponsorUser("");
          } else {
            switch (r.data.error) {
              case "invalidsponsor":
                setStatusClass("alert-danger");
                setStatusMessage(
                  "No Sponsor detail matched in system. Please try again."
                );
                break;

              case "userexist":
                setStatusClass("alert-danger");
                setStatusMessage(
                  "Contact number or Email id already attached to some existing user."
                );
                break;

              default:
                setStatusClass("alert-danger");
                setStatusMessage("Something went wrong, Please contact Admin");
                break;
            }
          }
          setspinner(false);
        });
    }
    window.scrollTo(0, 0);
  };

  if (validatelogin !== true) {
    return <Navigate to={"/login"} />;
  } else {
    return (
      <div className="container mt-5 pt-5 mb-5 mh-90">
        {spinner === true && <Spinner />}
        <div className="border-bottom p-1">
          <h1>User On-Boarding</h1>
          <small className="pb-1">
            Please fill up the form and submit to register.
          </small>
        </div>
        <div className="row m-0">
          <div className="col-12 mt-1">
            <div className={`alert ${statusClass} `} role="alert">
              {statusMessage}
            </div>
          </div>

          <div className="p-1 col-md-4">
            <label>Account Type</label>
            <select
              className="form-control mt-1"
              onChange={toggleactype}
              defaultValue={""}
            >
              <option value="" key="1">
                Select from List
              </option>
              {localStorage.getItem("ez-userrole") === "admin" && (
                <>
                  <option value="level1" key="branch">
                    Branch
                  </option>
                  <option value="level2" key="abm">
                    Assistant Branch Manager
                  </option>
                  <option value="level3" key="sm">
                    Sales Manager
                  </option>
                  <option value="level4" key="advisor">
                    Advisor
                  </option>
                  <option value="business" key="2">
                    Business Account
                  </option>
                </>
              )}
              {localStorage.getItem("ez-userrole") === "level1" && (
                <>
                  <option value="level2" key="abm">
                    Assistant Branch Manager
                  </option>
                  <option value="level3" key="sm">
                    Sales Manager
                  </option>
                  <option value="level4" key="advisor">
                    Advisor
                  </option>
                  <option value="business" key="2">
                    Business Account
                  </option>
                </>
              )}
              {localStorage.getItem("ez-userrole") === "level2" && (
                <>
                  <option value="level3" key="sm">
                    Sales Manager
                  </option>
                  <option value="level4" key="advisor">
                    Advisor
                  </option>
                  <option value="business" key="2">
                    Business Account
                  </option>
                </>
              )}
              {localStorage.getItem("ez-userrole") === "level3" && (
                <>
                  <option value="level4" key="advisor">
                    Advisor
                  </option>
                  <option value="business" key="2">
                    Business Account
                  </option>
                </>
              )}
              {localStorage.getItem("ez-userrole") === "level4" && (
                <>
                  <option value="business" key="2">
                    Business Account
                  </option>
                </>
              )}
            </select>
          </div>
          {toggleBusinessUrlInput !== "hide" && (
            <div className="p-1  col-md-4">
              <label>Create Business Page Url</label>
              <input
                type="text"
                className="form-control bg-light mt-1"
                value={pageurl}
                onChange={ToSeoUrl}
              />
              {urlStatusMsg !== "" && (
                <small className="bg-danger text-white rounded pe-2 ps-2">
                  {urlStatusMsg}
                </small>
              )}
            </div>
          )}

          <div className="p-1 col-md-4">
            <label>Please enter Email Address</label>
            <input
              type="email"
              className="form-control bg-light mt-1"
              value={email}
              onChange={(e) => {
                setemail(e.target.value);
              }}
            />
          </div>

          <div className="p-1  col-md-4">
            <label>Please enter Contact Number</label>
            <input
              type="number"
              min="0"
              className="form-control bg-light mt-1"
              value={contact}
              onChange={(e) => {
                setcontact(e.target.value);
              }}
            />
          </div>

          <div className="p-1  col-md-4">
            <label>Please enter Password</label>
            <input
              type="password"
              className="form-control bg-light mt-1"
              value={password}
              onChange={(e) => {
                setpassword(e.target.value);
              }}
            />
          </div>

          <div className="p-1  col-md-4">
            <label>Please Verify Password</label>
            <input
              type="password"
              className="form-control bg-light mt-1"
              value={verifypassword}
              onChange={(e) => {
                setverifypassword(e.target.value);
              }}
            />
          </div>
          <div className="p-1  col-md-4">
            <label>Sponsor Mobile Number</label> <br />
            <input
              type="number"
              min="0"
              className="form-control bg-light mt-1"
              value={sponsorUser}
              onChange={(e) => {
                setSponsorUser(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="p-1  col-md-4">
          <button className="btn btn-primary text-dark" onClick={RegisterUser}>
            Add Member
          </button>
        </div>
      </div>
    );
  }
}
