import { GoogleGenerativeAI } from "@google/generative-ai";

export const BASE_URL = window.location.origin;
export const BASE_PATH = "https://sellio.in/";
// export const BASE_PATH =
//   window.location.origin === "http://localhost:3000"
//     ? "http://localhost/sellio/"
//     : "https://sellio.in/";
// "http://192.168.1.8/react.ininess.ininess/";
export const API_PATH = BASE_PATH + "api/";
export const BASE_IMAGE_PATH = API_PATH + "public/img/";
export const IMAGE_PATH = API_PATH + "assets/img/";

export const USER_ID = localStorage.getItem("ez-userid");
export const USER_TOKEN = localStorage.getItem("ez-usertoken");
export const USER_ROLE = localStorage.getItem("ez-userrole");
export const LOGIN_STATUS = localStorage.getItem("ez-isLoggedIn");
export const APP_TOKEN = "74845b02ddab3681eb335981b97a1909";
export const SITE_TITLE = "Sellio";

export const STATE_LIST = [
  "Andaman Nicobar",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra & Nagar Haveli",
  "Daman & Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu & Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Lakshdweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Orissa",
  "Pondicherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Telangana",
  "Tamil Nadu",
  "Tripura",
  "Uttar Pradesh",
  "Uttaranchal",
  "West Bengal",
];
export const SPEAK = (textInput) => {
  var synth = window.speechSynthesis;
  var inputTxt = textInput;
  var voices = [];

  // voice load is async so wait for voice load change and then fire getvoices
  window.speechSynthesis.onvoiceschanged = function () {
    voices = window.speechSynthesis.getVoices();
  };

  function speak() {
    // do something while its speaking
    if (synth.speaking) {
      // console.error('speechSynthesis.speaking');
      return;
    }
    if (inputTxt.value !== "") {
      var utterThis = new SpeechSynthesisUtterance(inputTxt);

      // on process end event can be chaned with other actions
      utterThis.onend = function (event) {
        // console.log('SpeechSynthesisUtterance.onend');
      };
      // utterThis.onerror = function (event) {
      //     console.error('SpeechSynthesisUtterance.onerror');
      // }
      // set id of voice list generated
      utterThis.voice = voices[12];
      // Change pitch value from range 0.5 to 2;
      utterThis.pitch = 1;
      // Change pitch rate from range 0.5 to 2;
      utterThis.rate = 1;
      // call to speak something
      synth.speak(utterThis);
    }
  }

  return speak();
};
export const clearCache = () => {
  if ("caches" in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    window.stop();
    window.location.reload(true);
  }
};
export const gemini = async (prompt) => {
  // Access your API key as an environment variable (see "Set up your API key" above)
  const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GOOGLE_GEMINI_API);

  const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

  const result = await model.generateContent(prompt);
  const response = result.response;
  const text = response.text();
  return text;
};

export const PROFILE = {
  admin: {
    profile: "admin",
    display: "admin",
    amount: "500",
  },
  level1: {
    profile: "branch",
    display: "branch",
    amount: "500",
  },
  level2: {
    profile: "abm",
    display: "assistant branch manager",
    amount: "500",
  },
  level3: {
    profile: "sm",
    display: "sales manager",
    amount: "500",
  },
  level4: {
    profile: "advisor",
    display: "sales advisor",
    amount: "500",
  },
  reseller: {
    profile: "reseller",
    display: "reseller",
    amount: "500",
  },
  business: {
    profile: "business",
    display: "business",
    amount: "0",
  },
};
