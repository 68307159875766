import React from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";

function GoogleMap(props) {
  const getMapOptions = (maps) => {
    return {
      disableDefaultUI: false,
      mapTypeControl: true,
      streetViewControl: true,
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "on" }],
        },
      ],
    };
  };

  const mapstyle = {
    height: props.mapheight,
    width: props.mapwidth,
  };

  return (
    <div style={mapstyle}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API }}
        center={{ lat: props.lat, lng: props.long }}
        zoom={props.zoom}
        options={getMapOptions}
      >
        <Marker
          lat={props.lat}
          lng={props.long}
          name="Sellio Marker"
          color="#ffcc00"
        />
      </GoogleMapReact>
    </div>
  );
}
export default React.memo(GoogleMap);
