import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_PATH, IMAGE_PATH } from "../../Config";
import Spinner from "../../layout/common/Spinner";

export default function Marketplace() {
  const [spinner, setspinner] = useState(false);
  const [searchkey, setsearchkey] = useState("");

  const params = useParams();
  const upath = params.id;
  const [pdata, setpdata] = useState([]);

  useEffect(() => {
    setspinner(true);
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "getStoreFrontProductDatafromallseller",
        searchkey,
      })
      .then((r) => {
        if (r.data.status === "success") {
          setpdata(r.data.retval);
        } else {
          setpdata([]);
        }
        setspinner(false);
      });
  }, [upath, searchkey]);

  const searchProduct = (e) => {
    e.preventDefault();
    setsearchkey(e.target.value);
  };

  return (
    <>
      <div className="container mt-5 mh-90 pb-5">
        <div className="row m-0">
          <div className="col-md-1 col-lg-3"></div>
          <div className="col-md-10 col-lg-6 p-1">
            <input
              type="search"
              placeholder="Search by Category or Product"
              className="form-control border shadow mt-3 mb-2 rounded-pill"
              onChange={searchProduct}
              value={searchkey}
            />
          </div>
          <div className="col-md-1 col-lg-3"></div>
        </div>
        {spinner === false ? (
          <div className="row m-0">
            {pdata.length > 0 ? (
              pdata.map((d) => {
                if (d.prod_image_link !== "") {
                  return (
                    <div key={d.id} className="col-6 col-md-4 col-lg-2 p-1">
                      <div
                        className=" bg-white shadow p-1"
                        style={{
                          border: "1px dashed #ffcc00",
                          borderRadius: "15px",
                        }}
                      >
                        <img
                          src={IMAGE_PATH + "product/" + d.prod_image_link}
                          alt="img-product-list"
                          width={"100%"}
                          style={{
                            borderRadius: "15px",
                            aspectRatio: "1 / 1",
                          }}
                        />
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <small
                            className="p-1"
                            style={{ textTransform: "capitalize" }}
                          >
                            {d.prod_full_name}
                          </small>
                        </div>
                        <div className="row">
                          <div
                            className="col-6 text-center text-danger"
                            style={{ fontSize: 14, fontWeight: "bold" }}
                          >
                            <del>{parseFloat(d.prod_mrp).toFixed(2)}</del>
                          </div>
                          <div
                            className="col-6 text-center text-success"
                            style={{ fontSize: 14, fontWeight: "bold" }}
                          >
                            {"\u20B9" +
                              parseFloat(d.final_sale_price || 0).toFixed(2)}
                          </div>
                        </div>
                        <Link
                          to={
                            "/view-product/" + d.unique_user_name + "/" + d.id
                          }
                        >
                          <button
                            className="btn btn-white border form-control p-1"
                            style={{ borderRadius: "15px" }}
                          >
                            View More
                          </button>
                        </Link>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <>
                <h4 className="text-secondary text-center mt-5">
                  Checking the store, Please be patient !
                </h4>
              </>
            )}
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
}
