import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_PATH } from "../../Config";
import Spinner from "../../layout/common/Spinner";

export default function DashboardReseller() {
  const [spinner, setspinner] = useState(false);

  const [contactreqcount, setcontactreqcount] = useState();
  const [prodcount, setprodcount] = useState();
  const [outofstock, setoutofstock] = useState();
  const [sponsoredusers, setsponsoredusers] = useState();
  const [paidsponsoredusers, setpaidsponsoredusers] = useState();
  const [unpaidsponsoredusers, setunpaidsponsoredusers] = useState();
  const [totalrevenue, settotalrevenue] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true);
    axios
      .post(API_PATH + "ManageDashboard.php", {
        userid: localStorage.getItem("ez-userid"),
        userrole: localStorage.getItem("ez-userrole"),
        usertoken: localStorage.getItem("ez-usertoken"),
        action: "getdashboardstats",
      })
      .then((r) => {
        if (r.data.status === "success") {
          setcontactreqcount(r.data.contactrequestcount);
          setprodcount(r.data.productininventory);
          setoutofstock(r.data.productoutofstock);
          setsponsoredusers(r.data.sponsoredusers);
          setpaidsponsoredusers(r.data.paidsponsoredusers);
          setunpaidsponsoredusers(r.data.unpaidsponsoredusers);
          settotalrevenue(r.data.totalrevenue);
        }
        setspinner(false);
      });
  }, []);
  return (
    <div className="mt-5 pt-4 pb-5 mh-90">
      {spinner === true && <Spinner />}
      <div className="mt-2 container-fluid">
        <div className="text-center">
          <div className="row m-0">
            <div className="col-md-6">
              <input
                className="form-control"
                value={
                  "https://sellio.in/user-registration/" +
                  localStorage.getItem("ez-userid")
                }
                readOnly
              />
            </div>
            <div className="col-md-2">
              <button
                className="btn btn-primary form-control"
                style={{ fontWeight: "bold" }}
                onClick={(e) => {
                  navigator.clipboard.writeText(
                    "https://sellio.in/user-registration/" +
                      localStorage.getItem("ez-userid")
                  );
                  e.target.classList.remove("btn-primary");
                  e.target.classList.add("btn-success");
                  e.target.classList.add("text-white");
                }}
              >
                Copy Invitation Link
              </button>
            </div>
            <div className="col-md-2">
              <Link
                to={"/user-registration/" + localStorage.getItem("ez-userid")}
              >
                <button className="btn btn-primary form-control">
                  <strong>Register New User</strong>
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"#"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Registered Users
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  {sponsoredusers > 0 ? sponsoredusers : 0}
                </p>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"#"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Paid Users
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  {paidsponsoredusers > 0 ? paidsponsoredusers : 0}
                </p>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"#"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Un-Paid Users
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  {unpaidsponsoredusers > 0 ? unpaidsponsoredusers : 0}
                </p>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"#"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Total Revenue
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  {totalrevenue > 0 ? totalrevenue : 0}
                </p>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"#"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Total Earning
                </p>
                <p className="m-0 text-center text-bold text-secondary">0</p>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"#"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Total Withdrawal
                </p>
                <p className="m-0 text-center text-bold text-secondary">0</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"/view-product-listing"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Products Listed
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  {prodcount > 0 ? prodcount : 0}
                </p>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <div className="p-2 border shadow rounded">
              <p className="text-center text-bold text-secondary">
                Out of Stock
              </p>
              <p className="m-0 text-center text-bold text-secondary">
                {outofstock > 0 ? outofstock : 0}
              </p>
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <div className="p-2 border shadow rounded">
              <p className="text-center text-bold text-secondary">New Orders</p>
              <p className="m-0 text-center text-bold text-secondary">0</p>
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <div className="p-2 border shadow rounded">
              <p className="text-center text-bold text-secondary">Total Sale</p>
              <p className="m-0 text-center text-bold text-secondary">0</p>
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <div className="p-2 border shadow rounded">
              <p className="text-center text-bold text-secondary">
                Pending Invoices
              </p>
              <p className="m-0 text-center text-bold text-secondary">0</p>
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"/view-contact-request"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Contact Requests
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  {contactreqcount > 0 ? contactreqcount : 0}
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
