import React, { useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import useLoginAuth from "../../hooks/useLoginAuth";
import { USER_ROLE } from "../../Config";

const CustomerPageContainer = (props) => {
  const validatelogin = useLoginAuth();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  if (validatelogin !== true) {
    return <Navigate to={"/logout"} />;
  } else {
    return (
      <div className="mh-90 mt-5 pt-md-4">
        <div className="container">
          <div className="row m-0">
            <div className="d-none d-md-block col-md-3 p-0">
              <div className="">
                <Link to={"/cart"}>
                  <button
                    type=""
                    className="w-100 btn btn-white shadow rounded-pill"
                  >
                    Cart Items
                  </button>
                </Link>
              </div>
              <div className="mt-2">
                <Link to={"/order-history"}>
                  <button
                    type=""
                    className="w-100 btn btn-white shadow rounded-pill"
                  >
                    Order History
                  </button>
                </Link>
              </div>
              <div className="mt-2">
                <Link to={"/wishlist"}>
                  <button
                    type=""
                    className="w-100 btn btn-white shadow rounded-pill"
                  >
                    Wishlist
                  </button>
                </Link>
              </div>
              {/* <div className="mt-2">
                <Link to={"/user-profile"}>
                  <button
                    type=""
                    className="w-100 btn btn-white shadow rounded-pill"
                  >
                    Profile
                  </button>
                </Link>
              </div> */}
              <div className="mt-2">
                <Link to={"/delivery-address"}>
                  <button
                    type=""
                    className="w-100 btn btn-white shadow rounded-pill"
                  >
                    Addresses
                  </button>
                </Link>
              </div>
              {/* <div className="mt-2">
                <Link to={"/wallet"}>
                  <button
                    type=""
                    className="w-100 btn btn-white shadow rounded-pill"
                  >
                    Wallet
                  </button>
                </Link>
              </div> */}
              {/* <div className="mt-2">
                <Link to={"/product-recommendations"}>
                  <button
                    type=""
                    className="w-100 btn btn-white shadow rounded-pill"
                  >
                    Recommendations
                  </button>
                </Link>
              </div> */}
            </div>
            <div className="col-md-9 p-0">
              <div>{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CustomerPageContainer;
