import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_PATH, USER_ID, USER_TOKEN } from "../../Config";
import useLoginAuth from "../../hooks/useLoginAuth";
import Spinner from "../../layout/common/Spinner";

export default function PasswordReset() {
  const [spinner, setspinner] = useState(false);
  const validatelogin = useLoginAuth();
  const [newpass, setnewpass] = useState("");
  const [verifypass, setverifypass] = useState("");
  const [retstatus, setretstatus] = useState("");

  // to process email reset link
  const [useremail, setuseremail] = useState("");
  const [mailstatus, setmailstatus] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const resetpassword = (e) => {
    e.preventDefault();
    if (validatelogin !== true) {
      if (useremail.length > 6) {
        setmailstatus("processing");
        setspinner(true);
        axios
          .post(API_PATH + "EmailSender.php", {
            action: "resetpassforloggedin",
            email: useremail,
          })
          .then((r) => {
            if (r.data.status === "success") {
              setmailstatus("success");
              setuseremail("");
            } else {
              setmailstatus("failed");
            }
            setTimeout(() => {
              setmailstatus("");
            }, 4000);
            setspinner(false);
          });
      } else {
        alert("Email address is not valid");
      }
    } else {
      if (newpass === verifypass && newpass.length > 2) {
        setspinner(true);
        axios
          .post(API_PATH + "Auth.php", {
            userid: USER_ID,
            usertoken: USER_TOKEN,
            action: "resetpassforloggedin",
            pass: newpass,
          })
          .then((r) => {
            if (r.data.status === "success") {
              setnewpass("");
              setverifypass("");
              setretstatus("success");
            } else {
              setretstatus("failed");
            }
            setspinner(false);
          });
      } else {
        setnewpass("");
        setverifypass("");
        setretstatus("mismatch");
      }
    }

    setTimeout(() => {
      setretstatus("");
    }, 4000);
  };

  if (validatelogin !== true) {
    return (
      <div className="bg-white">
        <div className="container mt-5 pt-2">
          <div className="pt-1 mh-90  pb-5">
            {spinner === true && <Spinner />}

            <div className="row m-0 mt-2">
              <div className="col-md-6 border-end">
                {mailstatus === "processing" && (
                  <div className="alert alert-warning mt-2" role="alert">
                    Sending password link to mail is in process... Please wait.
                  </div>
                )}
                {mailstatus === "success" && (
                  <div className="alert alert-success mt-2" role="alert">
                    Password sent to email address. Please check your email and
                    spam.
                  </div>
                )}
                {mailstatus === "failed" && (
                  <div className="alert alert-danger mt-2" role="alert">
                    Unable to get data for this email address, Please try again.
                  </div>
                )}
                <div className="border-bottom p-1">
                  <h1>Reset User Password</h1>
                  <small className="pb-1">
                    Please provide details to reset password
                  </small>
                </div>
                <form onSubmit={resetpassword}>
                  <div className="p-1">
                    <label>Please enter Email Address</label>
                    <input
                      type="email"
                      className="form-control bg-light mt-1 rounded-pill shadow"
                      value={useremail}
                      onChange={(e) => setuseremail(e.target.value)}
                    />
                  </div>
                  <div className="p-1">
                    <button
                      className="btn btn-primary  rounded-pill"
                      type="submit"
                    >
                      Send Password
                    </button>
                  </div>
                </form>
                <div className="container mt-4" style={{ fontSize: "14px" }}>
                  <p className="text-justify">
                    Welcome to our password reset page. If you've forgotten your
                    password, don't worry, we'll help you reset it. To reset
                    your password, please follow the instructions below:
                  </p>
                  <p className="text-justify">
                    <span className="text-secondary text-bold">Step 1: </span>
                    Enter your email address Please enter the email address
                    associated with your account in the box provided. We will
                    send you an email with instructions on how to reset your
                    password.
                  </p>
                  <p className="text-justify">
                    <span className="text-secondary text-bold">Step 2: </span>{" "}
                    Check your email Once you have submitted your email address,
                    please check your inbox for an email from us. The email will
                    contain a link that you need to click on to proceed with the
                    password reset process.
                  </p>
                  <p className="text-justify">
                    <span className="text-secondary text-bold">Step 3: </span>{" "}
                    Create a new password After clicking on the link, you will
                    be redirected to a page where you can create a new password.
                    Please choose a strong password that is easy for you to
                    remember but difficult for others to guess. Make sure to use
                    a combination of upper and lower case letters, numbers, and
                    special characters.
                  </p>
                  <p className="text-justify">
                    <span className="text-secondary text-bold">Step 4: </span>{" "}
                    Confirm your new password Once you have created your new
                    password, please re-enter it to confirm it. This ensures
                    that you have typed in the password correctly and will be
                    able to log in to your account without any issues.
                  </p>
                  <p className="text-justify">
                    <span className="text-secondary text-bold">Step 5: </span>{" "}
                    Log in to your account After successfully resetting your
                    password, you can log in to your account using your new
                    password. Please make sure to keep your new password safe
                    and secure. If you have any issues resetting your password
                    or if you do not receive the password reset email, please
                    contact our customer support team for assistance. Thank you
                    for choosing our platform. We hope that you enjoy using our
                    services.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-center">
                  <img
                    alt="PricingImage"
                    width={"90%"}
                    height={"90%"}
                    src={require("./../../assets/img/registration.png")}
                    className=""
                  />
                </div>
                <div className="text-center d-block">
                  <img
                    width={"80%"}
                    height={"80%"}
                    src={require("./../../assets/img/sellio_logo.png")}
                    alt="about"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="bg-white mh-90">
        <div className="container mt-5 pt-5 ">
          <div className="border-bottom p-1">
            <h1>Reset User Password</h1>
            <small className="pb-1">
              Please provide details to reset password
            </small>
          </div>
          <div className="row m-0 mt-4 bg-white rounded pt-5 pb-5 shadow">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              {retstatus === "success" && (
                <div className="alert alert-success mt-2" role="alert">
                  Password has successfully been reset.
                </div>
              )}
              {retstatus === "mismatch" && (
                <div className="alert alert-danger mt-2" role="alert">
                  New password and verification password do not match, please
                  try again.
                </div>
              )}
              {retstatus === "failed" && (
                <div className="alert alert-danger mt-2" role="alert">
                  Something went wrong, please try again.
                </div>
              )}
              <form onSubmit={resetpassword}>
                <div className="form-group">
                  <div className="">
                    <label>Enter new Password</label>
                    <input
                      type="password"
                      className="form-control"
                      value={newpass}
                      onChange={(e) => setnewpass(e.target.value)}
                    />
                  </div>
                  <div className="mt-2">
                    <label>Verify new Password</label>
                    <input
                      type="password"
                      className="form-control"
                      value={verifypass}
                      onChange={(e) => setverifypass(e.target.value)}
                    />
                  </div>
                  <div className="mt-2">
                    <button
                      type="submit"
                      className="btn btn-primary  rounded-pill"
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
    );
  }
}
