import React from "react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createRoot } from "react-dom/client";
// importing the provider
import { HelmetProvider } from "react-helmet-async";
import Home from "./Home";
import Navbar from "./layout/common/Navbar";
import Footer from "./layout/common/Footer";
import Login from "./modules/auth/Login";
import Register from "./modules/auth/Register";
import PasswordReset from "./modules/auth/PasswordReset";
import Logout from "./modules/auth/Logout";
import Store from "./modules/store/Store";
import AboutUs from "./modules/web-front/AboutUs";
import Pricing from "./modules/web-front/Pricing";
import Faq from "./modules/web-front/Faq";
import PartnerWithUs from "./modules/web-front/PartnerWithUs";
import SearchBusiness from "./modules/store/SearchBusiness";
import JobBoard from "./modules/career/JobBoard";
import DashboardSwitch from "./modules/dashboard/DashboardSwitch";
import CreateStoreFront from "./modules/store/CreateStoreFront";
import UpdateAboutUs from "./modules/store/UpdateAboutUs";
import UpdateBusinessContacts from "./modules/store/UpdateBusinessContacts";
import UpdateSocialMediaLinks from "./modules/store/UpdateSocialMediaLinks";
import UpdatePaymentLinks from "./modules/store/UpdatePaymentLinks";
import StoreFrontPayNow from "./modules/store/StoreFrontPayNow";
import StoreFrontAboutUs from "./modules/store/StoreFrontAboutUs";
import CreateBlogPost from "./modules/store/CreateBlogPost";
import StoreFrontBlog from "./modules/store/StoreFrontBlog";
import StoreFrontContact from "./modules/store/StoreFrontContact";
import ManageBlogPost from "./modules/store/ManageBlogPost";
import AddNewProduct from "./modules/inventory/AddNewProduct";
import ViewProductInventory from "./modules/inventory/ViewProductInventory";
import ManageOrders from "./modules/inventory/ManageOrders";
import ReadyToShip from "./modules/inventory/ReadyToShip";
import InTransit from "./modules/inventory/InTransit";
import OrderDelivered from "./modules/inventory/OrderDelivered";
import PointOfSale from "./modules/inventory/PointOfSale";
import PageNotFount from "./modules/web-front/PageNotFount";
import ViewContactRequest from "./modules/dashboard/ViewContactRequest";
import TermsAndCondition from "./layout/common/navswitch/policies/TermsAndCondition";
import PrivacyPolicy from "./layout/common/navswitch/policies/PrivacyPolicy";
import RefundAndShipping from "./layout/common/navswitch/policies/RefundAndShipping";
import StoreFrontTeam from "./modules/store/StoreFrontTeam";
import StoreFrontCareer from "./modules/store/StoreFrontCareer";
import StoreFrontProdSingleView from "./modules/store/StoreFrontProdSingleView";
import CreateTeam from "./modules/store/CreateTeam";
import ManageTeamMembers from "./modules/store/ManageTeamMembers";
import ViewStoreFrontGalleryListing from "./modules/store/ViewStoreFrontGalleryListing";
import CreateGalleryItem from "./modules/store/CreateGalleryItem";
import StoreFrontGallery from "./modules/store/StoreFrontGallery";
import StoreFrontQr from "./modules/store/StoreFrontQr";
import PasswordResetMailAuth from "./modules/auth/PasswordResetMailAuth";
import PosCheckout from "./modules/inventory/PosCheckout";
import ViewUserDataRegByReseller from "./modules/manage-user/ViewUserDataRegByReseller";
import AddUser from "./modules/manage-user/AddUser";
import AdminManageUser from "./modules/manage-user/AdminManageUser";
import ViewCustomerBaseData from "./modules/inventory/ViewCustomerBaseData";
import OrderItemizedView from "./modules/inventory/OrderItemizedView";
import SalesVisitTracker from "./modules/team/SalesVisitTracker";
import ViewVisitRecords from "./modules/team/ViewVisitRecords";
import StoreFrontProductCatalogue from "./modules/store/StoreFrontProductCatalogue";
import StoreFrontReviews from "./modules/store/StoreFrontReviews";
import CartItems from "./modules/ecom-user-end/CartItems";
import OrderHistory from "./modules/ecom-user-end/OrderHistory";
import Wishlist from "./modules/ecom-user-end/Wishlist";
import CustomerProfile from "./modules/ecom-user-end/CustomerProfile";
import CustomerAddress from "./modules/ecom-user-end/CustomerAddress";
import CustomerWallet from "./modules/ecom-user-end/CustomerWallet";
import ProductRecommendations from "./modules/ecom-user-end/ProductRecommendations";
import Marketplace from "./modules/store/Marketplace";
import Checkout from "./modules/ecom-user-end/Checkout";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Router basename="/">
        <Navbar />
        <Routes>
          {/* Public Pages */}
          <Route exact path="/" element={<Home />} />
          <Route exact path="/in" element={<Store pagetype="store" />}>
            <Route exact path="/in/:id" element={<Store pagetype="store" />} />
          </Route>
          <Route exact path="/pay" element={<StoreFrontPayNow />}>
            <Route exact path="/pay/:id" element={<StoreFrontPayNow />} />
          </Route>
          <Route exact path="/intro" element={<StoreFrontAboutUs />}>
            <Route exact path="/intro/:id" element={<StoreFrontAboutUs />} />
          </Route>
          <Route exact path="/team" element={<StoreFrontTeam />}>
            <Route exact path="/team/:id" element={<StoreFrontTeam />} />
          </Route>
          <Route exact path="/reviews" element={<StoreFrontReviews />}>
            <Route exact path="/reviews/:id" element={<StoreFrontReviews />} />
          </Route>
          <Route
            exact
            path="/catalogue"
            element={<StoreFrontProductCatalogue />}
          >
            <Route
              exact
              path="/catalogue/:id"
              element={<StoreFrontProductCatalogue />}
            />
          </Route>
          <Route exact path="/career" element={<StoreFrontCareer />}>
            <Route exact path="/career/:id" element={<StoreFrontCareer />} />
          </Route>
          <Route exact path="/gallery/:id" element={<StoreFrontGallery />} />
          <Route exact path="/qr-code/:id" element={<StoreFrontQr />} />
          <Route
            exact
            path="/view-product"
            element={<StoreFrontProdSingleView />}
          >
            <Route
              exact
              path="/view-product/:upath/:id"
              element={<StoreFrontProdSingleView />}
            />
          </Route>
          <Route exact path="/blog" element={<StoreFrontBlog />}>
            <Route exact path="/blog/:from/" element={<StoreFrontBlog />} />
            <Route exact path="/blog/:from/:id/" element={<StoreFrontBlog />} />
          </Route>
          <Route exact path="/contact" element={<StoreFrontContact />}>
            <Route exact path="/contact/:id/" element={<StoreFrontContact />} />
          </Route>
          <Route exact path="/job-board" element={<JobBoard />} />
          <Route exact path="/search-business" element={<SearchBusiness />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/career" element={<AboutUs />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/partner-with-us" element={<PartnerWithUs />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/user-registration" element={<Register />} />
          <Route path="/user-registration/:id" element={<Register />} />
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-and-shipping" element={<RefundAndShipping />} />
          <Route
            path="/reset-link/:id/:hash"
            element={<PasswordResetMailAuth />}
          />
          <Route path="*" element={<PageNotFount />} />
          {/* End Of public pages */}
          {/* start Of private pages */}
          <Route path="/dashboard" element={<DashboardSwitch />} />
          <Route path="/create-store-front" element={<CreateStoreFront />} />
          <Route
            path="/update-business-contacts"
            element={<UpdateBusinessContacts />}
          />
          <Route
            path="/update-social-media-links"
            element={<UpdateSocialMediaLinks />}
          />
          <Route path="/update-payment-link" element={<UpdatePaymentLinks />} />
          <Route path="/update-about-us" element={<UpdateAboutUs />} />
          <Route path="/create-blog-post" element={<CreateBlogPost />} />
          <Route path="/add-business-team-member" element={<CreateTeam />} />
          <Route path="/manage-blogs" element={<ManageBlogPost />} />
          <Route path="/manage-team" element={<ManageTeamMembers />} />
          {/* inventory module */}
          <Route path="/add-product" element={<AddNewProduct />} />
          <Route
            path="/view-product-listing"
            element={<ViewProductInventory />}
          />
          <Route path="/manage-orders" element={<ManageOrders />} />
          <Route path="/ready-to-ship" element={<ReadyToShip />} />
          <Route path="/in-transit" element={<InTransit />} />
          <Route path="/order-delivered" element={<OrderDelivered />} />
          <Route
            path="/order-itemized/:orderid"
            element={<OrderItemizedView />}
          />
          <Route path="/point-of-sale" element={<PointOfSale />} />
          <Route
            path="/view-customer-base"
            element={<ViewCustomerBaseData />}
          />
          <Route path="/point-of-sale-checkout" element={<PosCheckout />} />
          <Route
            path="/view-contact-request"
            element={<ViewContactRequest />}
          />
          <Route
            path="/view-gallery-items"
            element={<ViewStoreFrontGalleryListing />}
          />
          <Route path="/create-gallery-item" element={<CreateGalleryItem />} />
          {/* inventory module */}

          {/* reseller */}
          <Route
            path="/view-user-data-reg-by-reseller"
            element={<ViewUserDataRegByReseller />}
          />
          {/* reseller */}
          {/* admin */}
          <Route path="/add-user" element={<AddUser />} />
          <Route path="/manage-user" element={<AdminManageUser />} />
          <Route path="/manage-user/:profile" element={<AdminManageUser />} />

          {/* admin */}
          {/* team */}
          <Route path="/sales-visit-tracker" element={<SalesVisitTracker />} />
          <Route path="/view-visit-records" element={<ViewVisitRecords />} />
          {/* team */}
          {/* customers */}

          <Route path="/cart" element={<CartItems />} />
          <Route path="/order-history" element={<OrderHistory />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/user-profile" element={<CustomerProfile />} />
          <Route path="/delivery-address" element={<CustomerAddress />} />
          <Route path="/wallet" element={<CustomerWallet />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route
            path="/product-recommendations"
            element={<ProductRecommendations />}
          />
          <Route path="/store" element={<Marketplace />} />
          {/* customers */}
          {/* End Of private pages */}
        </Routes>
        <Footer />
      </Router>
    </HelmetProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
