import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "./store.css";
import { API_PATH } from "../../Config";
import { useParams } from "react-router-dom";
import GoToHome from "./GoToHome";

const StoreFrontReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [page, setPage] = useState(1);
  const [counter, setcounter] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [alertmsg, setalertmsg] = useState("");

  // for rating ------
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [btntoggleaddreview, setbtntoggleaddreview] = useState(false);
  const [rname, setrname] = useState("");
  const [remail, setremail] = useState("");
  const [rcontact, setrcontact] = useState("");
  const [rreview, setrreview] = useState("");
  // for rating ------

  const params = useParams();
  const upath = params.id;

  const fetchReviews = async (page) => {
    setLoading(true);
    try {
      const response = await axios.post(API_PATH + "ManageStoreFront.php", {
        page: page,
        limit: 30,
        action: "fetchBusinessReview",
        upath: upath,
      });
      const newReviews = response.data.retval;

      if (newReviews.length > 0) {
        setReviews((prevReviews) => {
          // Only append new reviews if they don't already exist in the list
          return [
            ...prevReviews,
            ...newReviews.filter(
              (review) => !prevReviews.some((prev) => prev.id === review.id)
            ),
          ];
        });
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReviews(page);
  }, [page, counter]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      loading ||
      !hasMore
    )
      return;
    setPage((prevPage) => prevPage + 1);
  }, [loading, hasMore]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  // ------------------------------------------------->for rating script

  const handleMouseEnter = (index) => {
    setHoverRating(index);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  const handleClick = (index) => {
    setRating(index);
  };

  const togglereviewform = (e) => {
    e.preventDefault();
    if (btntoggleaddreview === true) {
      setbtntoggleaddreview(false);
    } else {
      setbtntoggleaddreview(true);
    }
  };

  const submitreview = async (e) => {
    e.preventDefault();
    if (rname && remail && rcontact && rating > 0 && rreview) {
      try {
        const response = await axios.post(API_PATH + "ManageStoreFront.php", {
          action: "submitbusinessreview",
          rname,
          rcontact,
          remail,
          rreview,
          upath,
          rating,
        });
        const r = response.data;

        if (r.status === "success") {
          setrname("");
          setremail("");
          setrcontact("");
          setRating("");
          setrreview("");
          setalertmsg("success");
          setbtntoggleaddreview(false);
          setcounter(Number(counter) + 1);
        } else {
          r.err === "multi" &&
            setalertmsg("User has already submitted review for this business");
        }
        setTimeout(() => {
          setalertmsg("");
        }, 5000);
      } catch (error) {}
    } else {
      setalertmsg(
        "All fields are mandatory to submit the business review form."
      );
      setTimeout(() => {
        setalertmsg("");
      }, 5000);
    }
  };
  // ------------------------------------------------->for rating script

  return (
    <div className="container mh-90 mt-5 pt-1 pb-5">
      <div className="d-flex justify-content-between">
        <div>
          <GoToHome link={"/in/" + upath} />
        </div>
        <div>
          {btntoggleaddreview === false ? (
            <button
              type="submit"
              className="btn border bg-white text-dark rounded-pill shadow"
              onClick={togglereviewform}
            >
              Add Review
            </button>
          ) : (
            <button
              type="submit"
              className="btn bg-danger text-white shadow rounded-pill "
              onClick={togglereviewform}
            >
              X
            </button>
          )}
        </div>
      </div>
      {alertmsg === "success" ? (
        <div className="alert alert-success mt-2">
          Remark submitted for this business.
        </div>
      ) : (
        alertmsg !== "" && (
          <div className="alert alert-danger mt-2">{alertmsg}</div>
        )
      )}
      {btntoggleaddreview === true && (
        <div className="container bg-white shadow rounded mt-2 pt-3 pb-3">
          <div className="row">
            <div className="col-md-3">
              <label for="username">Your Name</label>
              <input
                type="text"
                id="username"
                value={rname}
                onChange={(e) => setrname(e.target.value)}
                placeholder="Enter your Name"
                className="form-control rounded-pill bg-light"
              />
            </div>
            <div className="col-md-3">
              <label for="username">Email Address</label>
              <input
                type="email"
                id="useremail"
                value={remail}
                onChange={(e) => setremail(e.target.value)}
                placeholder="Enter your Email Address"
                className="form-control rounded-pill bg-light"
              />
            </div>
            <div className="col-md-3">
              <label for="usercontact">Contact Number</label>
              <input
                type="number"
                id="usercontact"
                value={rcontact}
                min={1000000000}
                onChange={(e) => setrcontact(e.target.value)}
                placeholder="Enter your Contact Number"
                className="form-control rounded-pill bg-light"
              />
            </div>
            <div className="col-md-3">
              <label for="usercontact">Select Rating ({rating})</label>
              <div className=" fs-4">
                {Array.from({ length: 5 }, (_, i) => (
                  <i
                    key={i}
                    className={`bi bi-star-fill ${
                      (hoverRating || rating) > i
                        ? "text-warning"
                        : "text-muted"
                    }`}
                    onMouseEnter={() => handleMouseEnter(i + 1)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleClick(i + 1)}
                    style={{ cursor: "pointer" }}
                  ></i>
                ))}
              </div>
            </div>
            <div className="col-12 mt-2">
              <label for="textarea">Write a Review</label>{" "}
              <textarea
                rows="8"
                id="textarea"
                value={rreview}
                onChange={(e) => setrreview(e.target.value)}
                className="form-control"
              ></textarea>
            </div>
            <div className="col mt-2">
              <div>
                <button
                  type="submit"
                  className="btn btn-primary rounded-pill"
                  onClick={submitreview}
                >
                  Click to submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        {reviews.length < 1 && (
          <div>
            <div className="container mt-5 p-3 pt-5 pb-5 bg-white shadow rounded">
              <div className="text-center">
                <h5>No review found</h5>
              </div>
              <div className="text-center">
                Please support this business by providing your valueable review.
                Click this {"   "}
                <button
                  type="submit"
                  className="btn border bg-white text-dark rounded-pill shadow"
                  onClick={togglereviewform}
                >
                  Add Review
                </button>
                {"   "}
                button to submit your remark.
              </div>
            </div>
          </div>
        )}
        {reviews.map((d, index) => (
          <div key={index} className="bg-white rounded p-2 mt-2 shadow">
            <div className="review-header">
              <h6 style={{ textTransform: "capitalize" }}>{d.user_name}</h6>
              <div className="stars">
                {Array.from({ length: d.rating }, (_, i) => (
                  <i key={i} className="bi bi-star-fill text-warning"></i>
                ))}
              </div>
            </div>
            <p>{d.remark}</p>
          </div>
        ))}
        {loading && <p>Loading more reviews...</p>}
      </div>
    </div>
  );
};

export default StoreFrontReviews;
