import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_PATH, IMAGE_PATH } from "../../Config";
import Spinner from "../../layout/common/Spinner";
import GoToHome from "./GoToHome";
import ProductList from "./ProductList";

export default function StoreFrontProductCatalogue() {
  const [spinner, setspinner] = useState(false);

  const params = useParams();
  const upath = params.id;

  const [status, setstatus] = useState("");
  const [pdata, setpdata] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (upath.length > 0) {
      // setspinner(true);
      axios
        .post(API_PATH + "ManageStoreFront.php", {
          action: "getStoreFrontProductData",
          upath: upath,
          limit: 150,
        })
        .then((r) => {
          if (r.data.status === "success") {
            setstatus("success");
            setpdata(r.data.retval);
          } else {
            setstatus("failed");
          }
          // setspinner(false);
        });
    }
  }, [upath]);
  return (
    <div className="mt-5 pt-4 mh-90">
      {spinner === true && <Spinner />}

      <div className="container">
        <GoToHome link={"/in/" + upath} />
        {status === "failed" ? (
          <>
            <h4 className="text-center mt-3">
              Sorry, No Product or Service is displayed for this business yet.
            </h4>
            <p className="text-center">
              Some times businesses are too busy serving things to you and dont
              get time to update infromations here. If you are looking for some
              specific information. You can contact business by given button
              link.
            </p>
            <div className="text-center">
              <Link to={"/contact/" + upath}>
                <button className="btn btn-primary">Contact Business</button>
              </Link>
            </div>
          </>
        ) : (
          <>
            <ProductList />
          </>
        )}
      </div>
    </div>
  );
}
