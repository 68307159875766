import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_PATH, IMAGE_PATH } from "../../Config";
import axios from "axios";
import parse from "html-react-parser";
import GoToHome from "./GoToHome";
import Spinner from "../../layout/common/Spinner";

export default function StoreFrontAboutUs() {
  const [spinner, setspinner] = useState(false);
  const params = useParams();
  const upath = params.id;
  const [bdata, setbdata] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true);
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "getBusinessIntroData",
        upath: upath,
      })
      .then((r) => {
        if (r.data.status === "success") {
          setbdata(r.data.retval);
        } else {
          // alert("Something went wrong. We didn't find the data.");
        }
        setspinner(false);
      });
  }, [upath]);

  return (
    <>
      <div className="container mt-0 pt-1 mh-90 mb-5">
        {spinner === true && <Spinner />}

        <div className="row  m-0 pt-2 ">
          <GoToHome link={"/in/" + upath} />
          <div
            className="col-12 text-center my-2 bg-white rounded shadow"
            style={{ textTransform: "uppercase" }}
          >
            <h4 className="text-dark p-lg-2 m-0">About {bdata.b_name}</h4>
          </div>
          <div
            className="col-12 text-left mb-2 bg-white p-lg-4 rounded rounded shadow"
            style={{ textTransform: "uppercase" }}
          >
            <h6 className="text-secondary">
              <span className="text-white bg-primary rounded ps-1 pe-1">
                Title
              </span>{" "}
              : {bdata.title}
            </h6>
            <h6 className="text-secondary mt-3">
              <span className="text-white bg-primary rounded  ps-1 pe-1">
                Tagline
              </span>{" "}
              : {bdata.subject}
            </h6>
          </div>
          <div className="col-md-4 p-0 pe-lg-2">
            <div className="bg-white rounded shadow p-1">
              <img
                className="bg-white rounded shadow"
                src={IMAGE_PATH + "business-intro/" + bdata.image}
                alt=""
                style={{ width: "100%", borderRadius: "12px" }}
              />
            </div>
          </div>
          <div className="col-md-8 bg-white rounded shadow p-lg-4">
            {bdata.content && parse(bdata.content)}
          </div>
        </div>
      </div>
    </>
  );
}
