import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { API_PATH } from "../../Config";
import axios from "axios";
import parse from "html-react-parser";
import "./store.css";

export default function StoreFrontIntro() {
  const params = useParams();
  const upath = params.id;
  const [bdata, setbdata] = useState([]);
  const [introdisplay, setintrodisplay] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "getBusinessIntroData",
        upath: upath,
      })
      .then((r) => {
        if (r.data.status === "success") {
          setbdata(r.data.retval);
        } else {
          // alert("Something went wrong. We didn't find the data.");
        }
      });
  }, [upath]);

  const toggelintro = useCallback(() => {
    setintrodisplay((prevIntrodisplay) => !prevIntrodisplay);
  }, []);

  const parsedContent = useMemo(() => {
    return bdata.content && parse(bdata.content);
  }, [bdata.content]);

  return (
    <>
      {bdata.content && bdata.content !== "" && (
        <div className="container mt-0 p-3">
          <div className="shadow bg-white rounded p-2 border">
            <div
              className="p-md-4 text-justify business-intro-styling-on-store-front"
              style={
                introdisplay === false
                  ? { height: "100%" }
                  : { height: "200px", overflow: "hidden" }
              }
            >
              {parsedContent}
            </div>
            <div className="text-center mt-4">
              <button
                className="btn btn-white shadow rounded-pill border"
                onClick={toggelintro}
              >
                {introdisplay === false ? "Hide" : "Show More"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
