import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_PATH } from "../../Config";

export default function PayForExpired() {
  const [orderid, setorderid] = useState("");
  const [receipt, setreceipt] = useState("");

  const [businessdata, setbusinessdata] = useState([]);
  const [payamount, setpayamount] = useState("0");
  const [plan, setplan] = useState("basic");

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .post(API_PATH + "Auth.php", {
        action: "businessdataforpayment",
        userid: localStorage.getItem("ez-userid"),
        usertoken: localStorage.getItem("ez-usertoken"),
      })
      .then((r) => {
        if (r.data.status === "success") {
          setbusinessdata(r.data.retval);
        }
      });
  }, []);

  const selectplan = (e, value, plan) => {
    e.preventDefault();
    setpayamount(value);
    setplan(plan);
    e.currentTarget.classList.remove("btn-primary");
    e.currentTarget.classList.add("btn-danger");
  };

  const createOrderInBackEnd = () => {
    axios
      .post(API_PATH + "ManagePayments.php", {
        action: "createOrder",
        amount: payamount,
      })
      .then((r) => {
        if (r.data.status === "success") {
          setorderid(r.data.orderid);
          setreceipt(r.data.receipt);
        }
      });
  };

  const handlePayment = async (params) => {
    if (payamount > 0) {
      if (businessdata.contact != null || businessdata.email != null) {
        // create order in backend and obtain key before proceeding
        createOrderInBackEnd();

        const options = {
          key: "rzp_live_Oo7juGqNVmZXzP", // Live APi Key
          // key: "rzp_test_EqOjfsQ3VlX9A8", // Test Api Key
          amount: Number(payamount) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "sellio.in",
          description: "Subscription Payment",
          image: "https://sellio.in/assets/icon-set/ms-icon-310x310.png",
          order_id: orderid, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
          handler: function (response) {
            // handle on success
            axios
              .post(API_PATH + "ManagePayments.php", {
                action: "updatePaymentAgainstUser",
                userid: localStorage.getItem("ez-userid"),
                usertoken: localStorage.getItem("ez-usertoken"),
                paymentid: response.razorpay_payment_id,
                receiptno: receipt,
                paidamount: payamount,
                plan: plan,
              })
              .then((r) => {
                if (r.data.status === "success") {
                  alert("You have successfully upgraded your account.");
                  window.location.reload();
                }
              });
          },
          prefill: {
            name: businessdata.unique_user_name,
            email: businessdata.email,
            contact: businessdata.contact,
          },
          notes: {
            address: "Subscription",
          },
          theme: {
            color: "#ffcc00",
          },
        };

        const rzp1 = new window.Razorpay(options);

        rzp1.on("payment.failed", function (response) {
          alert("Sorry, transaction failed. Please try again later.");
        });

        rzp1.open();
      } else {
        alert(
          "Email address or contact number seem to be incorrect please update before making payment."
        );
      }
    } else {
      alert("Please select a plan, no plan selected.");
    }
  };

  return (
    <div className="mt-5 pt-5">
      <div className="container mh-90">
        <div className="text-center">
          <img
            src={require("./../../assets/img/sorry.webp")}
            alt="plan-icon"
            width={"150px"}
          />
          <h2 className="text-center mt-2">
            Sorry, your current plan doesn't allow this feature.
          </h2>
          <h4 className="text-center mt-2">
            Either, your plan has expired or you are using free version. Please
            upgrade to unlock the full potential.
          </h4>
          <p className="mt-2">
            We need some funds to keep the platform running. Your coordinatation
            is expected to make this service awesome. <br />
            Please select from the plans and start managing your business again.
          </p>
          <div className="">
            <button
              className="btn btn-primary rounded-pill mt-2 ms-1 me-1"
              onClick={(e) => {
                selectplan(e, 6999, "basic");
              }}
            >
              Basic @ Rs. 6999/-
            </button>
            <button
              className="btn btn-primary rounded-pill mt-2 ms-1 me-1"
              onClick={(e) => {
                selectplan(e, 18999, "advance");
              }}
            >
              Advance @ Rs. 18999/-
            </button>
            <button
              className="btn btn-primary rounded-pill mt-2 ms-1 me-1"
              onClick={(e) => {
                selectplan(e, 24999, "professional");
              }}
            >
              Professional @ Rs. 24999/-
            </button>
          </div>
          <button
            className="btn  btn-primary  rounded-pill mt-2"
            onClick={handlePayment}
          >
            Renew Now
          </button>
        </div>
      </div>
    </div>
  );
}
