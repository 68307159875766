import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { API_PATH, USER_ID, USER_ROLE, USER_TOKEN } from "../../Config";
import useLoginAuth from "../../hooks/useLoginAuth";
import Spinner from "../../layout/common/Spinner";
import OrderNav from "./OrderNav";
import useValidateRegType from "../../hooks/useValidateRegType";
import PayForExpired from "../../layout/common/PayForExpired";

export default function ManageOrders() {
  const [spinner, setspinner] = useState(false);

  const validatelogin = useLoginAuth();
  const validateSubscription = useValidateRegType();
  const [dataLimit, setDataLimit] = useState(100);
  const [orderBy, setOrderBy] = useState("DESC");
  const [skipper, setSkipper] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [tillDate, setTillDate] = useState("");
  const [dataStatus, setDataStatus] = useState("");
  const [dataset, setDataSet] = useState([]);
  const [changeDetector, setChangeDetector] = useState(0);
  const [searchstr, setsearchstr] = useState("");

  const ProcessData = () => {
    // setspinner(true)
    axios
      .post(API_PATH + `ManageCart.php`, {
        userid: USER_ID,
        userrole: USER_ROLE,
        usertoken: USER_TOKEN,
        datalimit: dataLimit,
        orderby: orderBy,
        fromdate: fromDate,
        tilldate: tillDate,
        skipval: skipper,
        searchstr: searchstr,
        action: "getOrdersForAdmin",
        orderstatus: "generated",
      })
      .then((res) => {
        setDataStatus(res.data.status);
        setDataSet(res.data.retval);
        setspinner(false);
      });
  };

  // reduce skipper value only if skipper value is
  // greater than zero else it will show blank page on
  // next increament

  const reduceSkipper = (e) => {
    if (skipper > 0) {
      setSkipper(skipper - dataLimit);
    }
  };

  // increase skipper value only if skipper value is
  // greater than zero else it will show blank page on
  // next increament

  const increaseSkipper = (e) => {
    setSkipper(Number(skipper) + Number(dataLimit));
  };

  const changeOrderStatus = (e) => {
    e.preventDefault();
    const cartid = e.target.id;
    const cartstatus = e.target.value;
    if (cartstatus === "ready-to-ship" || cartstatus === "rejected") {
      setspinner(true);
      axios
        .post(API_PATH + `ManageCart.php`, {
          usertoken: USER_TOKEN,
          cartid: cartid,
          cartstatus: cartstatus,
          action: "updateCartStatus",
        })
        .then((r) => {
          setChangeDetector(Number(changeDetector) + 1);
          setspinner(false);
        });
    }
  };

  // list all dependency in dependency
  //array to let filter work properly

  useEffect(() => {
    ProcessData();
  }, [dataLimit, orderBy, skipper, changeDetector, searchstr]);

  if (validatelogin !== true) {
    return <Navigate to={"/login"} />;
  } else {
    if (validateSubscription.status === false) {
      return <PayForExpired />;
    } else {
      return (
        <>
          <div className="bg-light p-2 pt-5 pb-5 mh-90">
            {spinner === true && <Spinner />}
            <div className="mt-5">
              <OrderNav />
            </div>
            <div className="row m-0 mt-2">
              <div className="col-6 col-sm-3 col-md-2 p-1">
                <label className="">Data Limit</label>
                <select
                  className="form-control p-1"
                  onChange={(e) => {
                    setDataLimit(e.target.value);
                  }}
                >
                  <option value={dataLimit} key="">
                    {dataLimit}
                  </option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                  <option value="5000">5000</option>
                </select>
              </div>
              <div className="col-6 col-sm-3 col-md-2 p-1">
                <label className="">Order By</label>
                <select
                  className="form-control p-1"
                  onChange={(e) => {
                    setOrderBy(e.target.value);
                  }}
                >
                  <option value={orderBy}>{orderBy}</option>
                  <option value="ASC">ASC</option>
                  <option value="DESC">DESC</option>
                </select>
              </div>
              <div className="col col-sm-3 col-md-2 p-1">
                <label className="">Date From</label>
                <input
                  type="date"
                  className="form-control p-1"
                  onChange={(e) => {
                    setFromDate(e.target.value);
                  }}
                />
              </div>
              <div className="col col-sm-3 col-md-2 p-1">
                <label className="">Till Date</label>
                <input
                  type="date"
                  className="form-control p-1"
                  onChange={(e) => {
                    setTillDate(e.target.value);
                  }}
                />
              </div>
              <div className="col p-1">
                <label>Navigate</label>
                <div className="p-0">
                  <button
                    className="btn bg-danger text-white p-1"
                    onClick={reduceSkipper}
                  >
                    <i className="bi bi-arrow-bar-left"></i>
                  </button>
                  <button
                    className="ms-2 btn bg-success text-white p-1"
                    onClick={increaseSkipper}
                  >
                    <i className="bi bi-arrow-bar-right"></i>
                  </button>
                </div>
              </div>
              <div className="col col-sm-3 col-md-2 p-1">
                <label className="">Search Orders</label>
                <input
                  type="search"
                  className="form-control p-1"
                  onChange={(e) => {
                    setsearchstr(e.target.value);
                  }}
                />
              </div>
            </div>
            <div
              className="p-2"
              style={{ overflowX: "scroll", fontSize: "12px" }}
            >
              <table className=" rounded bg-white table table-responsive">
                <thead>
                  <tr key="">
                    <th>Action</th>
                    <th>Sr N.</th>
                    <th>Prod Name</th>
                    <th>Prod Qty</th>
                    <th>Total</th>
                    <th>Buyer Name</th>
                    <th>Bill to Address</th>
                    <th>Ship to Address</th>
                    <th>Sales Mode</th>
                    <th>OrderId</th>
                    <th>Pay Mode</th>
                    <th>Pay Ref No</th>
                    <th>Pay Status</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Order Status</th>
                  </tr>
                </thead>
                <tbody>
                  {dataStatus === "success" &&
                    dataset.map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>
                            <select id={data.id} onChange={changeOrderStatus}>
                              <option value="" key="0"></option>
                              <option value="ready-to-ship">Accept</option>
                              <option value="rejected">Reject</option>
                            </select>
                          </td>
                          <td>{data.id}</td>
                          <td>{data.prod_name}</td>
                          <td>{data.prod_qty}</td>
                          <td>{data.prod_total}</td>
                          <td>{data.buyer_name}</td>
                          <td>{data.bill_to_address}</td>
                          <td>{data.ship_to_address}</td>
                          <td>{data.sales_mode}</td>
                          <td>{data.orderid}</td>
                          <td>{data.pay_mode}</td>
                          <td>{data.pay_reference_no}</td>
                          <td>{data.pay_status}</td>
                          <td>{data.order_date}</td>
                          <td>{data.order_time}</td>
                          <td>{data.order_status}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      );
    }
  }
}
