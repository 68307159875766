import React from "react";

export default function Features() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6 col-12 p-2">
            <div className="bg-white shadow rounded p-4 text-center">
              <div className="fs-1">
                <i className="bg-light text-primary bi bi-person-lines-fill border rounded ps-2 pe-2 border-2"></i>
              </div>
              <h5 className="pt-2 features-title">Business Profile</h5>
              <p>
                Create your business profile and share with everyone in just one
                click.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-12 p-2">
            <div className="bg-white shadow rounded p-4 text-center">
              <div className="fs-1">
                <i className="bg-light  text-primary bi bi-shop-window border rounded ps-2 pe-2 border-2"></i>
              </div>
              <h5 className="pt-2 features-title">Product Catalogue</h5>
              <p>Add your product Catalogue and show off to your customers.</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-12 p-2">
            <div className="bg-white  shadow rounded p-4 text-center">
              <div className="fs-1">
                <i className="bg-light text-primary bi bi-receipt border rounded ps-2 pe-2 border-2"></i>
              </div>
              <h5 className="pt-2 features-title">Sales And Management</h5>
              <p>
                Create sale, Receive orders and keep track of everything in one
                place.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-12 p-2">
            <div className="bg-white shadow rounded p-4 text-center">
              <div className="fs-1">
                <i className="bg-light  text-primary bi bi-clipboard2-data border rounded ps-2 pe-2 border-2"></i>
              </div>
              <h5 className="pt-2 features-title">Analytics</h5>
              <p>
                Manage everything online in realtime and get reports anytime,
                anywhere.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
