import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { API_PATH } from "../../Config";
import useLoginAuth from "../../hooks/useLoginAuth";
import useValidateRegType from "../../hooks/useValidateRegType";
import PayForExpired from "../../layout/common/PayForExpired";
import Spinner from "../../layout/common/Spinner";

export default function CreateGalleryItem() {
  const [spinner, setspinner] = useState(false);

  const validatelogin = useLoginAuth();
  const validateSubscription = useValidateRegType();
  const [mediadescription, setmediadescription] = useState("");
  const [mediatype, setmediatype] = useState("image");
  const [imagefile, setimagefile] = useState("");
  const [medialink, setmedialink] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateIntro = () => {
    const linktype = medialink.slice(0, 8);
    if (mediatype === "video" && linktype !== "https://") {
      alert(
        "We can not accept un-secure media link. Check if url has https:// in it."
      );
    } else {
      const fd = new FormData();

      fd.append("action", "addmediatogallery");
      fd.append("userid", localStorage.getItem("ez-userid"));
      fd.append("usertoken", localStorage.getItem("ez-usertoken"));

      fd.append("imagefile", imagefile);
      fd.append("mediadescription", mediadescription);
      fd.append("mediatype", mediatype);
      fd.append("medialink", medialink);

      if (imagefile || medialink) {
        // check params are filled or not
        setspinner(true);
        axios
          .post(API_PATH + "ManageStoreFront.php", fd, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.status === "success") {
              setmediadescription("");
              setimagefile([]);
              alert("Data Updated successfully!");
            } else {
              if (res.data.error === "invalidimage") {
                alert(
                  "No valid image file. Please add image of jpg or png type."
                );
              } else {
                alert("Something went wrong, please try again later!");
              }
            }
            setspinner(false);
          });
      } else {
        alert("Please add image first.");
        setspinner(false);
      }
    }

    window.scrollTo(0, 0);
  };
  if (validatelogin !== true) {
    return <Navigate to={"/login"} />;
  } else {
    if (validateSubscription.status === false) {
      return <PayForExpired />;
    } else {
      return (
        <div className="pt-5 mh-90 pb-5">
          <div className="container mt-5">
            {spinner === true && <Spinner />}
            <h2 className="text-secondary">Add item to gallery</h2>
            <small>
              This page enables you to add new Gallery Item to your business
              profile on sellio.in. Creating a Gallery is very easy. You just
              have to fill up the simple form and submit it.
            </small>
          </div>
          <div className="container mt-4">
            <div className="row m-0">
              <div className="col-md-8 pt-2">
                <label>Brief Description</label>
                <input
                  type="text"
                  className="form-control"
                  value={mediadescription}
                  onChange={(e) => setmediadescription(e.target.value)}
                />
              </div>
              <div className="col-md-4 pt-2">
                <label>Select Media Type</label>
                <select
                  className="form-control"
                  value={mediatype}
                  onChange={(e) => setmediatype(e.target.value)}
                >
                  <option value="image">Image</option>
                  <option value="video">Video</option>
                </select>
              </div>
              {mediatype === "video" && (
                <div className="col-md-8 pt-2">
                  <label>Add Video Link</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setmedialink(e.target.value)}
                  />
                </div>
              )}
              {mediatype === "image" && (
                <div className="col-md-4 pt-2">
                  <label>Add an image</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setimagefile(e.target.files[0])}
                  />
                </div>
              )}
            </div>
            <div className="row mt-3">
              <div className="d-none d-lg-block col-lg-3"></div>
              <div className="col-lg-3 text-center p-2">
                <button className="btn btn-primary" onClick={updateIntro}>
                  Add Media to Gallery
                </button>
              </div>
              <div className="col-lg-3 text-center p-2">
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/view-gallery-items")}
                >
                  Manage Gallery
                </button>
              </div>
              <div className="d-none d-lg-block col-lg-3 p-2"></div>
            </div>
          </div>
        </div>
      );
    }
  }
}
