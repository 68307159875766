import React from "react";
import { Link } from "react-router-dom";
import { SITE_TITLE } from "../../Config";

export default function Footer() {
  return (
    <>
      <div className="footer p-0 bg-white border">
        <div className="border-top">
          <div
            className="container p-3 text-center"
            style={{ fontSize: "12px" }}
          >
            &#169; {SITE_TITLE} By{" "}
            <a
              href="https://innovilla.in"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "black" }}
            >
              Innovilla
            </a>{" "}
            |{" "}
            <Link to={"/terms-and-conditions"} className="text-dark">
              Terms & Conditions
            </Link>{" "}
            |{" "}
            <Link to={"/privacy-policy"} className="text-dark">
              Privacy Policy
            </Link>{" "}
            |{" "}
            <Link to={"/refund-and-shipping"} className="text-dark">
              Refund & Shipping
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
