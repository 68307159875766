import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { API_PATH } from "../../Config";
import useLoginAuth from "../../hooks/useLoginAuth";
import Spinner from "../../layout/common/Spinner";
import ProfileCompletionDashboard from "./ProfileCompletionDashboard";

export default function UpdatePaymentLinks() {
  const [spinner, setspinner] = useState(false);

  const validatelogin = useLoginAuth();
  const navigate = useNavigate();
  const [profiledata, setprofiledata] = useState([]);
  const [profilescore, setprofilescore] = useState("");

  const [vpa, setvpa] = useState("");
  const [qrimage, setqrimage] = useState([]);
  const [pglink, setpglink] = useState("");
  const [bankname, setbankname] = useState("");
  const [branchname, setbranchname] = useState("");
  const [accountnumber, setaccountnumber] = useState("");
  const [ifsccode, setifsccode] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true);
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "getBusinessProfile",
        userid: localStorage.getItem("ez-userid"),
        usertoken: localStorage.getItem("ez-usertoken"),
      })
      .then((r) => {
        if (r.data.status === "success") {
          setprofiledata(r.data.retval);
          setprofilescore(r.data.profilescore + "%");
        } else {
          setprofilescore("0%");
        }
        setspinner(false);
      });
  }, []);

  const updateProfile = () => {
    const fd = new FormData();

    fd.append("action", "updatePaymentLinks");
    fd.append("userid", localStorage.getItem("ez-userid"));
    fd.append("usertoken", localStorage.getItem("ez-usertoken"));

    fd.append("vpa", vpa);
    fd.append("qrimage", qrimage);
    fd.append("pglink", pglink);
    fd.append("bankname", bankname);
    fd.append("branchname", branchname);
    fd.append("accountnumber", accountnumber);
    fd.append("ifsccode", ifsccode);

    // check params are filled or not
    setspinner(true);
    axios
      .post(API_PATH + "ManageStoreFront.php", fd, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          alert("Data Updated successfully!");
        }
        setspinner(false);
      });

    window.scrollTo(0, 0);
  };
  if (validatelogin !== true) {
    return <Navigate to={"/login"} />;
  } else {
    return (
      <div className="pt-5 mh-90 pb-5 bg-white">
        {spinner === true && <Spinner />}
        <div className="container mt-4">
          <h2 className="text-secondary">Update Payment Links</h2>
          <small>
            Fill up details reuired in form fields and create your own Sellio
            Store where you can display all your business information at your
            customized business page. Click{" "}
            {
              <Link
                to={"/in/" + profiledata.unique_user_name}
                className="border rounded bg-warning text-dark p-1"
              >
                Here
              </Link>
            }{" "}
            to view your page progress.
          </small>
        </div>
        <ProfileCompletionDashboard score={profilescore} />
        <div className="container mt-4">
          <div className="row pb-4">
            <div className="col-12">
              <h4 className="text-center mt-5 text-secondary">
                Online Payments
              </h4>
            </div>
            <div className="pt-2 col-md-3"></div>
            <div className="pt-2 col-md-6">
              <label>Upi/Virtual payment Address </label>
              <input
                type="text"
                className="form-control"
                placeholder={profiledata.upi_pay_link}
                onChange={(e) => setvpa(e.target.value)}
              />
            </div>
            <div className="pt-2 col-md-3"></div>

            <div className="pt-2 col-md-3"></div>
            <div className="pt-2 col-md-6">
              <label>Attach QR Code </label>
              <input
                type="file"
                className="form-control"
                placeholder={profiledata.b_qr_image}
                onChange={(e) => setqrimage(e.target.files[0])}
              />
            </div>
            <div className="pt-2 col-md-3"></div>

            <div className="pt-2 col-md-3"></div>
            <div className="pt-2 col-md-6">
              <label>Payment Gateway Link if any</label>
              <input
                type="text"
                className="form-control"
                placeholder={profiledata.gateway_pay_link}
                onChange={(e) => setpglink(e.target.value)}
              />
            </div>
            <div className="pt-2 col-md-3"></div>
            <div className="col-12">
              <h4 className="text-center mt-5 text-secondary">Bank Details</h4>
            </div>
            <div className="pt-2 col-md-3"></div>
            <div className="pt-2 col-md-6">
              <label>Bank Name </label>
              <input
                type="text"
                className="form-control"
                placeholder={profiledata.bank_name}
                onChange={(e) => setbankname(e.target.value)}
              />
            </div>
            <div className="pt-2 col-md-3"></div>

            <div className="pt-2 col-md-3"></div>
            <div className="pt-2 col-md-6">
              <label>Bank Branch Name </label>
              <input
                type="text"
                className="form-control"
                placeholder={profiledata.branch_name}
                onChange={(e) => setbranchname(e.target.value)}
              />
            </div>
            <div className="pt-2 col-md-3"></div>

            <div className="pt-2 col-md-3"></div>
            <div className="pt-2 col-md-6">
              <label>Bank Account Number </label>
              <input
                type="number"
                className="form-control"
                placeholder={profiledata.account_number}
                onChange={(e) => setaccountnumber(e.target.value)}
              />
            </div>
            <div className="pt-2 col-md-3"></div>

            <div className="pt-2 col-md-3"></div>
            <div className="pt-2 col-md-6">
              <label>Ifsc Code </label>
              <input
                type="text"
                className="form-control"
                placeholder={profiledata.ifsc_code}
                onChange={(e) => setifsccode(e.target.value)}
              />
            </div>
            <div className="pt-2 col-md-3"></div>
          </div>
          <div className="row mt-3">
            <div className="d-none d-lg-block col-lg-3"></div>
            <div className="col-lg-3 text-center p-2" onClick={updateProfile}>
              <button className="btn btn-primary">Update Payment Links</button>
            </div>
            <div className="col-lg-3 text-center p-2">
              <button
                className="btn btn-primary"
                onClick={() => navigate("/update-about-us")}
              >
                Go to About Business
              </button>
            </div>
            <div className="d-none d-lg-block col-lg-3 p-2"></div>
          </div>
        </div>
      </div>
    );
  }
}
