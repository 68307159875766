import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { API_PATH, APP_TOKEN } from "../../Config";
import Spinner from "../../layout/common/Spinner";

export default function Login() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [statusClass, setStatusClass] = useState("d-none");
  const [statusMessage, setStatusMessage] = useState("");
  const [checkLoggedIn, setCheckLoggedIn] = useState("false");

  const [spinner, setspinner] = useState(false);

  const userLogin = (e) => {
    e.preventDefault();
    if (loginId === "" || password === "") {
      setStatusClass("d-block alert-danger");
      setStatusMessage("Something is Missing, Please Check Out.");
    } else {
      setspinner(true);
      axios
        .post(API_PATH + "Auth.php", {
          apptoken: APP_TOKEN,
          loginId: loginId,
          password: password,
          action: "userLogin",
        })
        .then(function (r) {
          switch (r.data.status) {
            case "success":
              setStatusClass("alert-success");
              setStatusMessage("Login Successfull. Redirecting....");
              localStorage.setItem("ez-isLoggedIn", "true");
              localStorage.setItem("ez-usertoken", r.data.usertoken);
              localStorage.setItem("ez-userid", r.data.userid);
              localStorage.setItem("ez-userrole", r.data.userrole);
              localStorage.setItem("ez-superowner", r.data.superowner);
              localStorage.setItem("ez-userstatus", r.data.userstatus);
              localStorage.setItem("ez-upath", r.data.upath);
              setCheckLoggedIn("true");
              break;

            case "usernotfound":
              setStatusClass("alert-danger");
              setStatusMessage(
                "No User detail matched in system. Please try again."
              );
              break;

            default:
              setStatusClass("alert-danger");
              setStatusMessage("Something went wrong, Please contact Admin");
              break;
          }
          window.scrollTo(0, 0);
          setspinner(false);
        });
    }
  };
  if (checkLoggedIn === "true") {
    return <Navigate to={"/dashboard"} />;
  } else {
    return (
      <div className="bg-white mh-90 ">
        <div className="container mt-5 pt-2">
          {spinner === true && <Spinner />}

          <form onSubmit={userLogin}>
            <div className="row m-0 mt-4">
              <div className="col-md-6 border-end">
                <div className="border-bottom p-1">
                  <h1>Login</h1>
                  <small className="pb-1">
                    {" "}
                    Please enter Contact NUmber or Email and password to login
                  </small>
                </div>
                <div className={`alert ${statusClass} `} role="alert">
                  {statusMessage}
                </div>
                <div className="p-1">
                  <label>Please enter Contact number or Email Address</label>
                  <input
                    type="text"
                    className="form-control bg-light mt-1  rounded-pill"
                    onChange={(e) => {
                      setLoginId(e.target.value);
                    }}
                  />
                </div>
                <div className="p-1 mt-1">
                  <label>Please enter User Password</label>
                  <input
                    type="password"
                    className="form-control bg-light mt-1  rounded-pill"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
                <div className="p-1">
                  <button
                    className="btn btn-primary rounded-pill"
                    onClick={userLogin}
                  >
                    Login
                  </button>
                  <button
                    className="btn btn-primary rounded-pill ms-2"
                    onClick={() => navigate("/user-registration")}
                  >
                    Register
                  </button>
                </div>
                <div className="text-center pt-5">
                  Login to access your comprehensive business management tools
                  and take your operations to the next level. Whether you're
                  showcasing products, generating leads, or managing sales,
                  Sellio.in is here to streamline your business processes
                  efficiently and affordably. Join us today and discover how
                  easy it is to grow your business with our intuitive SaaS
                  platform.
                </div>
              </div>
              <div className="col-md-6 text-justify" style={{ fontSize: 14 }}>
                <div className="text-center">
                  <div>
                    <img
                      alt="PricingImage"
                      width={"90%"}
                      height={"90%"}
                      src={require("./../../assets/img/login.png")}
                      className=""
                    />
                  </div>
                  <div className="text-center d-block d-lg-none">
                    <img
                      width={"80%"}
                      height={"80%"}
                      src={require("./../../assets/img/sellio_logo.png")}
                      alt="about"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
