import axios from "axios";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { API_PATH, gemini, USER_ID, USER_TOKEN } from "../../Config";
import useLoginAuth from "../../hooks/useLoginAuth";
import useValidateRegType from "../../hooks/useValidateRegType";
import PayForExpired from "../../layout/common/PayForExpired";
import Spinner from "../../layout/common/Spinner";
import imageCompression from "browser-image-compression";
import { Editor } from "@tinymce/tinymce-react";

const fetchCategoryData = async (retries = 3) => {
  try {
    const response = await axios.post(API_PATH + "ManageInventory.php", {
      usertoken: USER_TOKEN,
      action: "getCategoryData",
      parentcat: "",
    });

    if (response.data.status === "success") {
      return response.data.retval;
    } else {
      throw new Error("Failed to fetch category data");
    }
  } catch (error) {
    if (retries > 0) {
      // Retry after a delay if it fails
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return fetchCategoryData(retries - 1);
    } else {
      console.error(
        "Failed to fetch category data after multiple attempts:",
        error
      );
      return [];
    }
  }
};

export default function AddNewProduct() {
  const pathname = useLocation().pathname;
  const editorRef = useRef(null);
  const [errormsg, seterrormsg] = useState([]);
  const [spinner, setspinner] = useState(false);
  const validatelogin = useLoginAuth();
  const validateSubscription = useValidateRegType();

  // prepare category selection
  const [selectCat1, setSelectCat1] = useState([]);
  const [prodCat, setProdCat] = useState("");

  const [selectsubCat1, setselectsubCat1] = useState([]);
  const [subcat1, setsubcat1] = useState("");

  const [selectsubCat2, setselectsubCat2] = useState([]);
  const [subcat2, setsubcat2] = useState("");

  const [selectsubCat3, setselectsubCat3] = useState([]);
  const [subcat3, setsubcat3] = useState("");

  const [selectsubCat4, setselectsubCat4] = useState([]);
  const [subcat4, setsubcat4] = useState("");

  const [resultStatus, setresultStatus] = useState("");

  useEffect(() => {
    const loadCategoryData = async () => {
      setspinner(true);
      window.scrollTo(0, 0);

      const data = await fetchCategoryData();
      setSelectCat1(data);

      setspinner(false);
    };

    loadCategoryData();
  }, []);

  // Memoize the category data so that it is not recalculated on every render
  const memoizedCategoryData = useMemo(() => selectCat1, [selectCat1]);

  // Define a function to handle image compression and resizing
  const compressAndResizeImage = async (imageFile) => {
    try {
      const options = {
        maxSizeMB: 1, // Maximum size in MB
        maxWidthOrHeight: 1000, // Maximum width or height of the image
      };

      // Compress and resize the image
      const compressedImage = await imageCompression(imageFile, options);
      // console.log(compressedImage);
      return compressedImage;
    } catch (error) {
      console.error("Error compressing image:", error);
      return null;
    }
  };

  // Define a function to convert Blob to File -- unused
  const blobToFile = (blob, fileName) => {
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  };

  // sub category 1
  useEffect(() => {
    if (prodCat.length > 2) {
      axios
        .post(API_PATH + "ManageInventory.php", {
          usertoken: USER_TOKEN,
          action: "getCategoryData",
          parentcat: prodCat,
        })
        .then((r) => {
          r.data.status === "success"
            ? setselectsubCat1(r.data.retval)
            : setselectsubCat1([]);
        });
    }
  }, [prodCat]);

  // sub category 2
  useEffect(() => {
    if (subcat1.length > 2) {
      axios
        .post(API_PATH + "ManageInventory.php", {
          usertoken: USER_TOKEN,
          action: "getCategoryData",
          parentcat: subcat1,
        })
        .then((r) => {
          r.data.status === "success"
            ? setselectsubCat2(r.data.retval)
            : setselectsubCat2([]);
        });
    }
  }, [subcat1]);

  // sub category 3
  useEffect(() => {
    if (subcat2.length > 2) {
      axios
        .post(API_PATH + "ManageInventory.php", {
          usertoken: USER_TOKEN,
          action: "getCategoryData",
          parentcat: subcat2,
        })
        .then((r) => {
          r.data.status === "success"
            ? setselectsubCat3(r.data.retval)
            : setselectsubCat3([]);
        });
    }
  }, [subcat2]);

  // sub category 4
  useEffect(() => {
    if (subcat3.length > 2) {
      axios
        .post(API_PATH + "ManageInventory.php", {
          usertoken: USER_TOKEN,
          action: "getCategoryData",
          parentcat: subcat3,
        })
        .then((r) => {
          r.data.status === "success"
            ? setselectsubCat4(r.data.retval)
            : setselectsubCat4([]);
        });
    }
  }, [subcat3]);

  // change values of other categories if parent has changed
  const processprimarycategory = (e) => {
    setProdCat(e.target.value);

    setselectsubCat1([]);
    setsubcat1("");
    setselectsubCat2([]);
    setsubcat2("");
    setselectsubCat3([]);
    setsubcat3("");
    setselectsubCat4([]);
    setsubcat4("");
  };
  const processsubcat1 = (e) => {
    setsubcat1(e.target.value);
    setselectsubCat2([]);
    setsubcat2("");
    setselectsubCat3([]);
    setsubcat3("");
    setselectsubCat4([]);
    setsubcat4("");
  };
  const processsubcat2 = (e) => {
    setsubcat2(e.target.value);
    setselectsubCat3([]);
    setsubcat3("");
    setselectsubCat4([]);
    setsubcat4("");
  };
  const processsubcat3 = (e) => {
    setsubcat3(e.target.value);
    setselectsubCat4([]);
    setsubcat4("");
  };

  // formdata
  const [prodServiceType, setProdServiceType] = useState("product");
  const [fullName, setFullName] = useState("");
  const [alias, setAlias] = useState("");
  const [sku, setSku] = useState("");
  const [barcode, setBarcode] = useState("");
  const [stockQty, setStockQty] = useState("1");
  const [mrp, setMrp] = useState("0");
  const [salePrice, setSalePrice] = useState("0");
  const [finalSalePrice, setFinalSalePrice] = useState("0");
  const [weight, setWeight] = useState("0");
  const [weighingUnit, setWeighingUnit] = useState("");
  const [qtyInPack, setQtyInPack] = useState("0");
  const [itemCode, setItemCode] = useState("");
  const [itemGroupCode, setItemGroupCode] = useState("");
  const [brandName, setBrandName] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [longDesc, setLongDesc] = useState("");
  const [mfgDate, setMfgDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [origin, setOrigin] = useState("India");
  // const [taxType, setTaxType] = useState("none");
  const [taxRate, setTaxRate] = useState("0");
  const [taxAmount, setTaxAmount] = useState("0");
  const [hsnCode, setHsnCode] = useState("");
  const [batchNumber, setBacthNumber] = useState("");
  const [prodImage1, setProdImage1] = useState([]);
  const [prodImage2, setProdImage2] = useState([]);
  const [prodImage3, setProdImage3] = useState([]);
  const [prodImage4, setProdImage4] = useState([]);
  const [prodImage5, setProdImage5] = useState([]);

  // setNewsImagePreview(URL.createObjectURL(e.target.files[0]));

  const addProductToData = () => {
    const prodFormData = new FormData();

    // to get data from tiny mce editor
    // description state is replace with description reference
    const description = editorRef.current.getContent();

    prodFormData.append("action", "addProductToData");
    prodFormData.append("userid", USER_ID);
    prodFormData.append("username", "");
    prodFormData.append("usertoken", USER_TOKEN);

    prodFormData.append("prodImage1", prodImage1);
    prodFormData.append("prodImage2", prodImage2);
    prodFormData.append("prodImage3", prodImage3);
    prodFormData.append("prodImage4", prodImage4);
    prodFormData.append("prodImage5", prodImage5);

    prodFormData.append("primarycategory", prodCat);
    prodFormData.append("subcat1", subcat1);
    prodFormData.append("subcat2", subcat2);
    prodFormData.append("subcat3", subcat3);
    prodFormData.append("subcat4", subcat4);

    prodFormData.append("prodServiceType", prodServiceType);
    prodFormData.append("fullName", fullName);
    prodFormData.append("alias", alias);
    prodFormData.append("sku", sku);
    prodFormData.append("barcode", barcode);
    prodFormData.append("stockQty", stockQty);
    prodFormData.append("mrp", mrp);
    prodFormData.append("salePrice", salePrice);
    prodFormData.append("finalsaleamount", finalSalePrice);
    prodFormData.append("weight", weight);
    prodFormData.append("weighingUnit", weighingUnit);
    prodFormData.append("qtyInPack", qtyInPack);
    prodFormData.append("prodCat", prodCat);
    prodFormData.append("itemCode", itemCode);
    prodFormData.append("itemGroupCode", itemGroupCode);
    prodFormData.append("brandName", brandName);
    prodFormData.append("shortDesc", shortDesc);
    prodFormData.append("longDesc", description);
    prodFormData.append("mfgDate", mfgDate);
    prodFormData.append("expiryDate", expiryDate);
    prodFormData.append("origin", origin);
    prodFormData.append("taxType", "none");
    prodFormData.append("taxRate", taxRate);
    prodFormData.append("taxamount", taxAmount);
    prodFormData.append("hsnCode", hsnCode);
    prodFormData.append("batchNumber", batchNumber);

    // check params are filled or not

    if (!fullName) {
      seterrormsg((err) => [
        ...err,
        "Name is mandatory, please add product name to continue",
      ]);
    } else if (!stockQty || stockQty < 1) {
      seterrormsg((err) => [
        ...err,
        "Add stock quantity to continue, minimum is one.",
      ]);
    } else if (!mrp || mrp < 1) {
      seterrormsg((err) => [
        ...err,
        "MRP is mandatory, please enter MRP to continue.",
      ]);
    } else if (!salePrice || salePrice < 1) {
      seterrormsg((err) => [
        ...err,
        "Sale price can not be empty or less than 1.",
      ]);
    } else if (mrp < Number(salePrice)) {
      seterrormsg((err) => [
        ...err,
        "Sale price can not be grater than MRP" +
          "sale price" +
          { salePrice } +
          "mrp" +
          mrp,
      ]);
    } else if (!prodCat) {
      seterrormsg((err) => [
        ...err,
        "Category is a required field, please add category.",
      ]);
    } else if (!shortDesc) {
      seterrormsg((err) => [
        ...err,
        "Short description is required, please add one to continue.",
      ]);
    } else if (!description) {
      seterrormsg((err) => [
        ...err,
        "Long description is mandatory, you can generate is using KAI Ai Generator as well.",
      ]);
    } else if (!origin) {
      seterrormsg((err) => [
        ...err,
        "Origin of product is required, by default it is set to india.",
      ]);
    } else if (!prodImage1) {
      seterrormsg((err) => [
        ...err,
        "At least one product image is required, please add one.",
      ]);
    } else {
      setspinner(true);
      seterrormsg([]);
      axios
        .post(API_PATH + "ManageInventory.php", prodFormData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            seterrormsg(["Data Updated Successfully"]);
            setresultStatus("success");

            // Clear the image inputs after a successful upload
            setProdImage1(null);
            setProdImage2(null);
            setProdImage3(null);
            setProdImage4(null);
            setProdImage5(null);
          } else {
            seterrormsg(["Something went wrong, please try again later."]);
          }
          setTimeout(() => {
            seterrormsg([]);
            setresultStatus("");
          }, 3000);
          setspinner(false);
        });
    }

    window.scrollTo(0, 0);
  };

  const calcSalePrice = (e) => {
    setSalePrice(Number(e.target.value));
    var taxamount = (e.target.value * taxRate) / 100;
    setTaxAmount(taxamount);
    var finalsaleamount = Number(e.target.value) + Number(taxamount);
    setFinalSalePrice(finalsaleamount);
  };

  const calcTaxAmount = (e) => {
    setTaxRate(e.target.value);
    var taxamount = (salePrice * e.target.value) / 100;
    setTaxAmount(taxamount);
    var finalsaleamount = Number(salePrice) + Number(taxamount);
    setFinalSalePrice(finalsaleamount);
  };

  const generatedescription = async () => {
    setspinner(true);
    const prompt = `Generate a detailed product description with at least 1000 words, 
                    where product name = ${fullName}  
                    and MRP = ${mrp}
                    and sale price = ${finalSalePrice} 
                    and brand name = ${brandName}
                    and short description = ${shortDesc}
                    and product category = ${prodCat}. follow these 
                    instructions as well. dont add placeholders or instructions.
                    provide final text that doesnt need any change. in formatted 
                    piece of code snippet only like html text editor outputs. 
                    provide only code snippet. 
                    no extra instructuction or editables or image or placeholder should be added.
                    dont include ${"```html"} or ${"```"}`;

    const res = await gemini(prompt.toString());
    setLongDesc(res);
    setspinner(false);
  };

  if (validatelogin !== true) {
    return <Navigate to={"/login"} />;
  } else {
    if (validateSubscription.status === false) {
      return <PayForExpired />;
    } else {
      return (
        <>
          <div className="bg-light mh-90">
            <div className="container mt-5 mh-90 pt-2">
              {spinner === true && <Spinner />}
              <h2 className="text-secondary">Add Product To Inventory</h2>
              {errormsg.length > 0 &&
                errormsg.map((d, i) => {
                  return (
                    <div
                      className={[
                        "alert alert-dismissible fade show mt-1",
                        setresultStatus === "success"
                          ? "alert-success"
                          : "alert-danger",
                      ].join(" ")}
                      role="alert"
                      key={i}
                    >
                      {d}
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                    </div>
                  );
                })}
              <div className="p-2 card shadow mt-2 mb-5">
                <div className="row m-0">
                  <div className="col-sm-3 pt-2">
                    <label>Product/Service Type*</label>
                    <select
                      className="border-danger form-control p-1"
                      value={prodServiceType}
                      onChange={(e) => {
                        setProdServiceType(e.target.value);
                      }}
                    >
                      <option value="product">Product</option>
                      <option value="service">Service</option>
                    </select>
                  </div>
                  <div className="col-sm-9 pt-2">
                    <label>Prod. Full Name*</label>
                    <input
                      type="text"
                      className="border-danger form-control p-1"
                      value={fullName}
                      onChange={(e) => {
                        setFullName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-6 pt-2">
                    <label>Prod. Alias</label>
                    <input
                      type="text"
                      className="border-grey form-control p-1"
                      value={alias}
                      onChange={(e) => {
                        setAlias(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>SKU</label>
                    <input
                      type="text"
                      className=" form-control p-1"
                      value={sku}
                      onChange={(e) => {
                        setSku(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Bar Code</label>
                    <input
                      type="text"
                      className=" form-control p-1"
                      value={barcode}
                      onChange={(e) => {
                        setBarcode(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2 hideonservice">
                    <label>Prod. Stock Qty(Numbers)*</label>
                    <input
                      type="number"
                      min={0}
                      className="border-danger form-control p-1"
                      value={stockQty}
                      onChange={(e) => {
                        setStockQty(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Prod. MRP*</label>
                    <input
                      type="number"
                      min={0}
                      className="border-danger form-control p-1"
                      value={mrp}
                      onChange={(e) => {
                        setMrp(e.target.value);
                      }}
                    />
                  </div>
                  {/* <div className="col-sm-3 pt-2">
                    <label>Invoice Type</label>
                    <select
                      className="form-control"
                      value={taxType}
                      onChange={(e) => {
                        setTaxType(e.target.value);
                      }}
                    >
                      <option value="non-gst-invoice" key="non-gst-invoice">
                        Non Gst Invoice
                      </option>
                      <option value="gst-invoice" key="gst-invoice">
                        Gst Invoice
                      </option>
                    </select>
                  </div> */}
                  <div className="col-sm-3 pt-2">
                    <label>Sale Price*</label>
                    <input
                      type="number"
                      min={0}
                      className="border-danger form-control p-1"
                      value={salePrice}
                      onChange={calcSalePrice}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Tax Rate GST in % </label>
                    <input
                      type="number"
                      min={0}
                      className="border-danger form-control p-1"
                      value={taxRate}
                      onChange={calcTaxAmount}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Tax Amount </label>
                    <input
                      type="number"
                      min={0}
                      className="border-danger form-control p-1"
                      value={taxAmount}
                      readOnly
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Final Sale Price </label>
                    <input
                      type="number"
                      min={0}
                      className="border-danger form-control p-1"
                      value={finalSalePrice}
                      readOnly
                    />
                  </div>
                  <div className="col-sm-3 pt-2 hideonservice">
                    <label>Item Weight</label>
                    <input
                      type="number"
                      min={0}
                      step="0.01"
                      className=" form-control p-1"
                      value={weight}
                      onChange={(e) => {
                        setWeight(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2 hideonservice">
                    <label>Weighing Unit</label>
                    <input
                      type="text"
                      className=" form-control p-1"
                      value={weighingUnit}
                      onChange={(e) => {
                        setWeighingUnit(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2 hideonservice">
                    <label>Quantity In Packing</label>
                    <input
                      type="number"
                      min={0}
                      className=" form-control p-1"
                      value={qtyInPack}
                      onChange={(e) => {
                        setQtyInPack(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Primary Category</label>
                    <select
                      className="form-control border-danger"
                      value={prodCat}
                      onChange={processprimarycategory}
                      autoComplete="on"
                    >
                      <option value="">Select from list</option>
                      {memoizedCategoryData.map((c1) => {
                        return (
                          <option value={c1.cat_name} key={c1.id}>
                            {c1.cat_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Sub Category 1</label>
                    <select
                      className="form-control"
                      value={subcat1}
                      onChange={processsubcat1}
                      autoComplete="on"
                    >
                      <option value="">Select from list</option>
                      {selectsubCat1.map((c1) => {
                        return (
                          <option value={c1.cat_name} key={c1.id}>
                            {c1.cat_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Sub Category 2</label>
                    <select
                      className="form-control"
                      value={subcat2}
                      onChange={processsubcat2}
                      autoComplete="on"
                    >
                      <option value="">Select from list</option>
                      {selectsubCat2.map((c1) => {
                        return (
                          <option value={c1.cat_name} key={c1.id}>
                            {c1.cat_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Sub Category 3</label>
                    <select
                      className="form-control"
                      value={subcat3}
                      onChange={processsubcat3}
                      autoComplete="on"
                    >
                      <option value="">Select from list</option>
                      {selectsubCat3.map((c1) => {
                        return (
                          <option value={c1.cat_name} key={c1.id}>
                            {c1.cat_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Sub Category 4</label>
                    <select
                      className="form-control"
                      value={subcat4}
                      onChange={(e) => {
                        setsubcat4(e.target.value);
                      }}
                      autoComplete="on"
                    >
                      <option value="">Select from list</option>
                      {selectsubCat4.map((c1) => {
                        return (
                          <option value={c1.cat_name} key={c1.id}>
                            {c1.cat_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Item Code</label>
                    <input
                      type="text"
                      className=" form-control p-1"
                      value={itemCode}
                      onChange={(e) => {
                        setItemCode(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Item Group Code</label>
                    <input
                      type="text"
                      className=" form-control p-1"
                      value={itemGroupCode}
                      onChange={(e) => {
                        setItemGroupCode(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Brand/Model Name</label>
                    <input
                      type="text"
                      className=" form-control p-1"
                      value={brandName}
                      onChange={(e) => {
                        setBrandName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2 div_mfg_date">
                    <label>Manufacturing Date</label>
                    <input
                      type="date"
                      className="border-info form-control p-1"
                      value={mfgDate}
                      onChange={(e) => {
                        setMfgDate(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2 div_exp_date">
                    <label>Expiry Date</label>
                    <input
                      type="date"
                      className="border-info form-control p-1"
                      value={expiryDate}
                      onChange={(e) => {
                        setExpiryDate(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2 hideonservice">
                    <label>Place Of origin*</label>
                    <input
                      type="text"
                      className="border-danger form-control p-1"
                      value={origin}
                      onChange={(e) => {
                        setOrigin(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Hsn Code*</label>
                    <input
                      type="text"
                      className="border-grey form-control p-1"
                      value={hsnCode}
                      onChange={(e) => {
                        setHsnCode(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Batch No.</label>
                    <input
                      type="text"
                      className=" form-control p-1"
                      value={batchNumber}
                      onChange={(e) => {
                        setBacthNumber(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 pt-2">
                    <label>Prod. Short Description*</label>
                    <textarea
                      className="border-danger form-control p-1"
                      rows="2"
                      value={shortDesc}
                      onChange={(e) => {
                        setShortDesc(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <div className="col-sm-12 pt-2">
                    <label>Prod. Detailed Description*</label>

                    {spinner === false ? (
                      <button
                        type="submit"
                        className="ms-1 mb-1 btn btn-primary rounded-pill"
                        disabled={shortDesc.length > 50 ? false : true}
                        onClick={generatedescription}
                      >
                        Generate Description with AI
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary rounded-pill mb-1"
                        type="button"
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        KAI is working, please wait...
                      </button>
                    )}
                    {/* <textarea
                      className="border-danger form-control p-1"
                      rows="10"
                      value={longDesc}
                      onChange={(e) => {
                        setLongDesc(e.target.value);
                      }}
                    ></textarea> */}
                    <Editor
                      apiKey="51bzughom7qhj3gygo8ysr5oyk781cqqjd0pqmjs12x1h3gw"
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue={longDesc}
                      init={{
                        selector: "#tinymce",
                        branding: false,
                        height: 500,
                        menubar: false,
                        plugins: [
                          // "a11ychecker",
                          "advlist",
                          // "advcode",
                          // "advtable",
                          "autolink",
                          // "checklist",
                          // "export",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          // "powerpaste",
                          "fullscreen",
                          // "formatpainter",
                          "insertdatetime",
                          "media",
                          "table",
                          "help",
                          "wordcount",
                        ],
                        toolbar:
                          "undo redo | casechange blocks | bold italic backcolor | " +
                          "alignleft aligncenter alignright alignjustify | " +
                          "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Add Product Image*</label>
                    <input
                      type="file"
                      className="border-danger form-control p-1"
                      accept=".jpg, .jpeg, .png, .webp"
                      // onChange={(e) => {
                      //   setProdImage1(e.target.files[0]);
                      // }}
                      onChange={async (e) => {
                        const compressedImage = await compressAndResizeImage(
                          e.target.files[0]
                        );
                        const fileName = "compressed_image.jpg"; // Change the filename as needed
                        const mimeType = compressedImage.type;
                        const file = new File([compressedImage], fileName, {
                          type: mimeType,
                        });
                        setProdImage1(file);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Add Product Image 2</label>
                    <input
                      type="file"
                      className="border-info form-control p-1"
                      accept=".jpg, .jpeg, .png, .webp"
                      // onChange={(e) => {
                      //   setProdImage2(e.target.files[0]);
                      // }}
                      onChange={async (e) => {
                        const compressedImage = await compressAndResizeImage(
                          e.target.files[0]
                        );
                        const fileName = "compressed_image.jpg"; // Change the filename as needed
                        const mimeType = compressedImage.type;
                        const file = new File([compressedImage], fileName, {
                          type: mimeType,
                        });
                        setProdImage2(file);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Add Product Image 3</label>
                    <input
                      type="file"
                      className="border-info form-control p-1"
                      accept=".jpg, .jpeg, .png, .webp"
                      // onChange={(e) => {
                      //   setProdImage3(e.target.files[0]);
                      // }}
                      onChange={async (e) => {
                        const compressedImage = await compressAndResizeImage(
                          e.target.files[0]
                        );
                        const fileName = "compressed_image.jpg"; // Change the filename as needed
                        const mimeType = compressedImage.type;
                        const file = new File([compressedImage], fileName, {
                          type: mimeType,
                        });
                        setProdImage3(file);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Add Product Image 4</label>
                    <input
                      type="file"
                      className="border-info form-control p-1"
                      accept=".jpg, .jpeg, .png, .webp"
                      // onChange={(e) => {
                      //   setProdImage4(e.target.files[0]);
                      // }}
                      onChange={async (e) => {
                        const compressedImage = await compressAndResizeImage(
                          e.target.files[0]
                        );
                        const fileName = "compressed_image.jpg"; // Change the filename as needed
                        const mimeType = compressedImage.type;
                        const file = new File([compressedImage], fileName, {
                          type: mimeType,
                        });
                        setProdImage4(file);
                      }}
                    />
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-3 pt-2">
                    <label>Add Product Image 5</label>
                    <input
                      type="file"
                      className="border-info form-control p-1"
                      accept=".jpg, .jpeg, .png, .webp"
                      // onChange={(e) => {
                      //   setProdImage5(e.target.files[0]);
                      // }}
                      onChange={async (e) => {
                        const compressedImage = await compressAndResizeImage(
                          e.target.files[0]
                        );
                        const fileName = "compressed_image.jpg"; // Change the filename as needed
                        const mimeType = compressedImage.type;
                        const file = new File([compressedImage], fileName, {
                          type: mimeType,
                        });
                        setProdImage5(file);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Add Data to inventory</label>
                    <button
                      className="btn btn-primary text-white form-control p-1"
                      onClick={addProductToData}
                    >
                      Add record
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}
