import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import useLoginAuth from "../../hooks/useLoginAuth";
import useValidateRegType from "../../hooks/useValidateRegType";
import PayForExpired from "../../layout/common/PayForExpired";
import DashboardAbm from "./DashboardAbm";
import DashboardAdmin from "./DashboardAdmin";
import DashboardAdvisor from "./DashboardAdvisor";
import DashboardBranch from "./DashboardBranch";
import DashboardBusiness from "./DashboardBusiness";
import DashboardReseller from "./DashboardReseller";
import DashboardSm from "./DashboardSm";
import DashboardGeneralUser from "./DashboardGeneralUser";
import CartItems from "../ecom-user-end/CartItems";

export default function DashboardSwitch() {
  const validatelogin = useLoginAuth();

  const validateSubscription = useValidateRegType();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (validatelogin !== true) {
    return <Navigate to={"/login"} />;
  } else {
    switch (localStorage.getItem("ez-userrole")) {
      case "admin":
        return <DashboardAdmin />;

      case "generaluser":
        return <CartItems />;

      case "reseller":
        if (validateSubscription.status === false) {
          return <PayForExpired />;
        } else {
          return <DashboardReseller />;
        }

      case "business":
        if (validateSubscription.status === false) {
          return <PayForExpired />;
        } else {
          return <DashboardBusiness />;
        }

      case "level1":
        return <DashboardBranch />;

      case "level2":
        return <DashboardAbm />;

      case "level3":
        return <DashboardSm />;

      case "level4":
        return <DashboardAdvisor />;

      default:
        return (
          <>
            <div className="mh-90 pt-5">Dashboard</div>
          </>
        );
    }
  }
}
